import { createApp } from 'vue';
import App from './App.vue';
import router from './router';

import { Toast} from 'vant';

const app = createApp(App);
app.use(router).use(Toast);
app.config.globalProperties.$globalCode  = '374501';
app.mount('#app');

