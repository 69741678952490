import { createRouter, createWebHashHistory } from 'vue-router';


const router = createRouter({
  history: createWebHashHistory(),
  routes: [
    // 头部导航
    {
      path: '/',
      redirect: '/home',  
    },
    {
      path: '/index',
      redirect: '/home',
    },
    {
      path: '/home',
      component: () => import('../pages/home/Home'),
    },
    {
      name: 'jdk',
      path: '/jdk',
      component: () => import('../pages/java/components/Jdk'),
      children: [
        {
          path: '/jdk7VersionFeatures',
          component: () => import('../pages/java/components/jdk/Jdk7VersionFeatures'),
        },
        {
          path: '/jdk8VersionFeatures',
          component: () => import('../pages/java/components/jdk/Jdk8VersionFeatures'),
        },
        {
          path: '/jdk11VersionFeatures',
          component: () => import('../pages/java/components/jdk/Jdk11VersionFeatures'),
        },
        {
          path: '/jdk17VersionFeatures',
          component: () => import('../pages/java/components/jdk/Jdk17VersionFeatures'),
        },
        {
          path: '/jdk21VersionFeatures',
          component: () => import('../pages/java/components/jdk/Jdk21VersionFeatures'),
        },
        {
          path: '/jdkUtil',
          component: () => import('../pages/java/components/jdk/JdkUtil'),
        },
        {
          path: '/jdkIO',
          component: () => import('../pages/java/components/jdk/JdkIO'),
        },
       
      ]
    },
    {
      path: '/javaBase',
      component: () => import('../pages/java/components/JavaBase'),
      children: [
        {
          path: '/javaDev',
          component: () => import('../pages/java/components/javabase/JavaDev'),
        },
        {
          path: '/javaCodeBase',
          component: () => import('../pages/java/components/javabase/JavaCodeBase'),
        },
        {
          path: '/javaObjectOriented',
          component: () => import('../pages/java/components/javabase/JavaObjectOriented'),
        },
        {
          path: '/javaObjectOrientedTwo',
          component: () => import('../pages/java/components/javabase/JavaObjectOrientedTwo'),
        },
        {
          path: '/javaClass',
          component: () => import('../pages/java/components/javabase/JavaClass'),
        },
        {
          path: '/javaCollection',
          component: () => import('../pages/java/components/javabase/JavaCollection'),
        },
        {
          path: '/javaIO',
          component: () => import('../pages/java/components/javabase/JavaIO'),
        },
        {
          path: '/javaJDBC',
          component: () => import('../pages/java/components/javabase/JDBC'),
        },
        {
          path: '/javaNetWork',
          component: () => import('../pages/java/components/javabase/JavaNetWork'),
        },
        
      ]
    },
    {
      path: '/javaWeb',
      component: () => import('../pages/java/components/JavaWeb'),
      children: [
        {
          path: '/xmlBase',
          component: () => import('../pages/java/components/javaweb/XmlBase'),
        },
        {
          path: '/tomcatWeb',
          component: () => import('../pages/java/components/javaweb/TomcatWeb'),
        },
        {
          path: '/httpProtocol',
          component: () => import('../pages/java/components/javaweb/HttpProtocol'),
        },
        {
          path: '/servlet',
          component: () => import('../pages/java/components/javaweb/Servlet'),
        },
        {
          path: '/requestResponse',
          component: () => import('../pages/java/components/javaweb/RequestResponse'),
        },
        {
          path: '/sessionCookie',
          component: () => import('../pages/java/components/javaweb/SessionCookie'),
        },
        {
          path: '/javaBean',
          component: () => import('../pages/java/components/javaweb/JavaBean'),
        },
        {
          path: '/filter',
          component: () => import('../pages/java/components/javaweb/Filter'),
        },
        {
          path: '/servletListen',
          component: () => import('../pages/java/components/javaweb/ServletListen'),
        },
        {
          path: '/fileUploadDownload',
          component: () => import('../pages/java/components/javaweb/FileUploadDownload'),
        },
        {
          path: '/elExpress',
          component: () => import('../pages/java/components/javaweb/ElExpress'),
        },
        {
          path: '/internation',
          component: () => import('../pages/java/components/javaweb/Internation'),
        },
        
        
      ]
    },
    {
      path: '/javaconcurrent',
      component: () => import('../pages/java/components/JavaConcurrent'),
      children: [
        {
          path: '/concurrencyPrinciple',
          component: () => import('../pages/java/components/javaconcurrent/ConcurrencyPrinciple'),
        },
        {
          path: '/concurrencyOverview',
          component: () => import('../pages/java/components/javaconcurrent/ConcurrencyOverview'),
        },

        {
          path: '/concurrentbase',
          component: () => import('../pages/java/components/javaconcurrent/ConcurrentBase'),
        },
        
        {
          path: '/javaLock',
          component: () => import('../pages/java/components/javaconcurrent/JavaLock'),
        },
        {
          path: '/concurrencyContainer',
          component: () => import('../pages/java/components/javaconcurrent/ConcurrencyContainer'),
        },
        {
          path: '/concurrencyTools',
          component: () => import('../pages/java/components/javaconcurrent/ConcurrencyTools'),
        },

        {
          path: '/juc',
          component: () => import('../pages/java/components/javaconcurrent/Juc'),
        },
        {
          path: '/combat',
          component: () => import('../pages/java/components/javaconcurrent/Combat'),
        },
      ]
    },
    {
      path: '/jvm',
      component: () => import('../pages/java/components/Jvm'),
      children: [
        {
          path: '/jvmOverview',
          component: () => import('../pages/java/components/jvm/JvmOverview'),
        },
        {
          path: '/jvmMemory',
          component: () => import('../pages/java/components/jvm/JvmMemory'),
        },
        {
          path: '/jvmGarbageCollector',
          component: () => import('../pages/java/components/jvm/JvmGarbageCollector'),
        },
        {
          path: '/jvmClassProcess',
          component: () => import('../pages/java/components/jvm/JvmClassProcess'),
        },

      ]
    },
    {
      path: '/spring',
      component: () => import('../pages/java/components/Spring'),
      children: [
        {
          path: '/springBase',
          component: () => import('../pages/java/components/spring/SpringBase'),
        },
        {
          path: '/springApplication',
          component: () => import('../pages/java/components/spring/SpringApplication'),
        },
        {
          path: '/springAop',
          component: () => import('../pages/java/components/spring/SpringAop'),
        },
        {
          path: '/springDatabase',
          component: () => import('../pages/java/components/spring/SpringDatabase'),
        },
        {
          path: '/springCode',
          component: () => import('../pages/java/components/spring/SpringCode'),
        },
        {
          path: '/springCodeTwo',
          component: () => import('../pages/java/components/spring/SpringCodeTwo'),
        },
        
      ]
    },
    {
      path: '/SpringBoot',
      component: () => import('../pages/java/components/SpringBoot'),
      children: [

        {
          path: '/springboot',
          component: () => import('../pages/java/components/springboot/SpringBoot'),
        },
        {
          path: '/springbootTwo',
          component: () => import('../pages/java/components/springboot/SpringBootTwo'),
        },
        {
          path: '/springbootDevelopOverview',
          component: () => import('../pages/java/components/springboot/SpringBootDevelopOverview'),
        },
        {
          path: '/springbootConfigAnno',
          component: () => import('../pages/java/components/springboot/SpringbootConfigAnno'),
        },
        {
          path: '/springbootData',
          component: () => import('../pages/java/components/springboot/SpringbootData'),
        },
        {
          path: '/springbootViewTech',
          component: () => import('../pages/java/components/springboot/SpringbootViewTech'),
        },
        {
          path: '/springbootImplWeb',
          component: () => import('../pages/java/components/springboot/SpringbootImplWeb'),
        },
        {
          path: '/springbootCache',
          component: () => import('../pages/java/components/springboot/SpringbootCache'),
        },
        {
          path: '/springbootSecurity',
          component: () => import('../pages/java/components/springboot/SpringbootSecurity'),
        },
        {
          path: '/springbootTask',
          component: () => import('../pages/java/components/springboot/SpringbootTask'),
        },
        
      ]
    },

    {
      path: '/mybatis',
      component: () => import('../pages/java/components/Mybatis'),
      children: [
        {
          path: '/mybatisOverview',
          component: () => import('../pages/java/components/mybatis/MybatisOverview'),
        },
        {
          path: '/mybatisCoreConfig',
          component: () => import('../pages/java/components/mybatis/MybatisCoreConfig'),
        },
        
        {
          path: '/mybatisBase',
          component: () => import('../pages/java/components/mybatis/MybatisBase'),
        },
        {
          path: '/mybatisCombat',
          component: () => import('../pages/java/components/mybatis/MybatisCombat'),
        },
        {
          path: '/mybatisCode',
          component: () => import('../pages/java/components/mybatis/MybatisCode'),
        },
      ]
    },
    {
      path: '/designPattern',
      component: () => import('../pages/java/components/DesignPattern'),
      children: [
        {
          path: '/designPatternBase',
          component: () => import('../pages/java/components/designpattern/DesignPatternBase'),
        },
      ]
    },

    {
      path: '/queue',
      component: () => import('../pages/java/components/Queue'),
      children: [
        {
          path: '/rocketMQ',
          component: () => import('../pages/java/components/mq/RocketMQ'),
        },
        {
          path: '/kafka',
          component: () => import('../pages/java/components/mq/Kafka'),
        },
        {
          path: '/rabbitMQ',
          component: () => import('../pages/java/components/mq/RabbitMQ'),
        },
        {
          path: '/activeMQ',
          component: () => import('../pages/java/components/mq/ActiveMQ'),
        },

      ]
    },
    {
      path: '/springcloud',
      component: () => import('../pages/micservice/SpringCloud'),
      children: [
        {
          path: '/gateway',
          component: () => import('../pages/micservice/components/Gateway'),
        },
        {
          path: '/register',
          component: () => import('../pages/micservice/components/Register'),
        },
        {
          path: '/hystrix',
          component: () => import('../pages/micservice/components/Hystrix'),
        },
        {
          path: '/ribbon',
          component: () => import('../pages/micservice/components/Remote'),
        },
        {
          path: '/stream',
          component: () => import('../pages/micservice/components/Stream'),
        },
        {
          path: '/sentinel',
          component: () => import('../pages/micservice/components/Sentinel'),
        },
        {
          path: '/apollo',
          component: () => import('../pages/micservice/components/Apollo'),
        },
      ]
    },
    {
      path: '/dubbo',
      component: () => import('../pages/micservice/Dubbo'),
      children: [
        {
          path: '/dubboCode',
          component: () => import('../pages/micservice/components/DubboCode'),
        },
        {
          path: '/dubboCombat',
          component: () => import('../pages/micservice/components/DubboCombat'),
        },
      ]
    },
    {
      path: '/hadoop',
      component: () => import('../pages/bigdata/Hadoop'),
      children: [
        {
          path: '/hadoopBase',
          component: () => import('../pages/bigdata/hadoop/HadoopBase'),
        },
        {
          path: '/hadoopClusteConstruction',
          component: () => import('../pages/bigdata/hadoop/HadoopClusteConstruction'),
        },
        {
          path: '/hadoopCode',
          component: () => import('../pages/bigdata/hadoop/HadoopCode'),
        },
        {
          path: '/hadoopCombat',
          component: () => import('../pages/bigdata/hadoop/HadoopCombat'),
        },
      ]
    },
    {
      path: '/flink',
      component: () => import('../pages/bigdata/Flink'),
      children: [
        {
          path: '/flinkBase',
          component: () => import('../pages/bigdata/flink/FlinkBase'),
        },
        {
          path: '/flinkCombat',
          component: () => import('../pages/bigdata/flink/FlinkCombat'),
        },
        {
          path: '/hiveBase',
          component: () => import('../pages/bigdata/hive/HiveBase'),
        },
        {
          path: '/hiveCode',
          component: () => import('../pages/bigdata/hive/HiveCode'),
        },
        {
          path: '/hBase',
          component: () => import('../pages/bigdata/hbase/HBase'),
        },
        {
          path: '/stormBase',
          component: () => import('../pages/bigdata/storm/StormBase'),
        },
        {
          path: '/flumeBase',
          component: () => import('../pages/bigdata/flume/FlumeBase'),
        },
        {
          path: '/dolphinSchedulerBase',
          component: () => import('../pages/bigdata/dolphinScheduler/DolphinSchedulerBase'),
        },
        {
          path: '/sparkBase',
          component: () => import('../pages/bigdata/spark/SparkBase'),
        },
        {
          path: '/sparkCombat',
          component: () => import('../pages/bigdata/spark/SparkCombat'),
        },
      ]
    },
   
    {
      path: '/dataStore',
      component: () => import('../pages/bigdata/DataStore'),
      children: [
        {
          path: '/Superset',
          component: () => import('../pages/bigdata/datastore/Superset'),
        },
        {
          path: '/store',
          component: () => import('../pages/bigdata/datastore/Store'),
        },
        {
          path: '/datax',
          component: () => import('../pages/bigdata/datastore/DataX'),
        },
        {
          path: '/doris',
          component: () => import('../pages/bigdata/datastore/Doris'),
        },
        {
          path: '/kylin',
          component: () => import('../pages/bigdata/datastore/Kylin'),
        },
        {
          path: '/hudi',
          component: () => import('../pages/bigdata/datastore/Hudi'),
        },
      ]
    },
    {
      path: '/cache',
      component: () => import('../pages/java/components/Cache'),
      children: [
        {
          path: '/redis',
          component: () => import('../pages/java/components/cache/Redis'),
        },
        {
          path: '/memcached',
          component: () => import('../pages/java/components/cache/Memcached'),
        },
        {
          path: '/mongodb',
          component: () => import('../pages/java/components/cache/Mongodb'),
        },
       
      ]
    },
    {
      path: '/transaction',
      component: () => import('../pages/java/components/Transaction'),
      children: [
        {
          path: '/transactionBase',
          component: () => import('../pages/java/components/transaction/TransactionBase'),
        },
        {
          path: '/seata',
          component: () => import('../pages/java/components/transaction/Seata'),
        },
        {
          path: '/lcn',
          component: () => import('../pages/java/components/transaction/LCN'),
        },
       
      ]
    },
    {
      path: '/job',
      component: () => import('../pages/java/components/Job'),
      children: [
        {
          path: '/jobBase',
          component: () => import('../pages/java/components/job/JobBase'),
        },
        {
          path: '/jobQuartz',
          component: () => import('../pages/java/components/job/JobQuartz'),
        },
        {
          path: '/jobxxl',
          component: () => import('../pages/java/components/job/JobXxl'),
        },
        {
          path: '/jobElastic',
          component: () => import('../pages/java/components/job/JobElastic'),
        },
      ]
    },
    {
      path: '/mysql',
      component: () => import('../pages/database/mysql/Mysql'),
      children: [
        {
          path: '/sql',
          component: () => import('../pages/database/mysql/SQL'),
        },
        {
          path: '/mysqlBase',
          component: () => import('../pages/database/mysql/MysqlBase'),
        },
        {
          path: '/mysqlCombat',
          component: () => import('../pages/database/mysql/MysqlCombat'),
        },
      ]
    },
    {
      path: '/oracle',
      component: () => import('../pages/database/oracle/Oracle'),
      children: [
        {
          path: '/oracleSql',
          component: () => import('../pages/database/oracle/OracleSql'),
        },
        {
          path: '/oracleBase',
          component: () => import('../pages/database/oracle/OracleBase'),
        },
        {
          path: '/oracleCombat',
          component: () => import('../pages/database/oracle/OracleCombat'),
        },
      ]
    },
    {
      path: '/docker',
      component: () => import('../pages/cloud/Docker'),
      children: [
        {
          path: '/dockerBase',
          component: () => import('../pages/cloud/docker/DockerBase'),
        }, 
        {
          path: '/dockerRecognize',
          component: () => import('../pages/cloud/docker/DockerRecognize'),
        },
        {
          path: '/dockerUses',
          component: () => import('../pages/cloud/docker/DockerUses'),
        },
        {
          path: '/dockerNetData',
          component: () => import('../pages/cloud/docker/DockerNetData'),
        },
        {
          path: '/dockerMicServeIntegration',
          component: () => import('../pages/cloud/docker/DockerMicServeIntegration'),
        },
        {
          path: '/dockerMicServeInstall',
          component: () => import('../pages/cloud/docker/DockerMicServeInstall'),
        },
        
        
      ]
    },
    {
      path: '/k8s',
      component: () => import('../pages/cloud/Kubernetes'),
      children: [
        {
          path: '/kubernetesBase',
          component: () => import('../pages/cloud/kubernetes/KubernetesBase'),
        },
        
      ]
    },

    {
      path: '/front',
      component: () => import('../pages/front/Front'),
      children: [
        {
          path: '/vue',
          component: () => import('../pages/front/Vue'),
        },
        {
          path: '/react',
          component: () => import('../pages/front/React'),
        },
        {
          path: '/html5',
          component: () => import('../pages/front/Html5'),
        },
        {
          path: '/css',
          component: () => import('../pages/front/CSS'),
        },
        {
          path: '/jsp',
          component: () => import('../pages/front/JSP'),
        },
      ]
    },

    {
      path: '/meetingJava',
      component: () => import('../pages/meeting/MeetingJava'),
      children: [
        {
          path: '/meetingJvm',
          component: () => import('../pages/meeting/meetingjava/MeetingJvm'),
        },
        {
          path: '/meetingMybatis',
          component: () => import('../pages/meeting/meetingjava/MeetingMybatis'),
        },
        {
          path: '/meetingConcurrency',
          component: () => import('../pages/meeting/meetingjava/MeetingConcurrency'),
        },
        {
          path: '/meetingNetty',
          component: () => import('../pages/meeting/meetingjava/MeetingNetty'),
        },
        {
          path: '/meetingJavaBase',
          component: () => import('../pages/meeting/meetingjava/MeetingJavaBase'),
        },
        {
          path: '/meetingPatterns',
          component: () => import('../pages/meeting/meetingjava/MeetingPatterns'),
        },
        {
          path: '/meetingSpringBase',
          component: () => import('../pages/meeting/meetingspring/MeetingSpringBase'),
        },
        {
          path: '/meetingSpringMVC',
          component: () => import('../pages/meeting/meetingspring/MeetingSpringMVC'),
        },
        {
          path: '/meetingSpringBoot',
          component: () => import('../pages/meeting/meetingspring/MeetingSpringBoot'),
        },
      ]
    },
    {
      path: '/meetingCache',
      component: () => import('../pages/meeting/MeetingCache'),
      children: [
        {
          path: '/meetingRedis',
          component: () => import('../pages/meeting/meetingcache/MeetingRedis'),
        },
        {
          path: '/meetingMongodb',
          component: () => import('../pages/meeting/meetingcache/MeetingMongodb'),
        },
        {
          path: '/meetingMemchached',
          component: () => import('../pages/meeting/meetingcache/MeetingMemchached'),
        },
        {
          path: '/meetingMysql',
          component: () => import('../pages/meeting/meetingcache/MeetingMysql'),
        },
      ]
    },
    {
      path: '/meetingModule',
      component: () => import('../pages/meeting/MeetingModule'),
      children: [
        {
          path: '/meetingKafka',
          component: () => import('../pages/meeting/meetingmodule/MeetingKafka'),
        },
        {
          path: '/meetingRabbitmq',
          component: () => import('../pages/meeting/meetingmodule/MeetingRabbitmq'),
        },
        {
          path: '/meetingActivemq',
          component: () => import('../pages/meeting/meetingmodule/MeetingActivemq'),
        },
        {
          path: '/meetingRocketmq',
          component: () => import('../pages/meeting/meetingmodule/MeetingRocketmq'),
        },
        {
          path: '/meetingZookeeper',
          component: () => import('../pages/meeting/meetingmodule/MeetingZookeeper'),
        },
        {
          path: '/meetingNginx',
          component: () => import('../pages/meeting/meetingmodule/MeetingNginx'),
        },

        
      ]
    },
    {
      path: '/tools',
      component: () => import('../pages/tools/Tools'),
    },
    {
      path: '/meetingFramework',
      component: () => import('../pages/meeting/MeetingFramework'),
      children: [
        {
          path: '/meetingSpringcloud',
          component: () => import('../pages/meeting/meetingframework/MeetingSpringCloud'),
        },
        {
          path: '/meetingDubbo',
          component: () => import('../pages/meeting/meetingframework/MeetingDubbo'),
        },
      ]
      
    },
    {
      path: '/meetingBigData',
      component: () => import('../pages/meeting/MeetingBigData'),
      children: [
        {
          path: '/meetingBigDataNormal',
          component: () => import('../pages/meeting/meetingbigdata/BigDataNormalMeeting'),
        },
        {
          path: '/meetingBigDataInterviewers',
          component: () => import('../pages/meeting/meetingbigdata/BigDataInterviewers'),
        },
        
      ]
    },
    {
      path: '/meetingDistributed',
      component: () => import('../pages/meeting/MeetingDistributed'),
    },

    {
      path: '/concurrentbase01',
      component: () => import('../pages/java/components/javaconcurrent/concurrentbase/01'),
    },
    {
      path: '/volatilePrinciple',
      component: () => import('../pages/java/components/javaconcurrent/concurrencyprinciple/VolatilePrinciple'),
    },
    {
      path: '/synchronizedPrinciple',
      component: () => import('../pages/java/components/javaconcurrent/concurrencyprinciple/SynchronizedPrinciple'),
    },
    {
      path: '/atomPrinciple',
      component: () => import('../pages/java/components/javaconcurrent/concurrencyprinciple/AtomPrinciple'),
    },
    {
      path: '/javamm',
      component: () => import('../pages/java/components/javaconcurrent/concurrencyprinciple/JavaMemoryModel'),
    },
    {
      path: '/concurrenChallenge',
      component: () => import('../pages/java/components/javaconcurrent/concurrencyoverview/ConcurrenChallenge'),
    },
    {
      path: '/concurrenStudy',
      component: () => import('../pages/java/components/javaconcurrent/concurrencyoverview/ConcurrenStudy'),
    },
    {
      path: '/threadIntroduction',
      component: () => import('../pages/java/components/javaconcurrent/concurrentbase/ThreadIntroduction'),
    },
    {
      path: '/threadCreate',
      component: () => import('../pages/java/components/javaconcurrent/concurrentbase/ThreadCreate'),
    },
    {
      path: '/threadComm',
      component: () => import('../pages/java/components/javaconcurrent/concurrentbase/ThreadComm'),
    },


    {
      path: '/lock',
      component: () => import('../pages/java/components/javaconcurrent/javalock/Lock'),
    },
    {
      path: '/queueSynchronizer',
      component: () => import('../pages/java/components/javaconcurrent/javalock/QueueSynchronizer'),
    },
    {
      path: '/reentrantLock',
      component: () => import('../pages/java/components/javaconcurrent/javalock/ReentrantLock'),
    },
    {
      path: '/readWriteLock',
      component: () => import('../pages/java/components/javaconcurrent/javalock/ReadWriteLock'),
    },
    {
      path: '/lockSupport',
      component: () => import('../pages/java/components/javaconcurrent/javalock/LockSupport'),
    },
    {
      path: '/condition',
      component: () => import('../pages/java/components/javaconcurrent/javalock/Condition'),
    },
    {
      path: '/concurrentHashMap',
      component: () => import('../pages/java/components/javaconcurrent/concurrencyContainer/ConcurrentHashMap'),
    },
    {
      path: '/concurrentLinkedQueue',
      component: () => import('../pages/java/components/javaconcurrent/concurrencyContainer/ConcurrentLinkedQueue'),
    },
    {
      path: '/blockQueue',
      component: () => import('../pages/java/components/javaconcurrent/concurrencyContainer/BlockQueue'),
    },
    {
      path: '/forkJoin',
      component: () => import('../pages/java/components/javaconcurrent/concurrencyContainer/ForkJoin'),
    },
    {
      path: '/atomic',
      component: () => import('../pages/java/components/javaconcurrent/concurrencytools/ConcurrencyAtomic'),
    },
    {
      path: '/countDownLatch',
      component: () => import('../pages/java/components/javaconcurrent/concurrencytools/CountDownLatch'),
    },
    {
      path: '/cyclicBarrier',
      component: () => import('../pages/java/components/javaconcurrent/concurrencytools/CyclicBarrier'),
    },
    {
      path: '/semaphore',
      component: () => import('../pages/java/components/javaconcurrent/concurrencytools/Semaphore'),
    },
    {
      path: '/exchanger',
      component: () => import('../pages/java/components/javaconcurrent/concurrencytools/Exchanger'),
    },
    {
      path: '/threadPool',
      component: () => import('../pages/java/components/javaconcurrent/concurrencytools/ThreadPool'),
    },
    {
      path: '/classProcessDetail',
      component: () => import('../pages/java/components/jvm/jvmClassProcess/ClassProcessDetail'),
    },

    {
      path: '/classLoader',
      component: () => import('../pages/java/components/jvm/jvmClassProcess/ClassLoader'),
    },
    {
      path: '/jvmMemoryStructure',
      component: () => import('../pages/java/components/jvm/jvmMemory/JvmMemoryStructure'),
    },
    
    {
      path: '/jvmByte',
      component: () => import('../pages/java/components/jvm/jvmMemory/JvmByte'),
    },
    {
      path: '/garbageCollectorAlgorithm',
      component: () => import('../pages/java/components/jvm/jvmGarbageCollector/GarbageCollectorAlgorithm'),
    },
    {
      path: '/cms',
      component: () => import('../pages/java/components/jvm/jvmGarbageCollector/Cms'),
    },
    {
      path: '/garbageFirst',
      component: () => import('../pages/java/components/jvm/jvmGarbageCollector/GarbageFirst'),
    },
    {
      path: '/garbageSummary',
      component: () => import('../pages/java/components/jvm/jvmGarbageCollector/GarbageSummary'),
    },

    {
      path: '/springbootStartTime',
      component: () => import('../pages/java/components/springboot/springbootcombat/SpringbootStartTime'),
    },
    {
      path: '/rocketMQNameServer',
      component: () => import('../pages/java/components/mq/rocketmq/RocketMQNameServer'),
    },
    
    {
      path: '/rocketMQBroker',
      component: () => import('../pages/java/components/mq/rocketmq/RocketMQBroker'),
    },
    {
      path: '/rocketMQCommitLog',
      component: () => import('../pages/java/components/mq/rocketmq/RocketMQCommitLog'),
    },
    {
      path: '/rocketMQConsume',
      component: () => import('../pages/java/components/mq/rocketmq/RocketMQConsume'),
    },
    {
      path: '/rocketMQRetry',
      component: () => import('../pages/java/components/mq/rocketmq/RocketMQRetry'),
    },
    {
      path: '/rocketMQMessageFilter',
      component: () => import('../pages/java/components/mq/rocketmq/RocketMQMessageFilter'),
    },
    {
      path: '/rocketMQSequenceConsume',
      component: () => import('../pages/java/components/mq/rocketmq/RocketMQSequenceConsume'),
    },
    {
      path: '/rocketMQExpireDeletion',
      component: () => import('../pages/java/components/mq/rocketmq/RocketMQExpireDeletion'),
    },
    {
      path: '/rocketMQHA',
      component: () => import('../pages/java/components/mq/rocketmq/RocketMQHA'),
    },
    {
      path: '/rocketMQSeparate',
      component: () => import('../pages/java/components/mq/rocketmq/RocketMQSeparate'),
    },
    {
      path: '/rocketMQAffair',
      component: () => import('../pages/java/components/mq/rocketmq/RocketMQAffair'),
    },
    
    {
      path: '/rocketMQACL',
      component: () => import('../pages/java/components/mq/rocketmq/RocketMQACL'),
    },
    {
      path: '/rocketMQTrace',
      component: () => import('../pages/java/components/mq/rocketmq/RocketMQTrace'),
    },
    {
      path: '/rocketMQRaft',
      component: () => import('../pages/java/components/mq/rocketmq/RocketMQRaft'),
    },
    {
      path: '/rocketMQHeap',
      component: () => import('../pages/java/components/mq/rocketmq/RocketMQHeap'),
    },
    {
      path: '/rocketMQDuplicate',
      component: () => import('../pages/java/components/mq/rocketmq/RocketMQDuplicate'),
    },
    {
      path: '/rocketMQSequence',
      component: () => import('../pages/java/components/mq/rocketmq/RocketMQSequence'),
    },
    {
      path: '/rocketMQDelay',
      component: () => import('../pages/java/components/mq/rocketmq/RocketMQDelay'),
    },
    {
      path: '/rocketMQBrokerStartup',
      component: () => import('../pages/java/components/mq/rocketmq/RocketMQBrokerStartup'),
    },
    {
      path: '/rocketMQBrokerHeartBeat',
      component: () => import('../pages/java/components/mq/rocketmq/RocketMQBrokerHeartbeat'),
    },
    {
      path: '/rocketMQProducerStartup',
      component: () => import('../pages/java/components/mq/rocketmq/RocketMQProducerStartup'),
    },
    
    {
      path: '/rocketMQProducerSendMessage',
      component: () => import('../pages/java/components/mq/rocketmq/RocketMQProducerSendMessage'),
    },
    {
      path: '/rocketMQProducerSendSync',
      component: () => import('../pages/java/components/mq/rocketmq/RocketMQProducerSendSync'),
    },
    {
      path: '/rocketMQBrokerReceiveMessage',
      component: () => import('../pages/java/components/mq/rocketmq/RocketMQBrokerReceiveMessage'),
    },
    {
      path: '/rocketMQBatchMessage',
      component: () => import('../pages/java/components/mq/rocketmq/RocketMQBatchMessage'),
    },
    {
      path: '/rocketMQAffairApplication',
      component: () => import('../pages/java/components/mq/rocketmq/RocketMQAffairApplication'),
    },
    {
      path: '/rocketMQMessageReliablity',
      component: () => import('../pages/java/components/mq/rocketmq/RocketMQMessageReliablity'),
    },
    {
      path: '/rocketMQRebalance',
      component: () => import('../pages/java/components/mq/rocketmq/RocketMQRebalance'),
    },
    {
      path: '/rocketMQCluster',
      component: () => import('../pages/java/components/mq/rocketmq/RocketMQCluster'),
    },
    {
      path: '/rocketMQIntroduce',
      component: () => import('../pages/java/components/mq/rocketmq/RocketMQIntroduce'),
    },
    {
      path: '/rocketMQTiming',
      component: () => import('../pages/java/components/mq/rocketmq/RocketMQTiming'),
    },
    {
      path: '/kafkaIntroduce',
      component: () => import('../pages/java/components/mq/kafka/KafkaIntroduce'),
    },
    {
      path: '/kafkaReliable',
      component: () => import('../pages/java/components/mq/kafka/KafkaReliable'),
    },
    {
      path: '/kafkaCommit',
      component: () => import('../pages/java/components/mq/kafka/KafkaCommit'),
    },
    {
      path: '/kafkaFrameworkPrinciple',
      component: () => import('../pages/java/components/mq/kafka/KafkaFrameworkPrinciple'),
    },
    {
      path: '/kafkaReplication',
      component: () => import('../pages/java/components/mq/kafka/KafkaReplication'),
    },
    {
      path: '/kafkaHA',
      component: () => import('../pages/java/components/mq/kafka/KafkaHigAvailability'),
    },
    {
      path: '/kafkaSequenceConsume',
      component: () => import('../pages/java/components/mq/kafka/KafkaSequenceConsume'),
    },
    {
      path: '/kafkaIdempotent',
      component: () => import('../pages/java/components/mq/kafka/KafkaIdempotent'),
    },
    {
      path: '/kafkaStorageStructure',
      component: () => import('../pages/java/components/mq/kafka/KafkaStorageStructure'),
    },
    {
      path: '/kafkaAffair',
      component: () => import('../pages/java/components/mq/kafka/KafkaAffair'),
    },
    {
      path: '/kafkaOverstock',
      component: () => import('../pages/java/components/mq/kafka/KafkaOverstock'),
    },
    {
      path: '/kafkaDuplicate',
      component: () => import('../pages/java/components/mq/kafka/KafkaDuplicate'),
    },
    {
      path: '/kafkaSpringboot',
      component: () => import('../pages/java/components/mq/kafka/KafkaSpringboot'),
    },
    {
      path: '/kafkaConsumer',
      component: () => import('../pages/java/components/mq/kafka/KafkaConsumer'),
    },
    {
      path: '/kafkaProducer',
      component: () => import('../pages/java/components/mq/kafka/KafkaProducer'),
    },
    {
      path: '/kafkaConsumeProcess',
      component: () => import('../pages/java/components/mq/kafka/KafkaConsumeProcess'),
    },
    {
      path: '/kafkaProduceProcess',
      component: () => import('../pages/java/components/mq/kafka/KafkaProduceProcess'),
    },
    {
      path: '/kafkaInstall',
      component: () => import('../pages/java/components/mq/kafka/KafkaInstall'),
    },
    {
      path: '/kafkaStream',
      component: () => import('../pages/java/components/mq/kafka/KafkaStream'),
    },
    {
      path: '/kafkaZookeeper',
      component: () => import('../pages/java/components/mq/kafka/KafkaZookeeper'),
    },
    {
      path: '/kafkaDelay',
      component: () => import('../pages/java/components/mq/kafka/KafkaDelay'),
    },
    {
      path: '/kafkaMessageDuplication',
      component: () => import('../pages/java/components/mq/kafka/KafkaMessageDuplication'),
    },
    {
      path: '/rabbitMQIntroduce',
      component: () => import('../pages/java/components/mq/rabbitmq/RabbitMQIntroduce'),
    },
    {
      path: '/rabbitMQComponentsPrinciples',
      component: () => import('../pages/java/components/mq/rabbitmq/RabbitMQComponentsPrinciples'),
    },
    {
      path: '/rabbitMQArchitect',
      component: () => import('../pages/java/components/mq/rabbitmq/RabbitMQArchitect'),
    },
    {
      path: '/rabbitMQExchange',
      component: () => import('../pages/java/components/mq/rabbitmq/RabbitMQExchange'),
    },
    {
      path: '/rabbitMQModel',
      component: () => import('../pages/java/components/mq/rabbitmq/RabbitMQModel'),
    }, 
    {
      path: '/rabbitMQPersistent',
      component: () => import('../pages/java/components/mq/rabbitmq/RabbitMQPersistent'),
    }, 
    {
      path: '/rabbitMQDLQ',
      component: () => import('../pages/java/components/mq/rabbitmq/RabbitMQDLQ'),
    }, 
    {
      path: '/rabbitMQAffair',
      component: () => import('../pages/java/components/mq/rabbitmq/RabbitMQAffair'),
    }, 
    {
      path: '/rabbitMQMessageHeap',
      component: () => import('../pages/java/components/mq/rabbitmq/rabbitMQMessageHeap'),
    }, 
    {
      path: '/rabbitMQSequence',
      component: () => import('../pages/java/components/mq/rabbitmq/RabbitMQSequence'),
    }, 
    {
      path: '/rabbitMQRepeat',
      component: () => import('../pages/java/components/mq/rabbitmq/RabbitMQRepeat'),
    }, 
    {
      path: '/rabbitMQSpringBoot',
      component: () => import('../pages/java/components/mq/rabbitmq/RabbitMQSpringBoot'),
    }, 
    {
      path: '/rabbitMQDelay',
      component: () => import('../pages/java/components/mq/rabbitmq/RabbitMQDelay'),
    }, 
    {
      path: '/rabbitMQLose',
      component: () => import('../pages/java/components/mq/rabbitmq/RabbitMQLose'),
    }, 
    
    {
      path: '/activeMQIntroduce',
      component: () => import('../pages/java/components/mq/activemq/ActiveMQIntroduce'),
    },
    {
      path: '/activeMQPrinciple',
      component: () => import('../pages/java/components/mq/activemq/ActiveMQPrinciple'),
    },
    {
      path: '/activeMQSpringboot',
      component: () => import('../pages/java/components/mq/activemq/ActiveMQSpringBoot'),
    },
    {
      path: '/activeMQDelay',
      component: () => import('../pages/java/components/mq/activemq/ActiveMQDelay'),
    },
    {
      path: '/activePersistence',
      component: () => import('../pages/java/components/mq/activemq/ActivePersistence'),
    },
    {
      path: '/activeMQAdvanceFeature',
      component: () => import('../pages/java/components/mq/activemq/ActiveMQAdvanceFeature'),
    },
    {
      path: '/activeMQAck',
      component: () => import('../pages/java/components/mq/activemq/ActiveMQAck'),
    },
    {
      path: '/activeMQProtocol',
      component: () => import('../pages/java/components/mq/activemq/ActiveMQProtocol'),
    },
    {
      path: '/activeMQJms',
      component: () => import('../pages/java/components/mq/activemq/ActiveMQJms'),
    },
    {
      path: '/redisThirdCached',
      component: () => import('../pages/java/components/cache/redis/RedisThirdCached'),
    },
    {
      path: '/redisMysql',
      component: () => import('../pages/java/components/cache/redis/RedisMysql'),
    },
    {
      path: '/redisSeckill',
      component: () => import('../pages/java/components/cache/redis/RedisSeckill'),
    },
    {
      path: '/redisCluster',
      component: () => import('../pages/java/components/cache/redis/RedisCluster'),
    },
    {
      path: '/redisAppScene',
      component: () => import('../pages/java/components/cache/redis/RedisAppScene'),
    },
    {
      path: '/redisPipeline',
      component: () => import('../pages/java/components/cache/redis/RedisPipeline'),
    },
    {
      path: '/redisLossData',
      component: () => import('../pages/java/components/cache/redis/RedisLossData'),
    },
    {
      path: '/redisHotCache',
      component: () => import('../pages/java/components/cache/redis/RedisHotCache'),
    },
    
    {
      path: '/redisSentinelDown',
      component: () => import('../pages/java/components/cache/redis/RedisSentinelDown'),
    },
    {
      path: '/redisLock',
      component: () => import('../pages/java/components/cache/redis/RedisLock'),
    },
    
    {
      path: '/redisSentinel',
      component: () => import('../pages/java/components/cache/redis/RedisSentinel'),
    },
    
    {
      path: '/redisMemoryEliminate',
      component: () => import('../pages/java/components/cache/redis/RedisMemoryEliminate'),
    },
    
    {
      path: '/redisExpirationPolicies',
      component: () => import('../pages/java/components/cache/redis/RedisExpirationPolicies'),
    },
    {
      path: '/redisPersistence',
      component: () => import('../pages/java/components/cache/redis/RedisPersistence'),
    },
    {
      path: '/redisAtomic',
      component: () => import('../pages/java/components/cache/redis/RedisAtomic'),
    },
    {
      path: '/redisDataType',
      component: () => import('../pages/java/components/cache/redis/RedisDataType'),
    },
    {
      path: '/redisFast',
      component: () => import('../pages/java/components/cache/redis/RedisFast'),
    },
    {
      path: '/redisHA',
      component: () => import('../pages/java/components/cache/redis/RedisHA'),
    },
    {
      path: '/redisAffairLock',
      component: () => import('../pages/java/components/cache/redis/RedisAffairLock'),
    },
    {
      path: '/redisIntroduce',
      component: () => import('../pages/java/components/cache/redis/RedisIntroduce'),
    },
    {
      path: '/mongodbIntroduce',
      component: () => import('../pages/java/components/cache/mongodb/MongodbIntroduce'),
    },
    {
      path: '/mongodbScene',
      component: () => import('../pages/java/components/cache/mongodb/MongodbScene'),
    },
    {
      path: '/mongodbHA',
      component: () => import('../pages/java/components/cache/mongodb/MongodbHA'),
    },
    {
      path: '/mongodbStorage',
      component: () => import('../pages/java/components/cache/mongodb/MongodbStorage'),
    },
    {
      path: '/mongodbSpringboot',
      component: () => import('../pages/java/components/cache/mongodb/MongodbSpringboot'),
    },
    {
      path: '/memcachedIntroduce',
      component: () => import('../pages/java/components/cache/memcached/MemcachedIntroduce'),
    },
    {
      path: '/memcachedScene',
      component: () => import('../pages/java/components/cache/memcached/MemcachedScene'),
    },
    {
      path: '/memcachedUses',
      component: () => import('../pages/java/components/cache/memcached/MemcachedUses'),
    },
    {
      path: '/memcachedCommand',
      component: () => import('../pages/java/components/cache/memcached/MemcachedCommand'),
    },
    {
      path: '/transactionBaseIntroduce',
      component: () => import('../pages/java/components/transaction/transactionBase/TransactionBaseIntroduce'),
    },
    {
      path: '/transactionBaseMysql',
      component: () => import('../pages/java/components/transaction/transactionBase/TransactionBaseMysql'),
    },
    {
      path: '/transactionBaseSpring',
      component: () => import('../pages/java/components/transaction/transactionBase/TransactionBaseSpring'),
    },
    {
      path: '/transactionBaseDistributed',
      component: () => import('../pages/java/components/transaction/transactionBase/TransactionBaseDistributed'),
    },
    {
      path: '/transactionBaseCAP',
      component: () => import('../pages/java/components/transaction/transactionBase/TransactionBaseCAP'),
    },
    {
      path: '/transactionBaseBase',
      component: () => import('../pages/java/components/transaction/transactionBase/TransactionBaseBase'),
    },
    {
      path: '/transactionBaseZAB',
      component: () => import('../pages/java/components/transaction/transactionBase/TransactionBaseZAB'),
    },
    {
      path: '/transactionBaseRaft',
      component: () => import('../pages/java/components/transaction/transactionBase/TransactionBaseRaft'),
    },
    
    {
      path: '/transactionBasePaxos',
      component: () => import('../pages/java/components/transaction/transactionBase/TransactionBasePaxos'),
    },
    {
      path: '/transactionBase2PC',
      component: () => import('../pages/java/components/transaction/transactionBase/TransactionBase2PC'),
    },
    {
      path: '/transactionBase3PC',
      component: () => import('../pages/java/components/transaction/transactionBase/TransactionBase3PC'),
    },
    {
      path: '/transactionBaseTCC',
      component: () => import('../pages/java/components/transaction/transactionBase/TransactionBaseTCC'),
    },
    {
      path: '/transactionBaseStrong',
      component: () => import('../pages/java/components/transaction/transactionBase/TransactionBaseStrong'),
    },
    {
      path: '/transactionBaseFinal',
      component: () => import('../pages/java/components/transaction/transactionBase/TransactionBaseFinal'),
    },
    {
      path: '/transactionBaseXA',
      component: () => import('../pages/java/components/transaction/transactionBase/TransactionBaseXA'),
    },
    {
      path: '/transactionBaseReliable',
      component: () => import('../pages/java/components/transaction/transactionBase/TransactionBaseReliable'),
    },
    {
      path: '/transactionBaseNotice',
      component: () => import('../pages/java/components/transaction/transactionBase/TransactionBaseNotice'),
    },
    {
      path: '/transactionBaseSolutions',
      component: () => import('../pages/java/components/transaction/transactionBase/TransactionBaseSolutions'),
    },
    {
      path: '/lCNIntroduce',
      component: () => import('../pages/java/components/transaction/lcn/LCNIntroduce'),
    },
    {
      path: '/lcnPrinciple',
      component: () => import('../pages/java/components/transaction/lcn/LCNPrinciple'),
    },
    {
      path: '/seataIntroduce',
      component: () => import('../pages/java/components/transaction/seata/SeataIntroduce'),
    },
    {
      path: '/seataPrinciple',
      component: () => import('../pages/java/components/transaction/seata/SeataPrinciple'),
    },
    {
      path: '/seataBuild',
      component: () => import('../pages/java/components/transaction/seata/SeataBuild'),
    },
    {
      path: '/seataModel',
      component: () => import('../pages/java/components/transaction/seata/SeataModel'),
    },
    {
      path: '/jobBaseCron',
      component: () => import('../pages/java/components/job/jobBase/JobBaseCron'),
    },
    
    {
      path: '/jobBaseTimer',
      component: () => import('../pages/java/components/job/jobBase/JobBaseTimer'),
    },
    
    {
      path: '/jobBaseIntroduce',
      component: () => import('../pages/java/components/job/jobBase/JobBaseIntroduce'),
    },
    {
      path: '/jobBaseScheduled',
      component: () => import('../pages/java/components/job/jobBase/JobBaseScheduled'),
    },
    {
      path: '/jobBaseSpringScheduled',
      component: () => import('../pages/java/components/job/jobBase/JobBaseSpringScheduled'),
    },
    {
      path: '/jobQuartzIntroduce',
      component: () => import('../pages/java/components/job/jobQuartz/JobQuartzIntroduce'),
    },
    {
      path: '/jobQuartzSpringboot',
      component: () => import('../pages/java/components/job/jobQuartz/JobQuartzSpringboot'),
    },
    {
      path: '/jobQuartzCourse',
      component: () => import('../pages/java/components/job/jobQuartz/JobQuartzCourse'),
    },
    {
      path: '/jobQuartzPrinciple',
      component: () => import('../pages/java/components/job/jobQuartz/JobQuartzPrinciple'),
    },
    {
      path: '/jobQuartzTask',
      component: () => import('../pages/java/components/job/jobQuartz/JobQuartzTask'),
    },
    {
      path: '/jobQuartzTrigger',
      component: () => import('../pages/java/components/job/jobQuartz/JobQuartzTrigger'),
    },
    {
      path: '/jobQuartzMisfire',
      component: () => import('../pages/java/components/job/jobQuartz/JobQuartzMisfire'),
    },
    {
      path: '/jobXxlIntroduce',
      component: () => import('../pages/java/components/job/xxljob/JobXxlIntroduce'),
    },
    {
      path: '/jobXxlPrinciple',
      component: () => import('../pages/java/components/job/xxljob/JobXxlPrinciple'),
    },
    {
      path: '/jobXxlNewJobFrameWork',
      component: () => import('../pages/java/components/job/xxljob/JobXxlNewJobFrameWork'),
    },
    {
      path: '/jobXxlWheel',
      component: () => import('../pages/java/components/job/xxljob/JobXxlWheel'),
    },
    {
      path: '/jobElasticIntroduce',
      component: () => import('../pages/java/components/job/jobelastic/JobElasticIntroduce'),
    },
    {
      path: '/jobElasticPrinciple',
      component: () => import('../pages/java/components/job/jobelastic/JobElasticPrinciple'),
    },
    {
      path: '/jobElasticQuide',
      component: () => import('../pages/java/components/job/jobelastic/JobElasticQuide'),
    },
    {
      path: '/jdkVersion7Switch',
      component: () => import('../pages/java/components/jdk/jdkversion/jdk7/JdkVersion7Switch'),
    },
    {
      path: '/jdkVersion7Fork',
      component: () => import('../pages/java/components/jdk/jdkversion/jdk7/JdkVersion7Fork'),
    },
    {
      path: '/jdkVersion7Nio',
      component: () => import('../pages/java/components/jdk/jdkversion/jdk7/JdkVersion7Nio'),
    },
    {
      path: '/jdkVersion7Literal',
      component: () => import('../pages/java/components/jdk/jdkversion/jdk7/JdkVersion7Literal'),
    },
    {
      path: '/jdkVersion7Exception',
      component: () => import('../pages/java/components/jdk/jdkversion/jdk7/JdkVersion7Exception'),
    },
    {
      path: '/jdkVersion7Strong',
      component: () => import('../pages/java/components/jdk/jdkversion/jdk7/JdkVersion7Strong'),
    },
    {
      path: '/jdkVersion7JSR',
      component: () => import('../pages/java/components/jdk/jdkversion/jdk7/JdkVersion7JSR'),
    },
    {
      path: '/jdkVersion7Path',
      component: () => import('../pages/java/components/jdk/jdkversion/jdk7/JdkVersion7Path'),
    },
    {
      path: '/jdkVersion8Joining',
      component: () => import('../pages/java/components/jdk/jdkversion/jdk8/JdkVersion8Joining'),
    },
    {
      path: '/jdkVersion8Stream',
      component: () => import('../pages/java/components/jdk/jdkversion/jdk8/JdkVersion8Stream'),
    },  
    {
      path: '/jdkVersion8Runnable',
      component: () => import('../pages/java/components/jdk/jdkversion/jdk8/JdkVersion8Runnable'),
    },
    {
      path: '/jdkVersion8Collectors',
      component: () => import('../pages/java/components/jdk/jdkversion/jdk8/JdkVersion8Collectors'),
    },  
    {
      path: '/jdkVersion8Base64',
      component: () => import('../pages/java/components/jdk/jdkversion/jdk8/JdkVersion8Base64'),
    },
    {
      path: '/jdkVersion8LambdaUp',
      component: () => import('../pages/java/components/jdk/jdkversion/jdk8/JdkVersion8LambdaUp'),
    },  
    {
      path: '/jdkVersion8LambdaMiddle',
      component: () => import('../pages/java/components/jdk/jdkversion/jdk8/JdkVersion8LambdaMiddle'),
    },
    {
      path: '/jdkVersion8LambdaDown',
      component: () => import('../pages/java/components/jdk/jdkversion/jdk8/JdkVersion8LambdaDown'),
    },  
    {
      path: '/jdkVersion8Reference',
      component: () => import('../pages/java/components/jdk/jdkversion/jdk8/JdkVersion8Reference'),
    },
    {
      path: '/jdkVersion8Interface',
      component: () => import('../pages/java/components/jdk/jdkversion/jdk8/JdkVersion8Interface'),
    },  
    {
      path: '/jdkVersion8Static',
      component: () => import('../pages/java/components/jdk/jdkversion/jdk8/JdkVersion8Static'),
    },
    {
      path: '/jdkVersion8Collection',
      component: () => import('../pages/java/components/jdk/jdkversion/jdk8/JdkVersion8Collection'),
    },  
    {
      path: '/jdkVersion8Optional',
      component: () => import('../pages/java/components/jdk/jdkversion/jdk8/JdkVersion8Optional'),
    },
    {
      path: '/jdkVersion8Js',
      component: () => import('../pages/java/components/jdk/jdkversion/jdk8/JdkVersion8Js'),
    },  
    {
      path: '/jdkVersion8NewDateUp',
      component: () => import('../pages/java/components/jdk/jdkversion/jdk8/JdkVersion8NewDateUp'),
    },
    {
      path: '/jdkVersion8NewDateMiddle',
      component: () => import('../pages/java/components/jdk/jdkversion/jdk8/JdkVersion8NewDateMiddle'),
    },  
    {
      path: '/jdkVersion8NewDateDown',
      component: () => import('../pages/java/components/jdk/jdkversion/jdk8/JdkVersion8NewDateDown'),
    },
    {
      path: '/jdkVersion8StreamUp',
      component: () => import('../pages/java/components/jdk/jdkversion/jdk8/JdkVersion8StreamUp'),
    },  
    {
      path: '/jdkVersion8StreamDown',
      component: () => import('../pages/java/components/jdk/jdkversion/jdk8/JdkVersion8StreamDown'),
    },
    {
      path: '/jdkVersion8Functional',
      component: () => import('../pages/java/components/jdk/jdkversion/jdk8/JdkVersion8Functional'),
    },   
    {
      path: '/jdkVersion17Text',
      component: () => import('../pages/java/components/jdk/jdkversion/jdk17/JdkVersion17Text'),
    }, 
    {
      path: '/jdkVersion17Switch',
      component: () => import('../pages/java/components/jdk/jdkversion/jdk17/JdkVersion17Switch'),
    }, 
    {
      path: '/jdkVersion17Record',
      component: () => import('../pages/java/components/jdk/jdkversion/jdk17/JdkVersion17Record'),
    }, 
    {
      path: '/jdkVersion17Sealed',
      component: () => import('../pages/java/components/jdk/jdkversion/jdk17/JdkVersion17Sealed'),
    }, 
    {
      path: '/jdkVersion17Instance',
      component: () => import('../pages/java/components/jdk/jdkversion/jdk17/JdkVersion17Instance'),
    }, 
    {
      path: '/jdkVersion17Helpful',
      component: () => import('../pages/java/components/jdk/jdkversion/jdk17/JdkVersion17Helpful'),
    }, 
    {
      path: '/jdkVersion17Date',
      component: () => import('../pages/java/components/jdk/jdkversion/jdk17/JdkVersion17Date'),
    }, 
    {
      path: '/jdkVersion17Simple',
      component: () => import('../pages/java/components/jdk/jdkversion/jdk17/JdkVersion17Simple'),
    }, 

    {
      path: '/jdkVersion17Stream',
      component: () => import('../pages/java/components/jdk/jdkversion/jdk17/JdkVersion17Stream'),
    }, 
    {
      path: '/jdkVersion17Macos',
      component: () => import('../pages/java/components/jdk/jdkversion/jdk17/JdkVersion17Macos'),
    }, 
    {
      path: '/jdkVersion17Strong',
      component: () => import('../pages/java/components/jdk/jdkversion/jdk17/JdkVersion17Strong'),
    }, 
    {
      path: '/jdkVersion17Zgc',
      component: () => import('../pages/java/components/jdk/jdkversion/jdk17/JdkVersion17Zgc'),
    }, 

    {
      path: '/jdkVersion17Unicode',
      component: () => import('../pages/java/components/jdk/jdkversion/jdk17/JdkVersion17Unicode'),
    }, 
    {
      path: '/jdkVersion17Embedded',
      component: () => import('../pages/java/components/jdk/jdkversion/jdk17/JdkVersion17Embedded'),
    }, 
    {
      path: '/jdkVersion17Code',
      component: () => import('../pages/java/components/jdk/jdkversion/jdk17/JdkVersion17Code'),
    }, 
    {
      path: '/jdkVersion17Http',
      component: () => import('../pages/java/components/jdk/jdkversion/jdk17/JdkVersion17Http'),
    }, 


    {
      path: '/jdkVersion11Introduce',
      component: () => import('../pages/java/components/jdk/jdkversion/jdk11/JdkVersion11Introduce'),
    }, 
    {
      path: '/jdkVersion11Httpclient',
      component: () => import('../pages/java/components/jdk/jdkversion/jdk11/JdkVersion11Httpclient'),
    }, 

    {
      path: '/jdkVersion11Compile',
      component: () => import('../pages/java/components/jdk/jdkversion/jdk11/JdkVersion11Compile'),
    }, 
    {
      path: '/jdkVersion11String',
      component: () => import('../pages/java/components/jdk/jdkversion/jdk11/JdkVersion11String'),
    }, 

    {
      path: '/jdkVersion11Collection',
      component: () => import('../pages/java/components/jdk/jdkversion/jdk11/JdkVersion11Collection'),
    }, 
    {
      path: '/jdkVersion11File',
      component: () => import('../pages/java/components/jdk/jdkversion/jdk11/JdkVersion11File'),
    }, 

    {
      path: '/jdkVersion11Optional',
      component: () => import('../pages/java/components/jdk/jdkversion/jdk11/JdkVersion11Optional'),
    }, 
    {
      path: '/jdkVersion11Predicate',
      component: () => import('../pages/java/components/jdk/jdkversion/jdk11/JdkVersion11Predicate'),
    }, 
    {
      path: '/jdkVersion11Var',
      component: () => import('../pages/java/components/jdk/jdkversion/jdk11/JdkVersion11Var'),
    }, 
    {
      path: '/jdkVersion11Nest',
      component: () => import('../pages/java/components/jdk/jdkversion/jdk11/JdkVersion11Nest'),
    }, 
    {
      path: '/jdkVersion11Delete',
      component: () => import('../pages/java/components/jdk/jdkversion/jdk11/JdkVersion11Delete'),
    }, 

    {
      path: '/jdkVersion21Serial',
      component: () => import('../pages/java/components/jdk/jdkversion/jdk21/JdkVersion21Serial'),
    }, 
    {
      path: '/jdkVersion21Zgc',
      component: () => import('../pages/java/components/jdk/jdkversion/jdk21/JdkVersion21Zgc'),
    }, 

    {
      path: '/jdkVersion21Record',
      component: () => import('../pages/java/components/jdk/jdkversion/jdk21/JdkVersion21Record'),
    }, 

    {
      path: '/jdkVersion21Switch',
      component: () => import('../pages/java/components/jdk/jdkversion/jdk21/JdkVersion21Switch'),
    }, 

    {
      path: '/jdkVersion21Thread',
      component: () => import('../pages/java/components/jdk/jdkversion/jdk21/JdkVersion21Thread'),
    }, 

    {
      path: '/jdkVersion21Windows',
      component: () => import('../pages/java/components/jdk/jdkversion/jdk21/JdkVersion21Windows'),
    }, 
    {
      path: '/jdkVersion21Forbit',
      component: () => import('../pages/java/components/jdk/jdkversion/jdk21/JdkVersion21Forbit'),
    }, 
    {
      path: '/jdkVersion21Secret',
      component: () => import('../pages/java/components/jdk/jdkversion/jdk21/JdkVersion21Secret'),
    }, 
    {
      path: '/jdkVersion21String',
      component: () => import('../pages/java/components/jdk/jdkversion/jdk21/JdkVersion21String'),
    }, 
    {
      path: '/jdkVersion21Function',
      component: () => import('../pages/java/components/jdk/jdkversion/jdk21/JdkVersion21Function'),
    }, 
    {
      path: '/jdkVersion21Unnamed',
      component: () => import('../pages/java/components/jdk/jdkversion/jdk21/JdkVersion21Unnamed'),
    }, 

    {
      path: '/jdkVersion21UnClass',
      component: () => import('../pages/java/components/jdk/jdkversion/jdk21/JdkVersion21UnClass'),
    }, 
    {
      path: '/jdkVersion21Scope',
      component: () => import('../pages/java/components/jdk/jdkversion/jdk21/JdkVersion21Scope'),
    }, 
    {
      path: '/jdkVersion21Structure',
      component: () => import('../pages/java/components/jdk/jdkversion/jdk21/JdkVersion21Structure'),
    }, 
    {
      path: '/jdkVersion21Vector',
      component: () => import('../pages/java/components/jdk/jdkversion/jdk21/JdkVersion21Vector'),
    }, 
    {
      path: '/gatewayQuickUses',
      component: () => import('../pages/micservice/components/gateway/GatewayQuickUses'),
    }, 
    {
      path: '/gatewayRoute',
      component: () => import('../pages/micservice/components/gateway/GatewayRoute'),
    }, 
    {
      path: '/gatewayPrinciple',
      component: () => import('../pages/micservice/components/gateway/GatewayPrinciple'),
    }, 
    {
      path: '/gatewayInitializeLoading',
      component: () => import('../pages/micservice/components/gateway/GatewayInitializeLoading'),
    }, 
    {
      path: '/gatewayFilter',
      component: () => import('../pages/micservice/components/gateway/GatewayFilter'),
    }, 
    {
      path: '/gatewayGlobleFilter',
      component: () => import('../pages/micservice/components/gateway/GatewayGlobleFilter'),
    }, 
    {
      path: '/gatewayCustomizeFilter',
      component: () => import('../pages/micservice/components/gateway/GatewayCustomizeFilter'),
    }, 
    {
      path: '/gatewayConfiguration',
      component: () => import('../pages/micservice/components/gateway/GatewayConfiguration'),
    }, 
    {
      path: '/gatewayLimit',
      component: () => import('../pages/micservice/components/gateway/GatewayLimit'),
    }, 
    {
      path: '/gatewayGrayscale',
      component: () => import('../pages/micservice/components/gateway/GatewayGrayscale'),
    }, 
    {
      path: '/gatewayDynamicRoutes',
      component: () => import('../pages/micservice/components/gateway/GatewayDynamicRoutes'),
    }, 
    {
      path: '/gatewayCross',
      component: () => import('../pages/micservice/components/gateway/GatewayCross'),
    }, 
    {
      path: '/gatewayException',
      component: () => import('../pages/micservice/components/gateway/GatewayException'),
    }, 
    {
      path: '/gatewaySentinel',
      component: () => import('../pages/micservice/components/gateway/GatewaySentinel'),
    }, 
    {
      path: '/gatewayNonblock',
      component: () => import('../pages/micservice/components/gateway/GatewayNonblock'),
    }, 
    {
      path: '/gatewayZuul',
      component: () => import('../pages/micservice/components/gateway/GatewayZuul'),
    }, 
    {
      path: '/nacosIntroduce',
      component: () => import('../pages/micservice/components/register/NacosIntroduce'),
    }, 
    {
      path: '/nacosRegisterPrinciple',
      component: () => import('../pages/micservice/components/register/NacosRegisterPrinciple'),
    }, 
    {
      path: '/nacosConfigurationPrinciple',
      component: () => import('../pages/micservice/components/register/NacosConfigurationPrinciple'),
    }, 
    {
      path: '/nacosBeta',
      component: () => import('../pages/micservice/components/register/NacosBeta'),
    }, 
    {
      path: '/nacosConfigurationIsolate',
      component: () => import('../pages/micservice/components/register/NacosConfigurationIsolate'),
    }, 
    {
      path: '/hystrixFusingLimiting',
      component: () => import('../pages/micservice/components/hystrix/HystrixFusingLimiting'),
    }, 
    {
      path: '/hystrixFusingPrinciple',
      component: () => import('../pages/micservice/components/hystrix/HystrixFusingPrinciple'),
    }, 
    {
      path: '/hystrixDowngrading',
      component: () => import('../pages/micservice/components/hystrix/HystrixDowngrading'),
    }, 
    {
      path: '/hystrixFeign',
      component: () => import('../pages/micservice/components/hystrix/HystrixFeign'),
    }, 
    {
      path: '/hystrixRemoteException',
      component: () => import('../pages/micservice/components/hystrix/HystrixRemoteException'),
    },
    {
      path: '/hystrixExcptionFilter',
      component: () => import('../pages/micservice/components/hystrix/HystrixExcptionFilter'),
    },
    {
      path: '/hystrixDashboard',
      component: () => import('../pages/micservice/components/hystrix/HystrixDashboard'),
    },
    {
      path: '/remoteHttpClient',
      component: () => import('../pages/micservice/components/remote/RemoteHttpClient'),
    },
    {
      path: '/remoteRestTemplatePrinciple',
      component: () => import('../pages/micservice/components/remote/RemoteRestTemplatePrinciple'),
    },
    {
      path: '/remoteRibbonFlowCode',
      component: () => import('../pages/micservice/components/remote/RemoteRibbonFlowCode'),
    },
    {
      path: '/remoteRibbonLoadBalance',
      component: () => import('../pages/micservice/components/remote/RemoteRibbonLoadBalance'),
    },
    {
      path: '/remoteRibbonRetry',
      component: () => import('../pages/micservice/components/remote/RemoteRibbonRetry'),
    }, 
    {
      path: '/remoteFeignOpenFeign',
      component: () => import('../pages/micservice/components/remote/RemoteFeignOpenFeign'),
    }, 
    {
      path: '/remoteFeignPrinciple',
      component: () => import('../pages/micservice/components/remote/RemoteFeignPrinciple'),
    }, 
    {
      path: '/remoteOpenFeignConfiguration',
      component: () => import('../pages/micservice/components/remote/RemoteOpenFeignConfiguration'),
    }, 
    {
      path: '/remoteFeignFirstCall',
      component: () => import('../pages/micservice/components/remote/RemoteFeignFirstCall'),
    }, 

    {
      path: '/streamIntroduce',
      component: () => import('../pages/micservice/components/stream/StreamIntroduce'),
    }, 
    {
      path: '/streamSleuth',
      component: () => import('../pages/micservice/components/stream/StreamSleuth'),
    }, 
    {
      path: '/sentinelInstall',
      component: () => import('../pages/micservice/components/sentinel/SentinelInstall'),
    }, 
    {
      path: '/sentinelClientMonitor',
      component: () => import('../pages/micservice/components/sentinel/SentinelClientMonitor'),
    }, 
    {
      path: '/sentinelLimitPrinciple',
      component: () => import('../pages/micservice/components/sentinel/SentinelLimitPrinciple'),
    }, 
    {
      path: '/sentinelQps',
      component: () => import('../pages/micservice/components/sentinel/SentinelQps'),
    }, 
    {
      path: '/sentinelThread',
      component: () => import('../pages/micservice/components/sentinel/SentinelThread'),
    }, 
    {
      path: '/sentinelRelation',
      component: () => import('../pages/micservice/components/sentinel/SentinelRelation'),
    }, 
    {
      path: '/sentinelLink',
      component: () => import('../pages/micservice/components/sentinel/SentinelLink'),
    },
    
    {
      path: '/sentinelWarmUp',
      component: () => import('../pages/micservice/components/sentinel/SentinelWarmUp'),
    },
    {
      path: '/sentinelUniform',
      component: () => import('../pages/micservice/components/sentinel/SentinelUniform'),
    },
    {
      path: '/sentinelBlock',
      component: () => import('../pages/micservice/components/sentinel/SentinelBlock'),
    },
    {
      path: '/sentinelFusingGrading',
      component: () => import('../pages/micservice/components/sentinel/SentinelFusingGrading'),
    },
    {
      path: '/sentinelFusingException',
      component: () => import('../pages/micservice/components/sentinel/SentinelFusingException'),
    },
    {
      path: '/sentinelDegradeException',
      component: () => import('../pages/micservice/components/sentinel/SentinelDegradeException'),
    },
    {
      path: '/sentinelDegradeExceptionFilter',
      component: () => import('../pages/micservice/components/sentinel/SentinelDegradeExceptionFilter'),
    },
    {
      path: '/sentinelHotLimit',
      component: () => import('../pages/micservice/components/sentinel/SentinelHotLimit'),
    },
    {
      path: '/sentinelSelfAdaption',
      component: () => import('../pages/micservice/components/sentinel/SentinelSelfAdaption'),
    },
    {
      path: '/apolloConfigurationScene',
      component: () => import('../pages/micservice/components/apollo/ApolloConfigurationScene'),
    },
    
    {
      path: '/apolloIntroduce',
      component: () => import('../pages/micservice/components/apollo/ApolloIntroduce'),
    },
    {
      path: '/apolloInstall',
      component: () => import('../pages/micservice/components/apollo/ApolloInstall'),
    },
    {
      path: '/apolloPermission',
      component: () => import('../pages/micservice/components/apollo/ApolloPermission'),
    },
    {
      path: '/apolloSpringcloud',
      component: () => import('../pages/micservice/components/apollo/ApolloSpringcloud'),
    },
    {
      path: '/apolloHotUpdate',
      component: () => import('../pages/micservice/components/apollo/ApolloHotUpdate'),
    },
    {
      path: '/apolloPrinciple',
      component: () => import('../pages/micservice/components/apollo/ApolloPrinciple'),
    },
    {
      path: '/apolloGrayscale',
      component: () => import('../pages/micservice/components/apollo/ApolloGrayscale'),
    },
    {
      path: '/dubboCallProcess',
      component: () => import('../pages/micservice/components/dubbocode/DubboCallProcess'),
    },
    {
      path: '/dubboIntroduce',
      component: () => import('../pages/micservice/components/dubbocode/DubboIntroduce'),
    },
    {
      path: '/dubboBalance',
      component: () => import('../pages/micservice/components/dubbocode/DubboBalance'),
    },
    {
      path: '/dubboSPI',
      component: () => import('../pages/micservice/components/dubbocode/DubboSPI'),
    },
    {
      path: '/dubboExportProcess',
      component: () => import('../pages/micservice/components/dubbocode/DubboExportProcess'),
    }, 
    {
      path: '/dubboIntroduceProcess',
      component: () => import('../pages/micservice/components/dubbocode/DubboIntroduceProcess'),
    }, 
    {
      path: '/dubboRouteStrategy',
      component: () => import('../pages/micservice/components/dubbocode/DubboRouteStrategy'),
    }, 
    {
      path: '/dubboCluster',
      component: () => import('../pages/micservice/components/dubbocode/DubboCluster'),
    }, 
    {
      path: '/dubboFault',
      component: () => import('../pages/micservice/components/dubbocode/DubboFault'),
    }, 
    {
      path: '/dubboSync',
      component: () => import('../pages/micservice/components/dubbocode/DubboSync'),
    }, 
    {
      path: '/dubboExpose',
      component: () => import('../pages/micservice/components/dubbocode/DubboExpose'),
    }, 
    {
      path: '/dubboServiceGovernance',
      component: () => import('../pages/micservice/components/dubbocode/DubboServiceGovernance'),
    }, 
    {
      path: '/dubboSendComsume',
      component: () => import('../pages/micservice/components/dubbocode/DubboSendComsume'),
    }, 
    {
      path: '/dubboProviderSendComsume',
      component: () => import('../pages/micservice/components/dubbocode/DubboProviderSendComsume'),
    }, 
    {
      path: '/dubboSerializable',
      component: () => import('../pages/micservice/components/dubbocode/DubboSerializable'),
    }, 
    {
      path: '/dubboArchitecture',
      component: () => import('../pages/micservice/components/dubbocode/DubboArchitecture'),
    },  
    {
      path: '/dubboRealize',
      component: () => import('../pages/micservice/components/dubbocode/DubboRealize'),
    },  
    {
      path: '/dubboDynamicCompile',
      component: () => import('../pages/micservice/components/dubbocode/DubboDynamicCompile'),
    },
    {
      path: '/dubboSpring',
      component: () => import('../pages/micservice/components/dubbocode/DubboSpring'),
    },
    {
      path: '/dubboListenerFilter',
      component: () => import('../pages/micservice/components/dubbocode/DubboListenerFilter'),
    },
    
    {
      path: '/dubboClusterFailure',
      component: () => import('../pages/micservice/components/dubbocode/DubboClusterFailure'),
    },
    {
      path: '/dubboMonitor',
      component: () => import('../pages/micservice/components/dubbocode/DubboMonitor'),
    },
    {
      path: '/dubboNetty',
      component: () => import('../pages/micservice/components/dubbocode/DubboNetty'),
    },
    {
      path: '/dubboNetworkEncode',
      component: () => import('../pages/micservice/components/dubbocode/DubboNetworkEncode'),
    },
    {
      path: '/dubboCombatStarted',
      component: () => import('../pages/micservice/components/dubbocombat/DubboCombatStarted'),
    },
    {
      path: '/dubboDesignPattern',
      component: () => import('../pages/micservice/components/dubbocombat/DubboDesignPattern'),
    },
    {
      path: '/dubboWriteRpc',
      component: () => import('../pages/micservice/components/dubbocombat/DubboWriteRpc'),
    },
    {
      path: '/mysqlInstall',
      component: () => import('../pages/database/mysql/mysqlbase/MysqlInstall'),
    },
    {
      path: '/mysqlCommonDemand',
      component: () => import('../pages/database/mysql/mysqlbase/MysqlCommonDemand'),
    },
    {
      path: '/mysqlDataType',
      component: () => import('../pages/database/mysql/mysqlbase/MysqlDataType'),
    },
    {
      path: '/mysqlDDL',
      component: () => import('../pages/database/mysql/mysqlbase/MysqlDDL'),
    },
    {
      path: '/mysqlDML',
      component: () => import('../pages/database/mysql/mysqlbase/MysqlDML'),
    },
    {
      path: '/mysqlAffair',
      component: () => import('../pages/database/mysql/mysqlbase/MysqlAffair'),
    },
    {
      path: '/mysqlView',
      component: () => import('../pages/database/mysql/mysqlbase/MysqlView'),
    },
    {
      path: '/mysqlProcedure',
      component: () => import('../pages/database/mysql/mysqlbase/MysqlProcedure'),
    },
    {
      path: '/mysqlTrigger',
      component: () => import('../pages/database/mysql/mysqlbase/MysqlTrigger'),
    },
    {
      path: '/mysqlCustomFunctions',
      component: () => import('../pages/database/mysql/mysqlbase/MysqlCustomFunctions'),
    },
    {
      path: '/mysqlCursor',
      component: () => import('../pages/database/mysql/mysqlbase/MysqlCursor'),
    },
    {
      path: '/mysqlFlowControl',
      component: () => import('../pages/database/mysql/mysqlbase/MysqlFlowControl'),
    },
    {
      path: '/mysqlIndexPrinciple',
      component: () => import('../pages/database/mysql/mysqlbase/MysqlIndexPrinciple'),
    },
    {
      path: '/mysqlIndexImplement',
      component: () => import('../pages/database/mysql/mysqlbase/MysqlIndexImplement'),
    },
    {
      path: '/mysqlHighPerformanceIndex',
      component: () => import('../pages/database/mysql/mysqlbase/MysqlHighPerformanceIndex'),
    },
    {
      path: '/mysqlHighPerformanceIndexDemo',
      component: () => import('../pages/database/mysql/mysqlbase/MysqlHighPerformanceIndexDemo'),
    },
    {
      path: '/mysqlDevStandard',
      component: () => import('../pages/database/mysql/mysqlbase/MysqlDevStandard'),
    }, 
    {
      path: '/sqlBaseSearch',
      component: () => import('../pages/database/mysql/sql/SqlBaseSearch'),
    },
    {
      path: '/sqlSearchFilter',
      component: () => import('../pages/database/mysql/sql/SqlSearchFilter'),
    },
    {
      path: '/sqlRegular',
      component: () => import('../pages/database/mysql/sql/SqlRegular'),
    },
    {
      path: '/sqlSortPage',
      component: () => import('../pages/database/mysql/sql/SqlSortPage'),
    },
    {
      path: '/sqlGroup',
      component: () => import('../pages/database/mysql/sql/SqlGroup'),
    },
    {
      path: '/sqlCount',
      component: () => import('../pages/database/mysql/sql/SqlCount'),
    },
    {
      path: '/sqlSubCombination',
      component: () => import('../pages/database/mysql/sql/SqlSubCombination'),
    },
    {
      path: '/sqlConnectSearch',
      component: () => import('../pages/database/mysql/sql/SqlConnectSearch'),
    },
    {
      path: '/sqlFunctions',
      component: () => import('../pages/database/mysql/sql/SqlFunctions'),
    },
    
    {
      path: '/sqlCopyTable',
      component: () => import('../pages/database/mysql/sql/SqlCopyTable'),
    },
    {
      path: '/mysqlShowProfiles',
      component: () => import('../pages/database/mysql/mysqlcombat/MysqlShowProfiles'),
    },
    {
      path: '/mysqlCombatOptimizeBillions',
      component: () => import('../pages/database/mysql/mysqlcombat/MysqlCombatOptimizeBillions'),
    },
    {
      path: '/mysqlHA',
      component: () => import('../pages/database/mysql/mysqlcombat/MysqlHA'),
    }, 
    {
      path: '/mysqlDividDB',
      component: () => import('../pages/database/mysql/mysqlcombat/MysqlDividDB'),
    }, 
    {
      path: '/mysqlBackup',
      component: () => import('../pages/database/mysql/mysqlcombat/MysqlBackup'),
    },
    {
      path: '/mysqlHarm',
      component: () => import('../pages/database/mysql/mysqlcombat/MysqlHarm'),
    },   
    {
      path: '/mysqlAlterField',
      component: () => import('../pages/database/mysql/mysqlcombat/MysqlAlterField'),
    },   
    {
      path: '/mysqlDividDBPartition',
      component: () => import('../pages/database/mysql/mysqlcombat/MysqlDividDBPartition'),
    },  
    {
      path: '/mysqlOptimizeIndex',
      component: () => import('../pages/database/mysql/mysqlcombat/MysqlOptimizeIndex'),
    },  
    {
      path: '/mysqlSlowQuery',
      component: () => import('../pages/database/mysql/mysqlcombat/MysqlSlowQuery'),
    },  
    {
      path: '/mysqlOverwriteIndex',
      component: () => import('../pages/database/mysql/mysqlbase/MysqlOverwriteIndex'),
    },  
    {
      path: '/mysqlHALossLess',
      component: () => import('../pages/database/mysql/mysqlcombat/MysqlHALossLess'),
    },  
    {
      path: '/mysqlDiscriminationIndex',
      component: () => import('../pages/database/mysql/mysqlcombat/MysqlDiscriminationIndex'),
    },  
    {
      path: '/mysqlPreIndex',
      component: () => import('../pages/database/mysql/mysqlcombat/MysqlPreIndex'),
    }, 
    {
      path: '/mysqlInnoDBMyISAM',
      component: () => import('../pages/database/mysql/mysqlbase/MysqlInnoDBMyISAM'),
    }, 
    {
      path: '/mysqlInnodbHC',
      component: () => import('../pages/database/mysql/mysqlcombat/MysqlInnodbHC'),
    }, 
    {
      path: '/mysqlRRRC',
      component: () => import('../pages/database/mysql/mysqlbase/MysqlRRRC'),
    }, 
    {
      path: '/mysqlInnodbLock',
      component: () => import('../pages/database/mysql/mysqlbase/MysqlInnodbLock'),
    }, 
    {
      path: '/mysqlOperatorLock',
      component: () => import('../pages/database/mysql/mysqlbase/MysqlOperatorLock'),
    }, 
    {
      path: '/mysqlCommonMiddleware',
      component: () => import('../pages/database/mysql/mysqlbase/MysqlCommonMiddleware'),
    }, 
    {
      path: '/mysqlCommonLock',
      component: () => import('../pages/database/mysql/mysqlbase/MysqlCommonLock'),
    }, 
    {
      path: '/mysqlPartition',
      component: () => import('../pages/database/mysql/mysqlbase/MysqlPartition'),
    }, 
    {
      path: '/mysqlLog',
      component: () => import('../pages/database/mysql/mysqlbase/MysqlLog'),
    }, 
    {
      path: '/mysqlMiddlewareApp',
      component: () => import('../pages/database/mysql/mysqlcombat/MysqlMiddlewareApp'),
    }, 
    {
      path: '/mysqlLoseIndex',
      component: () => import('../pages/database/mysql/mysqlcombat/MysqlLoseIndex'),
    }, 
    {
      path: '/mysqlOptimizeBigTable',
      component: () => import('../pages/database/mysql/mysqlcombat/MysqlOptimizeBigTable'),
    }, 
    {
      path: '/mysqlMoveBigTable',
      component: () => import('../pages/database/mysql/mysqlcombat/MysqlMoveBigTable'),
    }, 
    {
      path: '/mysqlFileSort',
      component: () => import('../pages/database/mysql/mysqlcombat/MysqlFileSort'),
    }, 
    {
      path: '/mysqlCpu',
      component: () => import('../pages/database/mysql/mysqlcombat/MysqlCpu'),
    }, 
    {
      path: '/oracleBaseTanent',
      component: () => import('../pages/database/oracle/oraclebase/OracleBaseTanent'),
    }, 
    {
      path: '/oracleBaseFlash',
      component: () => import('../pages/database/oracle/oraclebase/OracleBaseFlash'),
    }, 
    {
      path: '/oracleILM',
      component: () => import('../pages/database/oracle/oraclebase/OracleILM'),
    },    
    {
      path: '/oracleHeatMap',
      component: () => import('../pages/database/oracle/oraclebase/OracleHeatMap'),
    }, 
    {
      path: '/oracleBaseAutoDataOptimize',
      component: () => import('../pages/database/oracle/oraclebase/OracleBaseAutoDataOptimize'),
    }, 
    {
      path: '/oracleBaseDatabaseArchiving',
      component: () => import('../pages/database/oracle/oraclebase/OracleBaseDatabaseArchiving'),
    }, 
    {
      path: '/oracleBaseTemporal',
      component: () => import('../pages/database/oracle/oraclebase/OracleBaseTemporal'),
    }, 
    {
      path: '/oracleBasePartitioned',
      component: () => import('../pages/database/oracle/oraclebase/OracleBasePartitioned'),
    }, 
    {
      path: '/oracleBaseSequence',
      component: () => import('../pages/database/oracle/oraclebase/OracleBaseSequence'),
    }, 
    {
      path: '/oracleBaseIdentityColumns',
      component: () => import('../pages/database/oracle/oraclebase/OracleBaseIdentityColumns'),
    }, 
    {
      path: '/oracleBaseParallelExecution',
      component: () => import('../pages/database/oracle/oraclebase/OracleBaseParallelExecution'),
    }, 
    {
      path: '/oracleBaseInvisibleColumns',
      component: () => import('../pages/database/oracle/oraclebase/OracleBaseInvisibleColumns'),
    }, 
    {
      path: '/oracleBaseStandardViews',
      component: () => import('../pages/database/oracle/oraclebase/OracleBaseStandardViews'),
    }, 
    {
      path: '/oracleBaseMaterializedView',
      component: () => import('../pages/database/oracle/oraclebase/OracleBaseMaterializedView'),
    }, 
    {
      path: '/oracleBaseInsertTable',
      component: () => import('../pages/database/oracle/oraclebase/OracleBaseInsertTable'),
    },
    {
      path: '/oracleBaseHeapOrganized',
      component: () => import('../pages/database/oracle/oraclebase/OracleBaseHeapOrganized'),
    },
    {
      path: '/oracleBaseDirectPath',
      component: () => import('../pages/database/oracle/oraclebase/OracleBaseDirectPath'),
    },
    {
      path: '/oracleBaseKeepPool',
      component: () => import('../pages/database/oracle/oraclebase/OracleBaseKeepPool'),
    },
    {
      path: '/oracleBaseHierarchicalQueries',
      component: () => import('../pages/database/oracle/oraclebase/OracleBaseHierarchicalQueries'),
    },
    {
      path: '/oracleBaseLostWrite',
      component: () => import('../pages/database/oracle/oraclebase/OracleBaseLostWrite'),
    },
    {
      path: '/oracleBaseTransportTablespace',
      component: () => import('../pages/database/oracle/oraclebase/OracleBaseTransportTablespace'),
    },    
    {
      path: '/oracleBaseInstall',
      component: () => import('../pages/database/oracle/oraclebase/OracleBaseInstall'),
    },    
    {
      path: '/oracleBaseRMAN',
      component: () => import('../pages/database/oracle/oraclebase/OracleBaseRMAN'),
    },    
    {
      path: '/oracleBaseIndex',
      component: () => import('../pages/database/oracle/oraclebase/OracleBaseIndex'),
    },     
    {
      path: '/oracleBaseAffair',
      component: () => import('../pages/database/oracle/oraclebase/OracleBaseAffair'),
    }, 
    {
      path: '/oracleBaseBakLog',
      component: () => import('../pages/database/oracle/oraclebase/OracleBaseBakLog'),
    },
    {
      path: '/oracleProcedureFunctions',
      component: () => import('../pages/database/oracle/oraclebase/OracleProcedureFunctions'),
    },   
    {
      path: '/oracleBaseCursor',
      component: () => import('../pages/database/oracle/oraclebase/OracleBaseCursor'),
    }, 
    {
      path: '/oracleBaseTrigger',
      component: () => import('../pages/database/oracle/oraclebase/OracleBaseTrigger'),
    }, 
    {
      path: '/oracleBaseTempTable',
      component: () => import('../pages/database/oracle/oraclebase/OracleBaseTempTable'),
    }, 
    {
      path: '/oracleCombatDeadLock',
      component: () => import('../pages/database/oracle/oraclecombat/OracleCombatDeadLock'),
    }, 
    

    
    

    
    
    {
      path: '/oracleSqlMergeInto',
      component: () => import('../pages/database/oracle/oraclesql/OracleSqlMergeInto'),
    },
    {
      path: '/oracleSqlFunctions',
      component: () => import('../pages/database/oracle/oraclesql/OracleSqlFunctions'),
    },
    {
      path: '/oracleSqlCommonSql',
      component: () => import('../pages/database/oracle/oraclesql/OracleSqlCommonSql'),
    },
    {
      path: '/oracelSqlOptimize',
      component: () => import('../pages/database/oracle/oraclesql/OracelSqlOptimize'),
    },
    {
      path: '/oracleCombatJdbc',
      component: () => import('../pages/database/oracle/oraclecombat/OracleCombatJdbc'),
    },   
    {
      path: '/mybatisCombatStarted',
      component: () => import('../pages/java/components/mybatis/mybatiscombat/MybatisCombatStarted'),
    },
    {
      path: '/mybatisCodePageHelperReasonable',
      component: () => import('../pages/java/components/mybatis/mybatiscombat/MybatisCodePageHelperReasonable'),
    }, 
    {
      path: '/mybatisCombatMapperXml',
      component: () => import('../pages/java/components/mybatis/mybatiscombat/MybatisCombatMapperXml'),
    },
    {
      path: '/mybatisCombatSql',
      component: () => import('../pages/java/components/mybatis/mybatiscombat/MybatisCombatSql'),
    }, 
    {
      path: '/mybatisCombatAutoIncrId',
      component: () => import('../pages/java/components/mybatis/mybatiscombat/MybatisCombatAutoIncrId'),
    }, 
    {
      path: '/mybatisCombatCRUD',
      component: () => import('../pages/java/components/mybatis/mybatiscombat/MybatisCombatCRUD'),
    }, 
    {
      path: '/mybatisCombatUseTips',
      component: () => import('../pages/java/components/mybatis/mybatiscombat/MybatisCombatUseTips'),
    }, 
    {
      path: '/mybatisCombatMultiOneQuery',
      component: () => import('../pages/java/components/mybatis/mybatiscombat/MybatisCombatMultiOneQuery'),
    }, 
    {
      path: '/mybatisCombatMultiOneDelayQuery',
      component: () => import('../pages/java/components/mybatis/mybatiscombat/MybatisCombatMultiOneDelayQuery'),
    }, 
    {
      path: '/mybatisCombatOneMultiQuery',
      component: () => import('../pages/java/components/mybatis/mybatiscombat/MybatisCombatOneMultiQuery'),
    },
    {
      path: '/mybatisCombatFirstCache',
      component: () => import('../pages/java/components/mybatis/mybatiscombat/MybatisCombatFirstCache'),
    },
    {
      path: '/mybatisCombatSecondCache',
      component: () => import('../pages/java/components/mybatis/mybatiscombat/MybatisCombatSecondCache'),
    },
    {
      path: '/mybatisCombatEhCache',
      component: () => import('../pages/java/components/mybatis/mybatiscombat/MybatisCombatEhCache'),
    },
    {
      path: '/mybatisCombatPageHelper',
      component: () => import('../pages/java/components/mybatis/mybatiscombat/MybatisCombatPageHelper'),
    },
    {
      path: '/mybatisCombatManualWrite',
      component: () => import('../pages/java/components/mybatis/mybatiscombat/MybatisCombatManualWrite'),
    },
    

    {
      path: '/mybatisOverviewIntroduce',
      component: () => import('../pages/java/components/mybatis/mybatisoverview/MybatisOverviewIntroduce'),
    },
    {
      path: '/mybatisOverviewPrinciple',
      component: () => import('../pages/java/components/mybatis/mybatisoverview/MybatisOverviewPrinciple'),
    },
    {
      path: '/mybatisOverviewSeachCustomer',
      component: () => import('../pages/java/components/mybatis/mybatisoverview/MybatisOverviewSeachCustomer'),
    },
    {
      path: '/mybatisOverviewAddCustomer',
      component: () => import('../pages/java/components/mybatis/mybatisoverview/MybatisOverviewAddCustomer'),
    },
    {
      path: '/mybatisOverviewUpdateCustomer',
      component: () => import('../pages/java/components/mybatis/mybatisoverview/MybatisOverviewUpdateCustomer'),
    },
    { 
    path: '/mybatisOverviewDeleteCustomer',
    component: () => import('../pages/java/components/mybatis/mybatisoverview/MybatisOverviewDeleteCustomer'),
    },
    
    { 
      path: '/mybatisConfigCoreObject',
      component: () => import('../pages/java/components/mybatis/mybatiscoreconfig/MybatisConfigCoreObject'),
    },
    { 
      path: '/mybatisConfigElement',
      component: () => import('../pages/java/components/mybatis/mybatiscoreconfig/MybatisConfigElement'),
    },
    { 
      path: '/mybatisConfigElementProperties',
      component: () => import('../pages/java/components/mybatis/mybatiscoreconfig/MybatisConfigElementProperties'),
    },
    { 
      path: '/mybatisConfigElementSettings',
      component: () => import('../pages/java/components/mybatis/mybatiscoreconfig/MybatisConfigElementSettings'),
    },
    { 
      path: '/mybatisConfigElementTypeAliases',
      component: () => import('../pages/java/components/mybatis/mybatiscoreconfig/MybatisConfigElementTypeAliases'),
    },
    { 
      path: '/mybatisConfigElementTypeHandler',
      component: () => import('../pages/java/components/mybatis/mybatiscoreconfig/MybatisConfigElementTypeHandler'),
    },
    { 
      path: '/mybatisConfigElementObjectFactory',
      component: () => import('../pages/java/components/mybatis/mybatiscoreconfig/MybatisConfigElementObjectFactory'),
    },
    { 
      path: '/mybatisConfigElementEnvironments',
      component: () => import('../pages/java/components/mybatis/mybatiscoreconfig/MybatisConfigElementEnvironments'),
    },
    { 
      path: '/mybatisConfigElementMappers',
      component: () => import('../pages/java/components/mybatis/mybatiscoreconfig/MybatisConfigElementMappers'),
    },
    { 
      path: '/mybatisConfigSelect',
      component: () => import('../pages/java/components/mybatis/mybatiscoreconfig/MybatisConfigSelect'),
    },
    { 
      path: '/mybatisConfigInsert',
      component: () => import('../pages/java/components/mybatis/mybatiscoreconfig/MybatisConfigInsert'),
    },
    { 
      path: '/mybatisConfigUpdate',
      component: () => import('../pages/java/components/mybatis/mybatiscoreconfig/MybatisConfigUpdate'),
    },
    { 
      path: '/mybatisConfigSql',
      component: () => import('../pages/java/components/mybatis/mybatiscoreconfig/MybatisConfigSql'),
    },
    { 
      path: '/mybatisConfigResultMap',
      component: () => import('../pages/java/components/mybatis/mybatiscoreconfig/MybatisConfigResultMap'),
    },
    
    {
      path: '/mybatisCombatConfig',
      component: () => import('../pages/java/components/mybatis/mybatiscoreconfig/MybatisCombatConfig'),
    }, 
    

    {
      path: '/mybatisCodeSqlSessionFactory',
      component: () => import('../pages/java/components/mybatis/mybatiscode/MybatisCodeSqlSessionFactory'),
    },
    {
      path: '/mybatisCodeMapper',
      component: () => import('../pages/java/components/mybatis/mybatiscode/MybatisCodeMapper'),
    },
    {
      path: '/mybatisCodeDataSource',
      component: () => import('../pages/java/components/mybatis/mybatiscode/MybatisCodeDataSource'),
    },
    {
      path: '/mybatisCodeSqlSession',
      component: () => import('../pages/java/components/mybatis/mybatiscode/MybatisCodeSqlSession'),
    },
    {
      path: '/mybatisCodeSqlSessionMapper',
      component: () => import('../pages/java/components/mybatis/mybatiscode/MybatisCodeSqlSessionMapper'),
    },
    {
      path: '/mybatisCodeSqlSessionBoundSql',
      component: () => import('../pages/java/components/mybatis/mybatiscode/MybatisCodeSqlSessionBoundSql'),
    },
    {
      path: '/mybatisCodeSqlSessionCache',
      component: () => import('../pages/java/components/mybatis/mybatiscode/MybatisCodeSqlSessionCache'),
    },
    {
      path: '/mybatisCodeSimpleExecutorQuery',
      component: () => import('../pages/java/components/mybatis/mybatiscode/MybatisCodeSimpleExecutorQuery'),
    },
    {
      path: '/mybatisCodeResultSetHandler',
      component: () => import('../pages/java/components/mybatis/mybatiscode/MybatisCodeResultSetHandler'),
    },
    {
      path: '/mybatisCodeCglibProxyFactory',
      component: () => import('../pages/java/components/mybatis/mybatiscode/MybatisCodeCglibProxyFactory'),
    },
    {
      path: '/mybatisCodePlugin',
      component: () => import('../pages/java/components/mybatis/mybatiscode/MybatisCodePlugin'),
    }, 
    {
      path: '/mybatisCodePageHelper',
      component: () => import('../pages/java/components/mybatis/mybatiscode/MybatisCodePageHelper'),
    }, 
    
    {
      path: '/mybatisCodeProjectOverview',
      component: () => import('../pages/java/components/mybatis/mybatiscode/MybatisCodeProjectOverview'),
    }, 
    {
      path: '/mybatisCodeParserModule',
      component: () => import('../pages/java/components/mybatis/mybatiscode/MybatisCodeParserModule'),
    }, 
    {
      path: '/mybatisCodeReflect',
      component: () => import('../pages/java/components/mybatis/mybatiscode/MybatisCodeReflect'),
    }, 
    {
      path: '/mybatisCodeExceptionModule',
      component: () => import('../pages/java/components/mybatis/mybatiscode/MybatisCodeExceptionModule'),
    }, 
    {
      path: '/mybatisCodeAffairModule',
      component: () => import('../pages/java/components/mybatis/mybatiscode/MybatisCodeAffairModule'),
    }, 
    {
      path: '/mybatisCodeCacheModule',
      component: () => import('../pages/java/components/mybatis/mybatiscode/MybatisCodeCacheModule'),
    }, 
    {
      path: '/mybatisCodeLogModule',
      component: () => import('../pages/java/components/mybatis/mybatiscode/MybatisCodeLogModule'),
    }, 
    {
      path: '/mybatisCodeTypeModule',
      component: () => import('../pages/java/components/mybatis/mybatiscode/MybatisCodeTypeModule'),
    },   
    {
      path: '/mybatisCodeProxyModul',
      component: () => import('../pages/java/components/mybatis/mybatiscode/MybatisCodeProxyModul'),
    },   
    {
      path: '/mybatisCodeDecoratorModule',
      component: () => import('../pages/java/components/mybatis/mybatiscode/MybatisCodeDecoratorModule'),
    },  
    {
      path: '/mybatisCodeStragy',
      component: () => import('../pages/java/components/mybatis/mybatiscode/MybatisCodeStragy'),
    }, 
    {
      path: '/mybatisCodeExecutorDecoratorModule',
      component: () => import('../pages/java/components/mybatis/mybatiscode/MybatisCodeExecutorDecoratorModule'),
    }, 
    {
      path: '/mybatisCodePluginProxy',
      component: () => import('../pages/java/components/mybatis/mybatiscode/MybatisCodePluginProxy'),
    }, 
    {
      path: '/mybatisCodeSqlSessionStragy',
      component: () => import('../pages/java/components/mybatis/mybatiscode/MybatisCodeSqlSessionStragy'),
    },
    {
      path: '/mybatisCodeStatementHandler',
      component: () => import('../pages/java/components/mybatis/mybatiscode/MybatisCodeStatementHandler'),
    }, 
    {
      path: '/mybatisCodeParameterHandler',
      component: () => import('../pages/java/components/mybatis/mybatiscode/MybatisCodeParameterHandler'),
    }, 
    {
      path: '/mybatisCodeResultSetHandlerMapper',
      component: () => import('../pages/java/components/mybatis/mybatiscode/MybatisCodeResultSetHandlerMapper'),
    }, 
    {
      path: '/mybatisCodeCoreFlowInit',
      component: () => import('../pages/java/components/mybatis/mybatiscode/MybatisCodeCoreFlowInit'),
    }, 
    {
      path: '/mybatisCodeCoreFlowProxy',
      component: () => import('../pages/java/components/mybatis/mybatiscode/MybatisCodeCoreFlowProxy'),
    }, 
    {
      path: '/mybatisCodeCoreFlowReadData',
      component: () => import('../pages/java/components/mybatis/mybatiscode/MybatisCodeCoreFlowReadData'),
    }, 
    {
      path: '/mybatisCodeCoreFlowPic',
      component: () => import('../pages/java/components/mybatis/mybatiscode/MybatisCodeCoreFlowPic'),
    }, 

    
    
    {
      path: '/springBaseSpringBeanType',
      component: () => import('../pages/java/components/spring/springcode/SpringBaseSpringBeanType'),
    },
    {
      path: '/springBaseStatus',
      component: () => import('../pages/java/components/spring/springcode/SpringBaseStatus'),
    },
    {
      path: '/springCodeLabel',
      component: () => import('../pages/java/components/spring/springcode/SpringCodeLabel'),
    },
    {
      path: '/springCodeContainerImpl',
      component: () => import('../pages/java/components/spring/springcode/SpringCodeContainerImpl'),
    },
    {
      path: '/springCodeCustomizeLabel',
      component: () => import('../pages/java/components/spring/springcode/SpringCodeCustomizeLabel'),
    },
    {
      path: '/springCodeBeanLoadFlow',
      component: () => import('../pages/java/components/spring/springcode/SpringCodeBeanLoadFlow'),
    },
    {
      path: '/springCodeApplicationContext',
      component: () => import('../pages/java/components/spring/springcode/SpringCodeApplicationContext'),
    },
    {
      path: '/springCodeSpringAOP',
      component: () => import('../pages/java/components/spring/springcode/SpringCodeSpringAOP'),
    },   
    {
      path: '/springCodeSpringAffair',
      component: () => import('../pages/java/components/spring/springcode/SpringCodeSpringAffair'),
    },   
    {
      path: '/springCodeScheduled',
      component: () => import('../pages/java/components/spring/springcode/SpringCodeScheduled'),
    },  
    {
      path: '/springCodeSpringMVC',
      component: () => import('../pages/java/components/spring/springcode/SpringCodeSpringMVC'),
    },  
    {
      path: '/springCodeAnnotatedBeanDefinitionReader',
      component: () => import('../pages/java/components/spring/springcode/SpringCodeAnnotatedBeanDefinitionReader'),
    },  
    {
      path: '/springCodeBeanDefinition',
      component: () => import('../pages/java/components/spring/springcode/SpringCodeBeanDefinition'),
    },    
    {
      path: '/springCodeClassPathBeanDefinition',
      component: () => import('../pages/java/components/spring/springcode/SpringCodeClassPathBeanDefinition'),
    }, 
    {
      path: '/springCodeAnnotatedBeanDefinitionReaderReflesh',
      component: () => import('../pages/java/components/spring/springcode/SpringCodeAnnotatedBeanDefinitionReaderReflesh'),
    }, 
    {
      path: '/springCodeInvokeBeanFactoryPostProcessors',
      component: () => import('../pages/java/components/spring/springcode/SpringCodeInvokeBeanFactoryPostProcessors'),
    }, 
    {
      path: '/springCodeConfigurationClassPostProcessor',
      component: () => import('../pages/java/components/spring/springcode/SpringCodeConfigurationClassPostProcessor'),
    }, 
    {
      path: '/springCodeConfigurationClassParser',
      component: () => import('../pages/java/components/spring/springcode/SpringCodeConfigurationClassParser'),
    }, 
    {
      path: '/springCodeConfigurationClassParserBean',
      component: () => import('../pages/java/components/spring/springcode/SpringCodeConfigurationClassParserBean'),
    }, 
    {
      path: '/springCodeInvokeBeanFactoryPostProcessorsSecond',
      component: () => import('../pages/java/components/spring/springcode/SpringCodeInvokeBeanFactoryPostProcessorsSecond'),
    }, 
    {
      path: '/springCodeInvokeBeanFactoryPostProcessorsThird',
      component: () => import('../pages/java/components/spring/springcode/SpringCodeInvokeBeanFactoryPostProcessorsThird'),
    }, 
    {
      path: '/springCodeSpringCglib',
      component: () => import('../pages/java/components/spring/springcode/SpringCodeSpringCglib'),
    }, 
    {
      path: '/springCodeInvokeBeanFactoryPostProcessorsforth',
      component: () => import('../pages/java/components/spring/springcode/SpringCodeInvokeBeanFactoryPostProcessorsforth'),
    }, 
    {
      path: '/springCodeFinishBeanFactoryInitialization',
      component: () => import('../pages/java/components/spring/springcode/SpringCodeFinishBeanFactoryInitialization'),
    }, 
    {
      path: '/springCodeIsFactoryBean',
      component: () => import('../pages/java/components/spring/springcode/SpringCodeIsFactoryBean'),
    }, 
    {
      path: '/springCodeGetBeanOne',
      component: () => import('../pages/java/components/spring/springcode/SpringCodeGetBeanOne'),
    }, 
    {
      path: '/springCodeGetBeanTwo',
      component: () => import('../pages/java/components/spring/springcode/SpringCodeGetBeanTwo'),
    }, 
    {
      path: '/springCodeGetBeanThree',
      component: () => import('../pages/java/components/spring/springcode/SpringCodeGetBeanThree'),
    }, 
    {
      path: '/springCodeGetBeanforth',
      component: () => import('../pages/java/components/spring/springcode/SpringCodeGetBeanforth'),
    }, 
    {
      path: '/springCodeGetBeanFive',
      component: () => import('../pages/java/components/spring/springcode/SpringCodeGetBeanFive'),
    }, 
    {
      path: '/springCodeGetBeanSix',
      component: () => import('../pages/java/components/spring/springcode/SpringCodeGetBeanSix'),
    },   
    {
      path: '/springCodeGetBeanSeven',
      component: () => import('../pages/java/components/spring/springcode/SpringCodeGetBeanSeven'),
    }, 
    {
      path: '/springCodeGetBeanEight',
      component: () => import('../pages/java/components/spring/springcode/SpringCodeGetBeanEight'),
    }, 
    {
      path: '/springCodeGetBeanNine',
      component: () => import('../pages/java/components/spring/springcode/SpringCodeGetBeanNine'),
    }, 
    {
      path: '/springCodeGetBeanTenInstantiateBean',
      component: () => import('../pages/java/components/spring/springcode/SpringCodeGetBeanTenInstantiateBean'),
    }, 
    {
      path: '/springCodeGetBeanTenAutowireConstructor',
      component: () => import('../pages/java/components/spring/springcode/SpringCodeGetBeanTenAutowireConstructor'),
    }, 
    {
      path: '/springCodeGetBeanEleven',
      component: () => import('../pages/java/components/spring/springcode/SpringCodeGetBeanEleven'),
    }, 
    {
      path: '/springCodeGetBeanTwelve',
      component: () => import('../pages/java/components/spring/springcode/SpringCodeGetBeanTwelve'),
    }, 
    {
      path: '/springCodeGetBeanThirteen',
      component: () => import('../pages/java/components/spring/springcode/SpringCodeGetBeanThirteen'),
    }, 
    {
      path: '/springCodeGetBeanFifteen',
      component: () => import('../pages/java/components/spring/springcode/SpringCodeGetBeanFifteen'),
    }, 
    {
      path: '/springCodeGetBeanSixTeen',
      component: () => import('../pages/java/components/spring/springcode/SpringCodeGetBeanSixTeen'),
    }, 
    {
      path: '/springCodeGetBeanSeventeen',
      component: () => import('../pages/java/components/spring/springcode/SpringCodeGetBeanSeventeen'),
    }, 
  
    {
      path: '/springCodeInstantiateExtend',
      component: () => import('../pages/java/components/spring/springcode/SpringCodeInstantiateExtend'),
    },  
    {
      path: '/springCodeConstructExtend',
      component: () => import('../pages/java/components/spring/springcode/SpringCodeConstructExtend'),
    },  
    {
      path: '/springCodeMergedBeanDefinitionPostProcessor',
      component: () => import('../pages/java/components/spring/springcode/SpringCodeMergedBeanDefinitionPostProcessor'),
    },     
    {
      path: '/springCodeInstantiatePostExtend',
      component: () => import('../pages/java/components/spring/springcode/SpringCodeInstantiatePostExtend'),
    },     
    {
      path: '/springCodeInstantiateRelateExtend',
      component: () => import('../pages/java/components/spring/springcode/SpringCodeInstantiateRelateExtend'),
    },     
    {
      path: '/springCodeRegisterCallback',
      component: () => import('../pages/java/components/spring/springcode/SpringCodeRegisterCallback'),
    },          
    {
      path: '/springCodeBeanPostProcessor',
      component: () => import('../pages/java/components/spring/springcode/SpringCodeBeanPostProcessor'),
    },          
    {
      path: '/springCodeConfigurationBeanOne',
      component: () => import('../pages/java/components/spring/springcode/SpringCodeConfigurationBeanOne'),
    },  
    {
      path: '/springCodeConfigurationBeanTwo',
      component: () => import('../pages/java/components/spring/springcode/SpringCodeConfigurationBeanTwo'),
    },   
    {
      path: '/springCodeConfigurationBeanThree',
      component: () => import('../pages/java/components/spring/springcode/SpringCodeConfigurationBeanThree'),
    },   
    {
      path: '/springCodeConfigurationBeanForth',
      component: () => import('../pages/java/components/spring/springcode/SpringCodeConfigurationBeanForth'),
    },   
    {
      path: '/springCodeAutoAssembly',
      component: () => import('../pages/java/components/spring/springcode/SpringCodeAutoAssembly'),
    }, 
    {
      path: '/springCodeAutoAssemblyTwo',
      component: () => import('../pages/java/components/spring/springcode/SpringCodeAutoAssemblyTwo'),
    }, 
    {
      path: '/springCodeQualifierOne',
      component: () => import('../pages/java/components/spring/springcode/SpringCodeQualifierOne'),
    },     
    {
      path: '/springCodeQualifierTwo',
      component: () => import('../pages/java/components/spring/springcode/SpringCodeQualifierTwo'),
    },      
    {
      path: '/springCodeQualifierThree',
      component: () => import('../pages/java/components/spring/springcode/SpringCodeQualifierThree'),
    },      
    {
      path: '/springCodeEnableAspectJAutoProxy',
      component: () => import('../pages/java/components/spring/springcode/SpringCodeEnableAspectJAutoProxy'),
    },  
    {
      path: '/springCodeAnnotationAwareAspectJAutoProxyCreator',
      component: () => import('../pages/java/components/spring/springcode/SpringCodeAnnotationAwareAspectJAutoProxyCreator'),
    },  
    {
      path: '/springCodeAspectOne',
      component: () => import('../pages/java/components/spring/springcode/SpringCodeAspectOne'),
    }, 
    {
      path: '/springCodeAspectTwo',
      component: () => import('../pages/java/components/spring/springcode/SpringCodeAspectTwo'),
    }, 
    {
      path: '/springCodeCreateProxy',
      component: () => import('../pages/java/components/spring/springcode/SpringCodeCreateProxy'),
    },
    {
      path: '/springCodeWrapIfNecessary',
      component: () => import('../pages/java/components/spring/springcode/SpringCodeWrapIfNecessary'),
    },
    {
      path: '/springCodeJdkDynamicAopProxy',
      component: () => import('../pages/java/components/spring/springcode/SpringCodeJdkDynamicAopProxy'),
    },
    {
      path: '/springCodeNoticeOrderExplore',
      component: () => import('../pages/java/components/spring/springcode/SpringCodeNoticeOrderExplore'),
    },
    {
      path: '/springCodeAffaireInitOne',
      component: () => import('../pages/java/components/spring/springcode/SpringCodeAffaireInitOne'),
    },     
    {
      path: '/springCodeEnableTransactionManagement',
      component: () => import('../pages/java/components/spring/springcode/SpringCodeEnableTransactionManagement'),
    },     
    {
      path: '/springCodeAffaireInitTwo',
      component: () => import('../pages/java/components/spring/springcode/SpringCodeAffaireInitTwo'),
    },   
    {
      path: '/springCodeAopAffaireOne',
      component: () => import('../pages/java/components/spring/springcode/SpringCodeAopAffaireOne'),
    },   
    {
      path: '/springCodeAopAffaireTwo',
      component: () => import('../pages/java/components/spring/springcode/SpringCodeAopAffaireTwo'),
    }, 
    {
      path: '/springCodeAopAffaireThree',
      component: () => import('../pages/java/components/spring/springcode/SpringCodeAopAffaireThree'),
    }, 
    {
      path: '/springCodeAopAffaireFour',
      component: () => import('../pages/java/components/spring/springcode/SpringCodeAopAffaireFour'),
    }, 
    {
      path: '/springCodeAopAffaireFive',
      component: () => import('../pages/java/components/spring/springcode/SpringCodeAopAffaireFive'),
    }, 
    {
      path: '/springCodeAopAffaireSix',
      component: () => import('../pages/java/components/spring/springcode/SpringCodeAopAffaireSix'),
    }, 
    {
      path: '/springCodeAopAffaireSeven',
      component: () => import('../pages/java/components/spring/springcode/SpringCodeAopAffaireSeven'),
    }, 
    {
      path: '/springCodeAopAffaireEight',
      component: () => import('../pages/java/components/spring/springcode/SpringCodeAopAffaireEight'),
    }, 
    {
      path: '/springCodeAffairePrincipleOne',
      component: () => import('../pages/java/components/spring/springcode/SpringCodeAffairePrincipleOne'),
    },   
    {
      path: '/springCodeAffairePrincipleTwo',
      component: () => import('../pages/java/components/spring/springcode/SpringCodeAffairePrincipleTwo'),
    },
    {
      path: '/springCodeAffairePrincipleThree',
      component: () => import('../pages/java/components/spring/springcode/SpringCodeAffairePrincipleThree'),
    },
    {
      path: '/springCodeAffairePrincipleFour',
      component: () => import('../pages/java/components/spring/springcode/SpringCodeAffairePrincipleFour'),
    },
    {
      path: '/springCodeAffairePrincipleFive',
      component: () => import('../pages/java/components/spring/springcode/SpringCodeAffairePrincipleFive'),
    },
    {
      path: '/springCodeAffairePrincipleSix',
      component: () => import('../pages/java/components/spring/springcode/SpringCodeAffairePrincipleSix'),
    },
    {
      path: '/springCodeAffaireSummary',
      component: () => import('../pages/java/components/spring/springcode/SpringCodeAffaireSummary'),
    },
    {
      path: '/springCodeCglibOne',
      component: () => import('../pages/java/components/spring/springcode/SpringCodeCglibOne'),
    },
    {
      path: '/springCodeCglibTwo',
      component: () => import('../pages/java/components/spring/springcode/SpringCodeCglibTwo'),
    },
    {
      path: '/springCodeCglibThree',
      component: () => import('../pages/java/components/spring/springcode/SpringCodeCglibThree'),
    },
    {
      path: '/springbootStartOne',
      component: () => import('../pages/java/components/springboot/springboot/SpringbootStartOne'),
    },
    {
      path: '/springbootStartTwo',
      component: () => import('../pages/java/components/springboot/springboot/SpringbootStartTwo'),
    },
    {
      path: '/springbootStartThree',
      component: () => import('../pages/java/components/springboot/springboot/SpringbootStartThree'),
    },
    {
      path: '/springbootStartFour',
      component: () => import('../pages/java/components/springboot/springboot/SpringbootStartFour'),
    },
    {
      path: '/springbootStartFive',
      component: () => import('../pages/java/components/springboot/springboot/SpringbootStartFive'),
    },
    {
      path: '/springbootStartSix',
      component: () => import('../pages/java/components/springboot/springboot/SpringbootStartSix'),
    },
    {
      path: '/springbootStartSeven',
      component: () => import('../pages/java/components/springboot/springboot/SpringbootStartSeven'),
    },
    {
      path: '/springbootAutoConfigOne',
      component: () => import('../pages/java/components/springboot/springboot/SpringbootAutoConfigOne'),
    },
    {
      path: '/springbootAutoConfigTwo',
      component: () => import('../pages/java/components/springboot/springboot/SpringbootAutoConfigTwo'),
    },
    {
      path: '/springbootAutoConfigThree',
      component: () => import('../pages/java/components/springboot/springboot/SpringbootAutoConfigThree'),
    },
    {
      path: '/springbootAutoConfigFour',
      component: () => import('../pages/java/components/springboot/springboot/SpringbootAutoConfigFour'),
    },
    {
      path: '/springbootAutoConfigFive',
      component: () => import('../pages/java/components/springboot/springboot/SpringbootAutoConfigFive'),
    },
    {
      path: '/springbootAutoConfigSix',
      component: () => import('../pages/java/components/springboot/springboot/SpringbootAutoConfigSix'),
    },
    {
      path: '/springbootSpringMVCWebOne',
      component: () => import('../pages/java/components/springboot/springboot/SpringbootSpringMVCWebOne'),
    },
    {
      path: '/springbootSpringMVCWebTwo',
      component: () => import('../pages/java/components/springboot/springboot/SpringbootSpringMVCWebTwo'),
    },
    {
      path: '/springbootSpringMVCWebThree',
      component: () => import('../pages/java/components/springboot/springboot/SpringbootSpringMVCWebThree'),
    },
    {
      path: '/springbootSpringMVCWebFour',
      component: () => import('../pages/java/components/springboot/springboot/SpringbootSpringMVCWebFour'),
    },
    {
      path: '/springbootDispatcherServletOne',
      component: () => import('../pages/java/components/springboot/springboot/SpringbootDispatcherServletOne'),
    },
    {
      path: '/springbootDispatcherServletTwo',
      component: () => import('../pages/java/components/springboot/springboot/SpringbootDispatcherServletTwo'),
    },
    {
      path: '/springbootDispatcherServletThree',
      component: () => import('../pages/java/components/springboot/springboot/SpringbootDispatcherServletThree'),
    },
    {
      path: '/springbootGetHandlerOne',
      component: () => import('../pages/java/components/springboot/springboot/SpringbootGetHandlerOne'),
    },
    {
      path: '/springbootGetHandlerTwo',
      component: () => import('../pages/java/components/springboot/springboot/SpringbootGetHandlerTwo'),
    },
    {
      path: '/springbootRequestMappingHandlerMappingOne',
      component: () => import('../pages/java/components/springboot/springboot/SpringbootRequestMappingHandlerMappingOne'),
    },
    {
      path: '/springbootRequestMappingHandlerMappingTwo',
      component: () => import('../pages/java/components/springboot/springboot/SpringbootRequestMappingHandlerMappingTwo'),
    },
    {
      path: '/springbootRequestMappingHandlerMappingThree',
      component: () => import('../pages/java/components/springboot/springboot/SpringbootRequestMappingHandlerMappingThree'),
    },
    {
      path: '/springbootGetHandlerAdapter',
      component: () => import('../pages/java/components/springboot/springboot/SpringbootGetHandlerAdapter'),
    },
    {
      path: '/springbootRequestMappingHandlerAdapterOne',
      component: () => import('../pages/java/components/springboot/springboot/SpringbootRequestMappingHandlerAdapterOne'),
    },
    {
      path: '/springbootRequestMappingHandlerAdapterTwo',
      component: () => import('../pages/java/components/springboot/springboot/SpringbootRequestMappingHandlerAdapterTwo'),
    },
    {
      path: '/springbootRequestMappingHandlerAdapterThree',
      component: () => import('../pages/java/components/springboot/springboot/SpringbootRequestMappingHandlerAdapterThree'),
    },
    {
      path: '/springbootRequestMappingHandlerAdapterFour',
      component: () => import('../pages/java/components/springboot/springboot/SpringbootRequestMappingHandlerAdapterFour'),
    },
    {
      path: '/springbootRequestMappingHandlerAdapterFive',
      component: () => import('../pages/java/components/springboot/springboot/SpringbootRequestMappingHandlerAdapterFive'),
    },
    {
      path: '/springbootRequestMappingHandlerAdapterSix',
      component: () => import('../pages/java/components/springboot/springboot/SpringbootRequestMappingHandlerAdapterSix'),
    },
    {
      path: '/springbootDispatcherServletResults',
      component: () => import('../pages/java/components/springboot/springboot/SpringbootDispatcherServletResults'),
    },
    {
      path: '/springbootSpringmvcFilter',
      component: () => import('../pages/java/components/springboot/springboot/SpringbootSpringmvcFilter'),
    },
    {
      path: '/springbootSpringmvcModelOne',
      component: () => import('../pages/java/components/springboot/springboot/SpringbootSpringmvcModelOne'),
    },   
    {
      path: '/springbootSpringmvcModelTwo',
      component: () => import('../pages/java/components/springboot/springboot/SpringbootSpringmvcModelTwo'),
    },
    {
      path: '/springbootSpringmvcModelThree',
      component: () => import('../pages/java/components/springboot/springboot/SpringbootSpringmvcModelThree'),
    },   
    {
      path: '/springbootSpringmvcDataOne',
      component: () => import('../pages/java/components/springboot/springboot/SpringbootSpringmvcDataOne'),
    },   
    {
      path: '/springbootSpringmvcDataTwo',
      component: () => import('../pages/java/components/springboot/springboot/SpringbootSpringmvcDataTwo'),
    },
    {
      path: '/springbootSpringmvcDataThree',
      component: () => import('../pages/java/components/springboot/springboot/SpringbootSpringmvcDataThree'),
    },  
    {
      path: '/springbootSimpleUrlHandlerMappingOne',
      component: () => import('../pages/java/components/springboot/springboot/SpringbootSimpleUrlHandlerMappingOne'),
    },   
    {
      path: '/springbootSimpleUrlHandlerMappingTwo',
      component: () => import('../pages/java/components/springboot/springboot/SpringbootSimpleUrlHandlerMappingTwo'),
    },
    {
      path: '/springbootBeanNameUrlHandlerMapping',
      component: () => import('../pages/java/components/springboot/springboot/SpringbootBeanNameUrlHandlerMapping'),
    },  
    {
      path: '/springbootSpringmvcExceptionOne',
      component: () => import('../pages/java/components/springboot/springboot/SpringbootSpringmvcExceptionOne'),
    },   
    {
      path: '/springbootSpringmvcExceptionTwo',
      component: () => import('../pages/java/components/springboot/springboot/SpringbootSpringmvcExceptionTwo'),
    },
    {
      path: '/springbootSpringmvcExceptionThree',
      component: () => import('../pages/java/components/springboot/springboot/SpringbootSpringmvcExceptionThree'),
    },  
    {
      path: '/springbootSpringmvcExceptionFour',
      component: () => import('../pages/java/components/springboot/springboot/SpringbootSpringmvcExceptionFour'),
    },   
    {
      path: '/springbootValue',
      component: () => import('../pages/java/components/springboot/springboot/SpringbootValue'),
    },  
    {
      path: '/springbootConfigurationPropertiesOne',
      component: () => import('../pages/java/components/springboot/springboot/SpringbootConfigurationPropertiesOne'),
    },   
    {
      path: '/springbootConfigurationPropertiesTwo',
      component: () => import('../pages/java/components/springboot/springboot/SpringbootConfigurationPropertiesTwo'),
    },
    {
      path: '/springbootConfigFileApplicationListenerOne',
      component: () => import('../pages/java/components/springboot/springboot/SpringbootConfigFileApplicationListenerOne'),
    },
    {
      path: '/springbootConfigFileApplicationListenerTwo',
      component: () => import('../pages/java/components/springboot/springboot/SpringbootConfigFileApplicationListenerTwo'),
    },   
    {
      path: '/springbootConfigFileApplicationListenerThree',
      component: () => import('../pages/java/components/springboot/springboot/SpringbootConfigFileApplicationListenerThree'),
    },
    {
      path: '/springbootConfigFileApplicationListenerFour',
      component: () => import('../pages/java/components/springboot/springboot/SpringbootConfigFileApplicationListenerFour'),
    },  
    {
      path: '/springbootSummary',
      component: () => import('../pages/java/components/springboot/springboot/SpringbootSummary'),
    },  
    
    {
      path: '/springBaseIntroduce',
      component: () => import('../pages/java/components/spring/springbase/SpringBaseIntroduce'),
    },  
    {
      path: '/springBaseThreeCores',
      component: () => import('../pages/java/components/spring/springbase/SpringBaseThreeCores'),
    },  
    {
      path: '/springBaseModule',
      component: () => import('../pages/java/components/spring/springbase/SpringBaseModule'),
    },  
    {
      path: '/springBaseBeanFactory',
      component: () => import('../pages/java/components/spring/springbase/SpringBaseBeanFactory'),
    }, 
    {
      path: '/springBaseTransactional',
      component: () => import('../pages/java/components/spring/springbase/SpringBaseTransactional'),
    }, 
    {
      path: '/springBaseApplicationContextExtend',
      component: () => import('../pages/java/components/spring/springbase/SpringBaseApplicationContextExtend'),
    }, 


    {
      path: '/springApplicationOverview',
      component: () => import('../pages/java/components/spring/springbase/SpringApplicationOverview'),
    }, 
    {
      path: '/springApplicationContext',
      component: () => import('../pages/java/components/spring/springbase/SpringApplicationContext'),
    }, 
    {
      path: '/springApplicationProcedure',
      component: () => import('../pages/java/components/spring/springbase/SpringApplicationProcedure'),
    }, 
    {
      path: '/springApplicationBeanConfig',
      component: () => import('../pages/java/components/spring/springbase/SpringApplicationBeanConfig'),
    }, 
    {
      path: '/springApplicationConstruct',
      component: () => import('../pages/java/components/spring/springbase/SpringApplicationConstruct'),
    }, 
    {
      path: '/springApplicationStaticFactory',
      component: () => import('../pages/java/components/spring/springbase/SpringApplicationStaticFactory'),
    }, 
    {
      path: '/springApplicationInstanceFactory',
      component: () => import('../pages/java/components/spring/springbase/SpringApplicationInstanceFactory'),
    }, 
    {
      path: '/springApplicationBeanScope',
      component: () => import('../pages/java/components/spring/springbase/SpringApplicationBeanScope'),
    }, 
    {
      path: '/springApplicationBeanLifeCycle',
      component: () => import('../pages/java/components/spring/springbase/SpringApplicationBeanLifeCycle'),
    }, 
    {
      path: '/springApplicationBeanXml',
      component: () => import('../pages/java/components/spring/springbase/SpringApplicationBeanXml'),
    }, 
    {
      path: '/springApplicationBeanAnnotation',
      component: () => import('../pages/java/components/spring/springbase/SpringApplicationBeanAnnotation'),
    }, 
    {
      path: '/springApplicationBeanAuto',
      component: () => import('../pages/java/components/spring/springbase/SpringApplicationBeanAuto'),
    },  
    
    {
      path: '/springAopOverview',
      component: () => import('../pages/java/components/spring/springaop/SpringAopOverview'),
    }, 
    {
      path: '/springJDKDynamic',
      component: () => import('../pages/java/components/spring/springaop/SpringJDKDynamic'),
    }, 
    {
      path: '/springCglibDynamic',
      component: () => import('../pages/java/components/spring/springaop/SpringCglibDynamic'),
    }, 
    {
      path: '/springDynamicAop',
      component: () => import('../pages/java/components/spring/springaop/SpringDynamicAop'),
    }, 
    {
      path: '/springAspectJ',
      component: () => import('../pages/java/components/spring/springaop/SpringAspectJ'),
    }, 
    {
      path: '/springAnnotationAspectJ',
      component: () => import('../pages/java/components/spring/springaop/SpringAnnotationAspectJ'),
    }, 
    
    {
      path: '/springDatabaseJdbc',
      component: () => import('../pages/java/components/spring/springdatabase/SpringDatabaseJdbc'),
    }, 
    {
      path: '/springDatabaseExecute',
      component: () => import('../pages/java/components/spring/springdatabase/SpringDatabaseExecute'),
    }, 
    {
      path: '/springDatabaseUpdate',
      component: () => import('../pages/java/components/spring/springdatabase/SpringDatabaseUpdate'),
    }, 
    {
      path: '/springDatabaseQuery',
      component: () => import('../pages/java/components/spring/springdatabase/SpringDatabaseQuery'),
    }, 
    {
      path: '/springDatabaseTransaction',
      component: () => import('../pages/java/components/spring/springdatabase/SpringDatabaseTransaction'),
    }, 
    {
      path: '/springDatabaseTransactionXml',
      component: () => import('../pages/java/components/spring/springdatabase/SpringDatabaseTransactionXml'),
    }, 
    {
      path: '/springDatabaseTransactionAnnotation',
      component: () => import('../pages/java/components/spring/springdatabase/SpringDatabaseTransactionAnnotation'),
    }, 
    
    



    {
      path: '/springCodeCircleDependence',
      component: () => import('../pages/java/components/spring/springbase/SpringCodeCircleDependence'),
    }, 
    {
      path: '/springCodeResourceAutowired',
      component: () => import('../pages/java/components/spring/springbase/SpringCodeResourceAutowired'),
    }, 
  
    {
      path: '/springbootBaseLog',
      component: () => import('../pages/java/components/springboot/springboottask/SpringbootBaseLog'),
    }, 
    
    {
      path: '/springCodeSwagger',
      component: () => import('../pages/java/components/springboot/springbootimplweb/SpringCodeSwagger'),
    },  
   
    {
      path: '/springCodeStaticResource',
      component: () => import('../pages/java/components/springboot/springbootviewtech/SpringCodeStaticResource'),
    }, 
   
    {
      path: '/springCodeGlobalException',
      component: () => import('../pages/java/components/springboot/springbootimplweb/SpringCodeGlobalException'),
    },  
    {
      path: '/springCodeParamsValidate',
      component: () => import('../pages/java/components/springboot/springbootimplweb/SpringCodeParamsValidate'),
    },  
    {
      path: '/springCodeMultiSubmit',
      component: () => import('../pages/java/components/springboot/springbootimplweb/SpringCodeMultiSubmit'),
    },  
    {
      path: '/springCodeHotDeploy',
      component: () => import('../pages/java/components/springboot/springbootimplweb/SpringCodeHotDeploy'),
    },  
    {
      path: '/springCodeHealthCheck',
      component: () => import('../pages/java/components/springboot/springbootsecurity/SpringCodeHealthCheck'),
    }, 
    
     
    {
      path: '/springbootIntroduce',
      component: () => import('../pages/java/components/springboot/springbootdevelopoverview/SpringbootIntroduce'),
    }, 
    {
      path: '/springbootEnv',
      component: () => import('../pages/java/components/springboot/springbootdevelopoverview/SpringbootEnv'),
    }, 
    {
      path: '/springbootMaven',
      component: () => import('../pages/java/components/springboot/springbootdevelopoverview/SpringbootMaven'),
    }, 
    {
      path: '/springBaseInitializr',
      component: () => import('../pages/java/components/springboot/springbootdevelopoverview/SpringBaseInitializr'),
    }, 
    {
      path: '/springbootUnitTest',
      component: () => import('../pages/java/components/springboot/springbootdevelopoverview/SpringbootUnitTest'),
    }, 
    {
      path: '/springbootDependency',
      component: () => import('../pages/java/components/springboot/springbootdevelopoverview/SpringbootDependency'),
    }, 
    {
      path: '/springbootAutoConfig',
      component: () => import('../pages/java/components/springboot/springbootdevelopoverview/SpringbootAutoConfig'),
    }, 
    {
      path: '/springbootProcess',
      component: () => import('../pages/java/components/springboot/springbootdevelopoverview/SpringbootProcess'),
    }, 
    
    
    {
      path: '/springbootProperties',
      component: () => import('../pages/java/components/springboot/springbootconfiganno/SpringbootProperties'),
    }, 
    {
      path: '/springbootYaml',
      component: () => import('../pages/java/components/springboot/springbootconfiganno/SpringbootYaml'),
    }, 
    {
      path: '/springbootConfigurationProperties',
      component: () => import('../pages/java/components/springboot/springbootconfiganno/springbootConfigurationProperties'),
    }, 
    {
      path: '/springbootonValueProperties',
      component: () => import('../pages/java/components/springboot/springbootconfiganno/SpringbootonValueProperties'),
    }, 
    {
      path: '/springbootAnnoCompare',
      component: () => import('../pages/java/components/springboot/springbootconfiganno/SpringbootAnnoCompare'),
    }, 
    {
      path: '/springbootPropertySource',
      component: () => import('../pages/java/components/springboot/springbootconfiganno/SpringbootPropertySource'),
    }, 
    {
      path: '/springbootImportResource',
      component: () => import('../pages/java/components/springboot/springbootconfiganno/SpringbootImportResource'),
    }, 
    {
      path: '/springbootConfigSelfDefine',
      component: () => import('../pages/java/components/springboot/springbootconfiganno/SpringbootConfigSelfDefine'),
    }, 
    {
      path: '/springbootProfileMultiEnv',
      component: () => import('../pages/java/components/springboot/springbootconfiganno/SpringbootProfileMultiEnv'),
    },  
    {
      path: '/springbootProfileAnnoMultiEnv',
      component: () => import('../pages/java/components/springboot/springbootconfiganno/SpringbootProfileAnnoMultiEnv'),
    },  
    {
      path: '/springbootRandomParams',
      component: () => import('../pages/java/components/springboot/springbootconfiganno/SpringbootRandomParams'),
    }, 


      
    {
      path: '/springbootDataOverview',
      component: () => import('../pages/java/components/springboot/Springbootdata/SpringbootDataOverview'),
    },   
    {
      path: '/springbootMybatisEnv',
      component: () => import('../pages/java/components/springboot/Springbootdata/SpringbootMybatisEnv'),
    },  
    {
      path: '/springbootMybatisAnno',
      component: () => import('../pages/java/components/springboot/Springbootdata/SpringbootMybatisAnno'),
    },  
    {
      path: '/springbootonMybatisConfig',
      component: () => import('../pages/java/components/springboot/Springbootdata/SpringbootonMybatisConfig'),
    },  
    {
      path: '/springbootDataJpaIntroduce',
      component: () => import('../pages/java/components/springboot/Springbootdata/SpringbootDataJpaIntroduce'),
    },   
    {
      path: '/springbootDataJpa',
      component: () => import('../pages/java/components/springboot/Springbootdata/SpringbootDataJpa'),
    },   
    {
      path: '/springbootRedis',
      component: () => import('../pages/java/components/springboot/Springbootdata/SpringbootRedis'),
    }, 
    
     
    {
      path: '/springbootViewTechIntroduce',
      component: () => import('../pages/java/components/springboot/springbootviewtech/SpringbootViewTechIntroduce'),
    }, 
    {
      path: '/springbootThymeleafLabel',
      component: () => import('../pages/java/components/springboot/springbootviewtech/SpringbootThymeleafLabel'),
    },  
    {
      path: '/springbootThymeleafExPress',
      component: () => import('../pages/java/components/springboot/springbootviewtech/SpringbootThymeleafExPress'),
    }, 
    {
      path: '/springbootThymeleafUses',
      component: () => import('../pages/java/components/springboot/springbootviewtech/SpringbootThymeleafUses'),
    }, 
    {
      path: '/springbootThymeleafDataView',
      component: () => import('../pages/java/components/springboot/springbootviewtech/SpringbootThymeleafDataView'),
    }, 
    {
      path: '/springbootThymeleafGroup',
      component: () => import('../pages/java/components/springboot/springbootviewtech/SpringbootThymeleafGroup'),
    }, 
    
    
    {
      path: '/springbootMvcIntegration',
      component: () => import('../pages/java/components/springboot/springbootimplweb/SpringbootMvcIntegration'),
    }, 
    {
      path: '/springbootRegisterServlet',
      component: () => import('../pages/java/components/springboot/springbootimplweb/SpringbootRegisterServlet'),
    }, 
    {
      path: '/springbootScanServlet',
      component: () => import('../pages/java/components/springboot/springbootimplweb/SpringbootScanServlet'),
    },  
    {
      path: '/springbootFileUpload',
      component: () => import('../pages/java/components/springboot/springbootimplweb/SpringbootFileUpload'),
    },  
    {
      path: '/springbootDownload',
      component: () => import('../pages/java/components/springboot/springbootimplweb/SpringbootDownload'),
    },  
    {
      path: '/springbootJar',
      component: () => import('../pages/java/components/springboot/springbootimplweb/SpringbootJar'),
    }, 
    {
      path: '/springbootWar',
      component: () => import('../pages/java/components/springboot/springbootimplweb/SpringbootWar'),
    }, 
    
    {
      path: '/springbootCacheEnvBuild',
      component: () => import('../pages/java/components/springboot/springbootcache/SpringbootCacheEnvBuild'),
    }, 
    {
      path: '/springbootDefaultCache',
      component: () => import('../pages/java/components/springboot/springbootcache/SpringbootDefaultCache'),
    }, 
    {
      path: '/springbootCacheAnno',
      component: () => import('../pages/java/components/springboot/springbootcache/SpringbootCacheAnno'),
    }, 
    {
      path: '/springbootCacheComponent',
      component: () => import('../pages/java/components/springboot/springbootcache/SpringbootCacheComponent'),
    }, 
    {
      path: '/springbootAnnoRedis',
      component: () => import('../pages/java/components/springboot/springbootcache/SpringbootAnnoRedis'),
    }, 
    {
      path: '/springbootApiRedis',
      component: () => import('../pages/java/components/springboot/springbootcache/SpringbootApiRedis'),
    }, 
    {
      path: '/springbootRedisTemplate',
      component: () => import('../pages/java/components/springboot/springbootcache/SpringbootRedisTemplate'),
    }, 
    {
      path: '/springbootRedisCacheManager',
      component: () => import('../pages/java/components/springboot/springbootcache/SpringbootRedisCacheManager'),
    }, 
    
    {
      path: '/springbootNoReturnAsyn',
      component: () => import('../pages/java/components/springboot/springboottask/SpringbootNoReturnAsyn'),
    }, 
    {
      path: '/springbootReturnAsyn',
      component: () => import('../pages/java/components/springboot/springboottask/SpringbootReturnAsyn'),
    }, 
    {
      path: '/springbootCron',
      component: () => import('../pages/java/components/springboot/springboottask/SpringbootCron'),
    }, 
    {
      path: '/springbootCronImpl',
      component: () => import('../pages/java/components/springboot/springboottask/SpringbootCronImpl'),
    }, 
    {
      path: '/springbootTextEmail',
      component: () => import('../pages/java/components/springboot/springboottask/SpringbootTextEmail'),
    }, 
    {
      path: '/springbootAttachEmail',
      component: () => import('../pages/java/components/springboot/springboottask/SpringbootAttachEmail'),
    }, 
    {
      path: '/springbootTemplateEmail',
      component: () => import('../pages/java/components/springboot/springboottask/SpringbootTemplateEmail'),
    }, 
    
    
    
    

    {
      path: '/springbootSecurityIntroduce',
      component: () => import('../pages/java/components/springboot/springbootsecurity/SpringbootSecurityIntroduce'),
    }, 
    {
      path: '/springbootEnvBuild',
      component: () => import('../pages/java/components/springboot/springbootsecurity/SpringbootEnvBuild'),
    }, 
    {
      path: '/springbootSecurityTest',
      component: () => import('../pages/java/components/springboot/springbootsecurity/SpringbootSecurityTest'),
    }, 
    {
      path: '/springbootSecurityConfig',
      component: () => import('../pages/java/components/springboot/springbootsecurity/SpringbootSecurityConfig'),
    }, 
    {
      path: '/springbootMemoryAuth',
      component: () => import('../pages/java/components/springboot/springbootsecurity/SpringbootMemoryAuth'),
    }, 
    {
      path: '/springbootJdbcAuth',
      component: () => import('../pages/java/components/springboot/springbootsecurity/SpringbootJdbcAuth'),
    }, 
    {
      path: '/springbootUserDetailsServic',
      component: () => import('../pages/java/components/springboot/springbootsecurity/SpringbootUserDetailsServic'),
    }, 
    {
      path: '/springbootDefineUserAccess',
      component: () => import('../pages/java/components/springboot/springbootsecurity/SpringbootDefineUserAccess'),
    }, 
    {
      path: '/springbootDefineUserLogin',
      component: () => import('../pages/java/components/springboot/springbootsecurity/SpringbootDefineUserLogin'),
    }, 
    {
      path: '/springbootDefineUserLogout',
      component: () => import('../pages/java/components/springboot/springbootsecurity/SpringbootDefineUserLogout'),
    }, 
    {
      path: '/springbootLoginUser',
      component: () => import('../pages/java/components/springboot/springbootsecurity/SpringbootLoginUser'),
    }, 
    {
      path: '/springbootRememberMyFunction',
      component: () => import('../pages/java/components/springboot/springbootsecurity/SpringbootRememberMyFunction'),
    }, 
    {
      path: '/springbootCsrf',
      component: () => import('../pages/java/components/springboot/springbootsecurity/SpringbootCsrf'),
    }, 
    {
      path: '/springbootSecurityManagePage',
      component: () => import('../pages/java/components/springboot/springbootsecurity/SpringbootSecurityManagePage'),
    }, 
    
    
    


    {
      path: '/meetingJvmOne',
      component: () => import('../pages/meeting/meetingjava/jvm/MeetingJvmOne'),
    },
    {
      path: '/meetingJvmTwo',
      component: () => import('../pages/meeting/meetingjava/jvm/MeetingJvmTwo'),
    },
    {
      path: '/meetingJvmThree',
      component: () => import('../pages/meeting/meetingjava/jvm/MeetingJvmThree'),
    },
    {
      path: '/meetingMybatisOne',
      component: () => import('../pages/meeting/meetingjava/mybatis/MeetingMybatisOne'),
    },
    {
      path: '/meetingMybatisTwo',
      component: () => import('../pages/meeting/meetingjava/mybatis/MeetingMybatisTwo'),
    },
    {
      path: '/meetingMybatisThree',
      component: () => import('../pages/meeting/meetingjava/mybatis/MeetingMybatisThree'),
    },
    {
      path: '/meetingRedisOne',
      component: () => import('../pages/meeting/meetingcache/redis/MeetingRedisOne'),
    },
    {
      path: '/meetingRedisTwo',
      component: () => import('../pages/meeting/meetingcache/redis/MeetingRedisTwo'),
    },
    {
      path: '/meetingRedisThree',
      component: () => import('../pages/meeting/meetingcache/redis/MeetingRedisThree'),
    },
    {
      path: '/meetingRedisFour',
      component: () => import('../pages/meeting/meetingcache/redis/MeetingRedisFour'),
    },
    {
      path: '/meetingMongodbOne',
      component: () => import('../pages/meeting/meetingcache/mongodb/MeetingMongodbOne'),
    },
    {
      path: '/meetingMongodbTwo',
      component: () => import('../pages/meeting/meetingcache/mongodb/MeetingMongodbTwo'),
    },
    {
      path: '/meetingMongodbThree',
      component: () => import('../pages/meeting/meetingcache/mongodb/MeetingMongodbThree'),
    },
    {
      path: '/meetingMemchachedOne',
      component: () => import('../pages/meeting/meetingcache/memchached/MeetingMemchachedOne'),
    },
    {
      path: '/meetingMemchachedTwo',
      component: () => import('../pages/meeting/meetingcache/memchached/MeetingMemchachedTwo'),
    },
    {
      path: '/meetingMemchachedThree',
      component: () => import('../pages/meeting/meetingcache/memchached/MeetingMemchachedThree'),
    },

    {
      path: '/meetingMysqlOne',
      component: () => import('../pages/meeting/meetingcache/mysql/MeetingMysqlOne'),
    },
    {
      path: '/meetingMysqlTwo',
      component: () => import('../pages/meeting/meetingcache/mysql/MeetingMysqlTwo'),
    },
    {
      path: '/meetingMysqlThree',
      component: () => import('../pages/meeting/meetingcache/mysql/MeetingMysqlThree'),
    },
    {
      path: '/meetingMysqlFour',
      component: () => import('../pages/meeting/meetingcache/mysql/MeetingMysqlFour'),
    },
    {
      path: '/meetingMysqlFive',
      component: () => import('../pages/meeting/meetingcache/mysql/MeetingMysqlFive'),
    },

    {
      path: '/meetingConcurrencyOne',
      component: () => import('../pages/meeting/meetingjava/concurrency/MeetingConcurrencyOne'),
    },
    {
      path: '/meetingConcurrencyTwo',
      component: () => import('../pages/meeting/meetingjava/concurrency/MeetingConcurrencyTwo'),
    },
    {
      path: '/meetingConcurrencyThree',
      component: () => import('../pages/meeting/meetingjava/concurrency/MeetingConcurrencyThree'),
    },
    {
      path: '/meetingConcurrencyFour',
      component: () => import('../pages/meeting/meetingjava/concurrency/MeetingConcurrencyFour'),
    },
    {
      path: '/meetingConcurrencyFive',
      component: () => import('../pages/meeting/meetingjava/concurrency/MeetingConcurrencyFive'),
    },
    {
      path: '/meetingSpringBaseOne',
      component: () => import('../pages/meeting/meetingspring/spring/MeetingSpringBaseOne'),
    },   
    {
      path: '/meetingSpringBaseTwo',
      component: () => import('../pages/meeting/meetingspring/spring/MeetingSpringBaseTwo'),
    },  
    {
      path: '/meetingSpringBaseThree',
      component: () => import('../pages/meeting/meetingspring/spring/MeetingSpringBaseThree'),
    },  
    {
      path: '/meetingSpringBaseFour',
      component: () => import('../pages/meeting/meetingspring/spring/MeetingSpringBaseFour'),
    }, 
    {
      path: '/meetingSpringBaseFive',
      component: () => import('../pages/meeting/meetingspring/spring/MeetingSpringBaseFive'),
    },  
    {
      path: '/meetingSpringMVCOne',
      component: () => import('../pages/meeting/meetingspring/springmvc/MeetingSpringMVCOne'),
    },  
    {
      path: '/meetingSpringMVCTwo',
      component: () => import('../pages/meeting/meetingspring/springmvc/MeetingSpringMVCTwo'),
    },  
    {
      path: '/meetingSpringMVCThree',
      component: () => import('../pages/meeting/meetingspring/springmvc/MeetingSpringMVCThree'),
    },  
    {
      path: '/meetingSpringBootOne',
      component: () => import('../pages/meeting/meetingspring/springboot/MeetingSpringBootOne'),
    },  
    {
      path: '/meetingSpringBootTwo',
      component: () => import('../pages/meeting/meetingspring/springboot/MeetingSpringBootTwo'),
    },  
    {
      path: '/meetingSpringBootThree',
      component: () => import('../pages/meeting/meetingspring/springboot/MeetingSpringBootThree'),
    },  
    {
      path: '/meetingSpringCloudOne',
      component: () => import('../pages/meeting/meetingframework/springcloud/MeetingSpringCloudOne'),
    }, 
    {
      path: '/meetingSpringCloudTwo',
      component: () => import('../pages/meeting/meetingframework/springcloud/MeetingSpringCloudTwo'),
    }, 
    {
      path: '/meetingSpringCloudThree',
      component: () => import('../pages/meeting/meetingframework/springcloud/MeetingSpringCloudThree'),
    }, 
    {
      path: '/meetingDubboOne',
      component: () => import('../pages/meeting/meetingframework/dubbo/MeetingDubboOne'),
    }, 
    {
      path: '/meetingDubboTwo',
      component: () => import('../pages/meeting/meetingframework/dubbo/MeetingDubboTwo'),
    }, 
    {
      path: '/meetingDubboThree',
      component: () => import('../pages/meeting/meetingframework/dubbo/MeetingDubboThree'),
    }, 
    {
      path: '/meetingKafkaOne',
      component: () => import('../pages/meeting/meetingmodule/kafka/MeetingKafkaOne'),
    },
    {
      path: '/meetingKafkaTwo',
      component: () => import('../pages/meeting/meetingmodule/kafka/MeetingKafkaTwo'),
    }, 
    {
      path: '/meetingKafkaThree',
      component: () => import('../pages/meeting/meetingmodule/kafka/MeetingKafkaThree'),
    },  
    {
      path: '/meetingRabbitMqOne',
      component: () => import('../pages/meeting/meetingmodule/rabbitmq/MeetingRabbitMqOne'),
    },
    {
      path: '/meetingRabbitMqTwo',
      component: () => import('../pages/meeting/meetingmodule/rabbitmq/MeetingRabbitMqTwo'),
    },
    {
      path: '/meetingRabbitMqThree',
      component: () => import('../pages/meeting/meetingmodule/rabbitmq/MeetingRabbitMqThree'),
    },
    {
      path: '/meetingRabbitMqFour',
      component: () => import('../pages/meeting/meetingmodule/rabbitmq/MeetingRabbitMqFour'),
    },
    {
      path: '/meetingActiveMqOne',
      component: () => import('../pages/meeting/meetingmodule/activemq/MeetingActiveMqOne'),
    },
    {
      path: '/meetingActiveMqTwo',
      component: () => import('../pages/meeting/meetingmodule/activemq/MeetingActiveMqTwo'),
    },
    {
      path: '/meetingRocketMqOne',
      component: () => import('../pages/meeting/meetingmodule/rocketmq/MeetingRocketMqOne'),
    },
    {
      path: '/meetingRocketMqTwo',
      component: () => import('../pages/meeting/meetingmodule/rocketmq/MeetingRocketMqTwo'),
    },
    {
      path: '/meetingRocketMqThree',
      component: () => import('../pages/meeting/meetingmodule/rocketmq/MeetingRocketMqThree'),
    },
    {
      path: '/meetingZookeeperOne',
      component: () => import('../pages/meeting/meetingmodule/zookeeper/MeetingZookeeperOne'),
    },
    {
      path: '/meetingZookeeperTwo',
      component: () => import('../pages/meeting/meetingmodule/zookeeper/MeetingZookeeperTwo'),
    },
    {
      path: '/meetingZookeeperThree',
      component: () => import('../pages/meeting/meetingmodule/zookeeper/MeetingZookeeperThree'),
    },
    {
      path: '/meetingZookeeperFour',
      component: () => import('../pages/meeting/meetingmodule/zookeeper/MeetingZookeeperFour'),
    },
    {
      path: '/meetingNginxOne',
      component: () => import('../pages/meeting/meetingmodule/nginx/MeetingNginxOne'),
    },
    {
      path: '/meetingNginxTwo',
      component: () => import('../pages/meeting/meetingmodule/nginx/MeetingNginxTwo'),
    },
    {
      path: '/meetingTomcatOne',
      component: () => import('../pages/meeting/meetingmodule/nginx/MeetingTomcatOne'),
    },
    {
      path: '/meetingTomcatTwo',
      component: () => import('../pages/meeting/meetingmodule/nginx/MeetingTomcatTwo'),
    },
    {
      path: '/meetingMavenOne',
      component: () => import('../pages/meeting/meetingmodule/nginx/MeetingMavenOne'),
    },
    {
      path: '/meetingMavenTwo',
      component: () => import('../pages/meeting/meetingmodule/nginx/MeetingMavenTwo'),
    },
    {
      path: '/meetingNettyOne',
      component: () => import('../pages/meeting/meetingjava/netty/MeetingNettyOne'),
    },
    {
      path: '/meetingNettyTwo',
      component: () => import('../pages/meeting/meetingjava/netty/MeetingNettyTwo'),
    },
    {
      path: '/meetingNettyThree',
      component: () => import('../pages/meeting/meetingjava/netty/MeetingNettyThree'),
    },
    {
      path: '/meetingPatternsOne',
      component: () => import('../pages/meeting/meetingjava/patterns/MeetingPatternsOne'),
    },
    {
      path: '/meetingPatternsTwo',
      component: () => import('../pages/meeting/meetingjava/patterns/MeetingPatternsTwo'),
    },
    {
      path: '/meetingPatternsThree',
      component: () => import('../pages/meeting/meetingjava/patterns/MeetingPatternsThree'),
    },
    {
      path: '/meetingJavaBaseOne',
      component: () => import('../pages/meeting/meetingjava/javabase/MeetingJavaBaseOne'),
    },
    {
      path: '/meetingJavaBaseTwo',
      component: () => import('../pages/meeting/meetingjava/javabase/MeetingJavaBaseTwo'),
    },
    {
      path: '/meetingJavaBaseThree',
      component: () => import('../pages/meeting/meetingjava/javabase/MeetingJavaBaseThree'),
    },
    {
      path: '/meetingJavaBaseFour',
      component: () => import('../pages/meeting/meetingjava/javabase/MeetingJavaBaseFour'),
    },
    {
      path: '/meetingJavaBaseFive',
      component: () => import('../pages/meeting/meetingjava/javabase/MeetingJavaBaseFive'),
    },
    {
      path: '/meetingJavaBaseSix',
      component: () => import('../pages/meeting/meetingjava/javabase/MeetingJavaBaseSix'),
    },
    {
      path: '/toolsIdea',
      component: () => import('../pages/tools/idea/Idea'),
    },
    {
      path: '/toolsActivationCode',
      component: () => import('../pages/tools/idea/ActivationCode'),
    },
    {
      path: '/toolsDataGrip',
      component: () => import('../pages/tools/idea/DataGrip'),
    },
    {
      path: '/toolsAppCode',
      component: () => import('../pages/tools/idea/AppCode'),
    },
    {
      path: '/toolsClion',
      component: () => import('../pages/tools/idea/Clion'),
    },
    {
      path: '/toolsPhpStorm',
      component: () => import('../pages/tools/idea/PhpStorm'),
    },
    {
      path: '/toolsPyCharm',
      component: () => import('../pages/tools/idea/PyCharm'),
    },
    {
      path: '/toolsRubyMine',
      component: () => import('../pages/tools/idea/RubyMine'),
    },
    {
      path: '/toolsWebStorm',
      component: () => import('../pages/tools/idea/WebStorm'),
    },
    {
      path: '/toolsNavicat',
      component: () => import('../pages/tools/idea/Navicat'),
    },
    {
      path: '/hadoopIntroduce',
      component: () => import('../pages/bigdata/hadoop/hadoopbase/HadoopIntroduce'),
    },
    {
      path: '/hadoopInstall',
      component: () => import('../pages/bigdata/hadoop/hadoopbase/HadoopInstall'),
    },
    {
      path: '/hadoopVersion',
      component: () => import('../pages/bigdata/hadoop/hadoopbase/HadoopVersion'),
    },
    {
      path: '/hadoopHDFS',
      component: () => import('../pages/bigdata/hadoop/hadoopbase/HadoopHDFS'),
    },
    {
      path: '/hadoopMapReduce',
      component: () => import('../pages/bigdata/hadoop/hadoopbase/HadoopMapReduce'),
    },
    {
      path: '/hadoopYarn',
      component: () => import('../pages/bigdata/hadoop/hadoopbase/HadoopYarn'),
    },
    {
      path: '/hadoopOozie',
      component: () => import('../pages/bigdata/hadoop/hadoopbase/HadoopOozie'),
    },
    {
      path: '/hadoopZooKeeper',
      component: () => import('../pages/bigdata/hadoop/hadoopbase/HadoopZooKeeper'),
    },
    {
      path: '/hadoopSqoop',
      component: () => import('../pages/bigdata/hadoop/hadoopbase/HadoopSqoop'),
    },
    {
      path: '/hadoopConfigure',
      component: () => import('../pages/bigdata/hadoop/hadoopbase/HadoopConfigure'),
    },
    {
      path: '/hadoopHDFShell',
      component: () => import('../pages/bigdata/hadoop/hadoopbase/HadoopHDFShell'),
    },
    {
      path: '/hadoopBeanWritable',
      component: () => import('../pages/bigdata/hadoop/hadoopbase/HadoopBeanWritable'),
    },
    {
      path: '/hadoopPartitioner',
      component: () => import('../pages/bigdata/hadoop/hadoopbase/HadoopPartitioner'),
    },
    {
      path: '/hadoopMapReducer',
      component: () => import('../pages/bigdata/hadoop/hadoopbase/HadoopMapReducer'),
    },
    {
      path: '/hadoopWritableComparable',
      component: () => import('../pages/bigdata/hadoop/hadoopbase/HadoopWritableComparable'),
    },
    {
      path: '/hadoopCombiner',
      component: () => import('../pages/bigdata/hadoop/hadoopbase/HadoopCombiner'),
    },
    {
      path: '/hadoopOutputFormat',
      component: () => import('../pages/bigdata/hadoop/hadoopbase/HadoopOutputFormat'),
    },
    {
      path: '/hadoopDistributedCache',
      component: () => import('../pages/bigdata/hadoop/hadoopbase/HadoopDistributedCache'),
    },
    {
      path: '/hadoopMultiQueue',
      component: () => import('../pages/bigdata/hadoop/hadoopbase/HadoopMultiQueue'),
    },
    {
      path: '/hadoopMultiQueueScheduler',
      component: () => import('../pages/bigdata/hadoop/hadoopbase/HadoopMultiQueueScheduler'),
    },
    {
      path: '/hadoopDataBalancing',
      component: () => import('../pages/bigdata/hadoop/hadoopbase/HadoopDataBalancing'),
    },
    {
      path: '/hadoopHDFSWhiteBlack',
      component: () => import('../pages/bigdata/hadoop/hadoopbase/HadoopHDFSWhiteBlack'),
    },
    {
      path: '/hadoopHDFSReadWrite',
      component: () => import('../pages/bigdata/hadoop/hadoopbase/HadoopHDFSReadWrite'),
    },
    {
      path: '/hadoopYarnJobProcess',
      component: () => import('../pages/bigdata/hadoop/hadoopbase/HadoopYarnJobProcess'),
    },
    
    
    
    {
      path: '/hadoopClusterInstall',
      component: () => import('../pages/bigdata/hadoop/hadoopinstall/HadoopClusterInstall'),
    },
    {
      path: '/hadoopClusterZooKeeper',
      component: () => import('../pages/bigdata/hadoop/hadoopinstall/HadoopClusterZooKeeper'),
    },
    {
      path: '/hadoopClusterHive',
      component: () => import('../pages/bigdata/hadoop/hadoopinstall/HadoopClusterHive'),
    },
    {
      path: '/hadoopClusterSpark',
      component: () => import('../pages/bigdata/hadoop/hadoopinstall/HadoopClusterSpark'),
    },
    {
      path: '/hadoopClusterFlink',
      component: () => import('../pages/bigdata/hadoop/hadoopinstall/HadoopClusterFlink'),
    },
    {
      path: '/hadoopClusterScala',
      component: () => import('../pages/bigdata/hadoop/hadoopinstall/HadoopClusterScala'),
    },
    {
      path: '/hadoopClusterHudi',
      component: () => import('../pages/bigdata/hadoop/hadoopinstall/HadoopClusterHudi'),
    },
    {
      path: '/hadoopClusterShell',
      component: () => import('../pages/bigdata/hadoop/hadoopinstall/HadoopClusterShell'),
    },
    {
      path: '/hadoopClusterPyspark',
      component: () => import('../pages/bigdata/hadoop/hadoopinstall/HadoopClusterPyspark'),
    },
    {
      path: '/hadoopClusterHiveonSpark',
      component: () => import('../pages/bigdata/hadoop/hadoopinstall/HadoopClusterHiveonSpark'),
    },
    {
      path: '/hadoopClusterHiveMetastore',
      component: () => import('../pages/bigdata/hadoop/hadoopinstall/HadoopClusterHiveMetastore'),
    },
    {
      path: '/hadoopClusterFeatures',
      component: () => import('../pages/bigdata/hadoop/hadoopinstall/HadoopClusterFeatures'),
    },
    {
      path: '/hadoopCombatJobHistory',
      component: () => import('../pages/bigdata/hadoop/hadoopcombat/HadoopCombatJobHistory'),
    },
    {
      path: '/hadoopCombatLoginHive',
      component: () => import('../pages/bigdata/hadoop/hadoopcombat/HadoopCombatLoginHive'),
    },
    {
      path: '/hadoopCombatDatanode',
      component: () => import('../pages/bigdata/hadoop/hadoopcombat/HadoopCombatDatanode'),
    },
    {
      path: '/hadoopCombatYarnSession',
      component: () => import('../pages/bigdata/hadoop/hadoopcombat/HadoopCombatYarnSession'),
    },
    {
      path: '/hadoopCombatFlinkSql',
      component: () => import('../pages/bigdata/hadoop/hadoopcombat/HadoopCombatFlinkSql'),
    },
    {
      path: '/hadoopCombatLog',
      component: () => import('../pages/bigdata/hadoop/hadoopcombat/HadoopCombatLog'),
    },
    {
      path: '/hadoopOptimize',
      component: () => import('../pages/bigdata/hadoop/hadoopcombat/HadoopOptimize'),
    },   
    {
      path: '/hadoopHDFSCoreParamsOptimize',
      component: () => import('../pages/bigdata/hadoop/hadoopcombat/HadoopHDFSCoreParamsOptimize'),
    }, 
    {
      path: '/hadoopMapReduceOptimize',
      component: () => import('../pages/bigdata/hadoop/hadoopcombat/HadoopMapReduceOptimize'),
    }, 
    {
      path: '/hadoopHDFSPressure',
      component: () => import('../pages/bigdata/hadoop/hadoopcombat/HadoopHDFSPressure'),
    },  
    {
      path: '/hadoopYarnOptimize',
      component: () => import('../pages/bigdata/hadoop/hadoopcombat/HadoopYarnOptimize'),
    }, 
    

    {
      path: '/hiveIntroduce',
      component: () => import('../pages/bigdata/hive/hivebase/HiveIntroduce'),
    },
    {
      path: '/hiveDataType',
      component: () => import('../pages/bigdata/hive/hivebase/HiveDataType'),
    },
    {
      path: '/hiveDataDefine',
      component: () => import('../pages/bigdata/hive/hivebase/HiveDataDefine'),
    },
    {
      path: '/hiveDataOper',
      component: () => import('../pages/bigdata/hive/hivebase/HiveDataOper'),
    },
    {
      path: '/hiveDataQuery',
      component: () => import('../pages/bigdata/hive/hivebase/HiveDataQuery'),
    },
    {
      path: '/hiveFunction',
      component: () => import('../pages/bigdata/hive/hivebase/HiveFunction'),
    },
    {
      path: '/hiveTempTable',
      component: () => import('../pages/bigdata/hive/hivebase/HiveTempTable'),
    },
    {
      path: '/hiveView',
      component: () => import('../pages/bigdata/hive/hivebase/HiveView'),
    },
    {
      path: '/hiveMacro',
      component: () => import('../pages/bigdata/hive/hivebase/HiveMacro'),
    },
    {
      path: '/hiveWith',
      component: () => import('../pages/bigdata/hive/hivebase/HiveWith'),
    },
    {
      path: '/hiveAnalyticFunction',
      component: () => import('../pages/bigdata/hive/hivebase/HiveAnalyticFunction'),
    },
    {
      path: '/hiveExpr',
      component: () => import('../pages/bigdata/hive/hivebase/HiveExpr'),
    },
    {
      path: '/hiveIndex',
      component: () => import('../pages/bigdata/hive/hivebase/HiveIndex'),
    },
    {
      path: '/hiveArchiving',
      component: () => import('../pages/bigdata/hive/hivebase/HiveArchiving'),
    },
    {
      path: '/hiveHplSql',
      component: () => import('../pages/bigdata/hive/hivebase/HiveHplSql'),
    },
    {
      path: '/hiveAffaire',
      component: () => import('../pages/bigdata/hive/hivebase/HiveAffaire'),
    },
    {
      path: '/hiveLock',
      component: () => import('../pages/bigdata/hive/hivebase/HiveLock'),
    },
    {
      path: '/hiveRecycling',
      component: () => import('../pages/bigdata/hive/hivebase/HiveRecycling'),
    },
    {
      path: '/hivePartition',
      component: () => import('../pages/bigdata/hive/hivebase/HivePartition'),
    },
    {
      path: '/hiveHighFeatures',
      component: () => import('../pages/bigdata/hive/hivebase/HiveHighFeatures'),
    },
    {
      path: '/hivePrinciple',
      component: () => import('../pages/bigdata/hive/hivebase/HivePrinciple'),
    },
    {
      path: '/hiveOptimizationOverview',
      component: () => import('../pages/bigdata/hive/hivecode/HiveOptimizationOverview'),
    },
    {
      path: '/hiveExplain',
      component: () => import('../pages/bigdata/hive/hivecode/HiveExplain'),
    },
    {
      path: '/hiveStatistic',
      component: () => import('../pages/bigdata/hive/hivecode/HiveStatistic'),
    },
    {
      path: '/hiveSampling',
      component: () => import('../pages/bigdata/hive/hivecode/HiveSampling'),
    },
    {
      path: '/hiveORC',
      component: () => import('../pages/bigdata/hive/hivecode/HiveORC'),
    },
    {
      path: '/hivePress',
      component: () => import('../pages/bigdata/hive/hivecode/HivePress'),
    },
    {
      path: '/hiveSpark',
      component: () => import('../pages/bigdata/hive/hivecode/HiveSpark'),
    },
    {
      path: '/hiveJob',
      component: () => import('../pages/bigdata/hive/hivecode/HiveJob'),
    },
    {
      path: '/hiveSqoopHive',
      component: () => import('../pages/bigdata/hive/hivecode/HiveSqoopHive'),
    },
    {
      path: '/hiveMap',
      component: () => import('../pages/bigdata/hive/hivecode/HiveMap'),
    },
    {
      path: '/hiveCountDistinct',
      component: () => import('../pages/bigdata/hive/hivecode/HiveCountDistinct'),
    },
    {
      path: '/hiveShuffle',
      component: () => import('../pages/bigdata/hive/hivecode/HiveShuffle'),
    },
    {
      path: '/hiveCartesian',
      component: () => import('../pages/bigdata/hive/hivecode/HiveCartesian'),
    },
    {
      path: '/hiveXML',
      component: () => import('../pages/bigdata/hive/hivecode/HiveXML'),
    },
    {
      path: '/hiveBigTableCartesian',
      component: () => import('../pages/bigdata/hive/hivecode/HiveBigTableCartesian'),
    },
    {
      path: '/hiveResourceQueue',
      component: () => import('../pages/bigdata/hive/hivecode/HiveResourceQueue'),
    },
    {
      path: '/hiveHDFSRebalance',
      component: () => import('../pages/bigdata/hive/hivecode/HiveHDFSRebalance'),
    },
    {
      path: '/hiveNormalParams',
      component: () => import('../pages/bigdata/hive/hivecode/HiveNormalParams'),
    },
    {
      path: '/hiveHDFS',
      component: () => import('../pages/bigdata/hive/hivecode/HiveHDFS'),
    },
    
    





    {
      path: '/sparkbaseIntroduce',
      component: () => import('../pages/bigdata/spark/sparkbase/SparkIntroduce'),
    },
    {
      path: '/sparkbaseSql',
      component: () => import('../pages/bigdata/spark/sparkbase/SparkbaseSql'),
    },
    {
      path: '/sparkbaseStreaming',
      component: () => import('../pages/bigdata/spark/sparkbase/SparkbaseStreaming'),
    },

    {
      path: '/sparkbaseInstall',
      component: () => import('../pages/bigdata/spark/sparkbase/SparkbaseInstall'),
    },
    {
      path: '/sparkbaseRDD',
      component: () => import('../pages/bigdata/spark/sparkbase/SparkbaseRDD'),
    },
    {
      path: '/sparkbaseDevOps',
      component: () => import('../pages/bigdata/spark/sparkbase/SparkbaseDevOps'),
    },
    {
      path: '/sparkbasOptimize',
      component: () => import('../pages/bigdata/spark/sparkbase/SparkbasOptimize'),
    },
    {
      path: '/sparkbaseSqlNormalParas',
      component: () => import('../pages/bigdata/spark/sparkbase/SparkbaseSqlNormalParas'),
    },
    {
      path: '/sparkbaseSqlWays',
      component: () => import('../pages/bigdata/spark/sparkbase/SparkbaseSqlWays'),
    },




    {
      path: '/sparkcombatDataframe',
      component: () => import('../pages/bigdata/spark/sparkcombat/SparkcombatDataframe'),
    },
    {
      path: '/sparkcombatMysqlHive',
      component: () => import('../pages/bigdata/spark/sparkcombat/SparkcombatMysqlHive'),
    },
    {
      path: '/sparkcombatHiveMysql',
      component: () => import('../pages/bigdata/spark/sparkcombat/SparkcombatHiveMysql'),
    },
    {
      path: '/sparkcombatDataframePivot',
      component: () => import('../pages/bigdata/spark/sparkcombat/SparkcombatDataframePivot'),
    },
    {
      path: '/sparkcombatDataframeUnion',
      component: () => import('../pages/bigdata/spark/sparkcombat/SparkcombatDataframeUnion'),
    },
    {
      path: '/sparkcombatFriends',
      component: () => import('../pages/bigdata/spark/sparkcombat/SparkcombatFriends'),
    },
    
    

    

    
    {
      path: '/flinkbaseIntroduce',
      component: () => import('../pages/bigdata/flink/flinkbase/FlinkbaseIntroduce'),
    },
    {
      path: '/flinkbaseInstall',
      component: () => import('../pages/bigdata/flink/flinkbase/FlinkbaseInstall'),
    },
    {
      path: '/flinkbaseWindowsInstall',
      component: () => import('../pages/bigdata/flink/flinkbase/FlinkbaseWindowsInstall'),
    },
    {
      path: '/flinkbaseCDH',
      component: () => import('../pages/bigdata/flink/flinkbase/FlinkbaseCDH'),
    },
    {
      path: '/flinkbaseConfigParams',
      component: () => import('../pages/bigdata/flink/flinkbase/FlinkbaseConfigParams'),
    },
    {
      path: '/flinkbaseRunParmas',
      component: () => import('../pages/bigdata/flink/flinkbase/FlinkbaseRunParmas'),
    },
    {
      path: '/flinkbasWebUIJar',
      component: () => import('../pages/bigdata/flink/flinkbase/FlinkbasWebUIJar'),
    },
    {
      path: '/flinkbaseWordCount',
      component: () => import('../pages/bigdata/flink/flinkbase/FlinkbaseWordCount'),
    },
    {
      path: '/flinkbaseArchitecture',
      component: () => import('../pages/bigdata/flink/flinkbase/FlinkbaseArchitecture'),
    },
    {
      path: '/flinkbaseEnvironment',
      component: () => import('../pages/bigdata/flink/flinkbase/FlinkbaseEnvironment'),
    },
    {
      path: '/flinkbaseSource',
      component: () => import('../pages/bigdata/flink/flinkbase/FlinkbaseSource'),
    },
    {
      path: '/flinkbaseSourceFiles',
      component: () => import('../pages/bigdata/flink/flinkbase/FlinkbaseSourceFiles'),
    },
    {
      path: '/flinkbaseSourceKafka',
      component: () => import('../pages/bigdata/flink/flinkbase/FlinkbaseSourceKafka'),
    },
    {
      path: '/flinkbaseSourceSource',
      component: () => import('../pages/bigdata/flink/flinkbase/FlinkbaseSourceSource'),
    },
    {
      path: '/flinkbaseTranform',
      component: () => import('../pages/bigdata/flink/flinkbase/FlinkbaseTranform'),
    },
    {
      path: '/flinkbaseTranformOperator',
      component: () => import('../pages/bigdata/flink/flinkbase/FlinkbaseTranformOperator'),
    },
    {
      path: '/flinkbaseTranformMultiStream',
      component: () => import('../pages/bigdata/flink/flinkbase/FlinkbaseTranformMultiStream'),
    },
    {
      path: '/flinkbaseDataType',
      component: () => import('../pages/bigdata/flink/flinkbase/FlinkbaseDataType'),
    },
    {
      path: '/flinkbaseUDF',
      component: () => import('../pages/bigdata/flink/flinkbase/FlinkbaseUDF'),
    },
    {
      path: '/flinkbaseDataPartition',
      component: () => import('../pages/bigdata/flink/flinkbase/FlinkbaseDataPartition'),
    },
    {
      path: '/flinkbaseSinkKafka',
      component: () => import('../pages/bigdata/flink/flinkbase/FlinkbaseSinkKafka'),
    },
    {
      path: '/flinkbaseSinkRedis',
      component: () => import('../pages/bigdata/flink/flinkbase/FlinkbaseSinkRedis'),
    },
    {
      path: '/flinkbaseSinkJDBC',
      component: () => import('../pages/bigdata/flink/flinkbase/FlinkbaseSinkJDBC'),
    },
    {
      path: '/flinkbaseWindow',
      component: () => import('../pages/bigdata/flink/flinkbase/FlinkbaseWindow'),
    },
    {
      path: '/flinkbaseWatermark',
      component: () => import('../pages/bigdata/flink/flinkbase/FlinkbaseWatermark'),
    },
    {
      path: '/flinkbaseStatus',
      component: () => import('../pages/bigdata/flink/flinkbase/FlinkbaseStatus'),
    },
    {
      path: '/flinkbaseProcessFunction',
      component: () => import('../pages/bigdata/flink/flinkbase/FlinkbaseProcessFunction'),
    },
    {
      path: '/flinkbaseFault',
      component: () => import('../pages/bigdata/flink/flinkbase/FlinkbaseFault'),
    },
    {
      path: '/flinkbaseTableAPISql',
      component: () => import('../pages/bigdata/flink/flinkbase/FlinkbaseTableAPISql'),
    },
    {
      path: '/flinkbaseTableAPISqlStream',
      component: () => import('../pages/bigdata/flink/flinkbase/FlinkbaseTableAPISqlStream'),
    },
    {
      path: '/flinkbaseTableAPISqlWindows',
      component: () => import('../pages/bigdata/flink/flinkbase/FlinkbaseTableAPISqlWindows'),
    },
    {
      path: '/flinkbaseTableAPISqlFunction',
      component: () => import('../pages/bigdata/flink/flinkbase/FlinkbaseTableAPISqlFunction'),
    },
    {
      path: '/flinkbaseCEP',
      component: () => import('../pages/bigdata/flink/flinkbase/FlinkbaseCEP'),
    },
    {
      path: '/flinkbaseCDC',
      component: () => import('../pages/bigdata/flink/flinkbase/FlinkbaseCDC'),
    },
    {
      path: '/flinkcombatIntroduce',
      component: () => import('../pages/bigdata/flink/flinkcombat/FlinkcombatIntroduce'),
    },
    {
      path: '/flinkcombatHotStatic',
      component: () => import('../pages/bigdata/flink/flinkcombat/FlinkcombatHotStatic'),
    },
    {
      path: '/flinkcombatStreamStatic',
      component: () => import('../pages/bigdata/flink/flinkcombat/FlinkcombatStreamStatic'),
    },
    {
      path: '/flinkcombatMarketStatic',
      component: () => import('../pages/bigdata/flink/flinkcombat/FlinkcombatMarketStatic'),
    },
    {
      path: '/flinkcombatLoginMonitor',
      component: () => import('../pages/bigdata/flink/flinkcombat/FlinkcombatLoginMonitor'),
    },
    {
      path: '/flinkcombatOrderPayMonitor',
      component: () => import('../pages/bigdata/flink/flinkcombat/FlinkcombatOrderPayMonitor'),
    },
    
    {
      path: '/supersetIntroduce',
      component: () => import('../pages/bigdata/datastore/superset/SupersetIntroduce'),
    },
    {
      path: '/supersetInstall',
      component: () => import('../pages/bigdata/datastore/superset/SupersetInstall'),
    },
    {
      path: '/supersetTestData',
      component: () => import('../pages/bigdata/datastore/superset/SupersetTestData'),
    },
    {
      path: '/supersetMysql',
      component: () => import('../pages/bigdata/datastore/superset/SupersetMysql'),
    },
    {
      path: '/supersetDashboard',
      component: () => import('../pages/bigdata/datastore/superset/SupersetDashboard'),
    },
    {
      path: '/supersetLineChart',
      component: () => import('../pages/bigdata/datastore/superset/SupersetLineChart'),
    },
    {
      path: '/supersetHistogram',
      component: () => import('../pages/bigdata/datastore/superset/SupersetHistogram'),
    },
    {
      path: '/supersetPieChart',
      component: () => import('../pages/bigdata/datastore/superset/SupersetPieChart'),
    },
    {
      path: '/supersetMap',
      component: () => import('../pages/bigdata/datastore/superset/SupersetMap'),
    },
    {
      path: '/supersetDashboardLayout',
      component: () => import('../pages/bigdata/datastore/superset/SupersetDashboardLayout'),
    },
    {
      path: '/storeIntroduce',
      component: () => import('../pages/bigdata/datastore/store/StoreIntroduce'),
    },
    {
      path: '/storeModel',
      component: () => import('../pages/bigdata/datastore/store/StoreModel'),
    },
    {
      path: '/storeFactTable',
      component: () => import('../pages/bigdata/datastore/store/StoreFactTable'),
    },
    {
      path: '/storeDimension',
      component: () => import('../pages/bigdata/datastore/store/StoreDimension'),
    },
    {
      path: '/storeKimbalDW',
      component: () => import('../pages/bigdata/datastore/store/StoreKimbalDW'),
    },
    {
      path: '/storeDimensionModel',
      component: () => import('../pages/bigdata/datastore/store/StoreDimensionModel'),
    },
    {
      path: '/storeETLTech',
      component: () => import('../pages/bigdata/datastore/store/StoreETLTech'),
    },
    {
      path: '/storeETLProcess',
      component: () => import('../pages/bigdata/datastore/store/StoreETLProcess'),
    },
    {
      path: '/storeAnalysis',
      component: () => import('../pages/bigdata/datastore/store/StoreAnalysis'),
    },
    {
      path: '/storeDataWarehouse',
      component: () => import('../pages/bigdata/datastore/store/StoreDataWarehouse'),
    },
    
    

    {
      path: '/dataxIntroduce',
      component: () => import('../pages/bigdata/datastore/datax/DataxIntroduce'),
    },
    {
      path: '/dataxInstall',
      component: () => import('../pages/bigdata/datastore/datax/DataxInstall'),
    },
    {
      path: '/dataxTxtFileReader',
      component: () => import('../pages/bigdata/datastore/datax/DataxTxtFileReader'),
    },
    {
      path: '/dataxTxtFileWriter',
      component: () => import('../pages/bigdata/datastore/datax/DataxTxtFileWriter'),
    },
    {
      path: '/dataxMysqlReader',
      component: () => import('../pages/bigdata/datastore/datax/DataxMysqlReader'),
    },
    {
      path: '/dataxMysqlWriter',
      component: () => import('../pages/bigdata/datastore/datax/DataxMysqlWriter'),
    },
    {
      path: '/dataxHdfsReader',
      component: () => import('../pages/bigdata/datastore/datax/DataxHdfsReader'),
    },
    {
      path: '/dataxHdfsWriter',
      component: () => import('../pages/bigdata/datastore/datax/DataxHdfsWriter'),
    },
    {
      path: '/dataxSyncCase',
      component: () => import('../pages/bigdata/datastore/datax/DataxSyncCase'),
    },
    {
      path: '/dataxOptimize',
      component: () => import('../pages/bigdata/datastore/datax/DataxOptimize'),
    },
    
    
    
    {
      path: '/flumebaseOverview',
      component: () => import('../pages/bigdata/flume/flumebase/FlumebaseOverview'),
    },
    {
      path: '/flumebaseIntroduce',
      component: () => import('../pages/bigdata/flume/flumebase/FlumebaseIntroduce'),
    },
    {
      path: '/flumebaseAffair',
      component: () => import('../pages/bigdata/flume/flumebase/FlumebaseAffair'),
    },
    {
      path: '/flumebaseTopology',
      component: () => import('../pages/bigdata/flume/flumebase/FlumebaseTopology'),
    },
    {
      path: '/flumebaseInterceptor',
      component: () => import('../pages/bigdata/flume/flumebase/FlumebaseInterceptor'),
    },
    {
      path: '/flumebaseKafka',
      component: () => import('../pages/bigdata/flume/flumebase/FlumebaseKafka'),
    },
    {
      path: '/dorisIntroduce',
      component: () => import('../pages/bigdata/datastore/doris/DorisIntroduce'),
    },
    {
      path: '/dorisInstall',
      component: () => import('../pages/bigdata/datastore/doris/DorisInstall'),
    },
    {
      path: '/dorisUsageGuide',
      component: () => import('../pages/bigdata/datastore/doris/DorisUsageGuide'),
    },
    {
      path: '/dorisHiveSpark',
      component: () => import('../pages/bigdata/datastore/doris/DorisHiveSpark'),
    },
    {
      path: '/dorisDataModel',
      component: () => import('../pages/bigdata/datastore/doris/DorisDataModel'),
    },
    {
      path: '/dorisRollup',
      component: () => import('../pages/bigdata/datastore/doris/DorisRollup'),
    },
    {
      path: '/dorisPreIndex',
      component: () => import('../pages/bigdata/datastore/doris/DorisPreIndex'),
    },
    {
      path: '/dorisDataPartitioning',
      component: () => import('../pages/bigdata/datastore/doris/DorisDataPartitioning'),
    },
    {
      path: '/dorisAlterStructure',
      component: () => import('../pages/bigdata/datastore/doris/DorisAlterStructure'),
    },
    {
      path: '/dorisInsertInto',
      component: () => import('../pages/bigdata/datastore/doris/DorisInsertInto'),
    },
    {
      path: '/dorisStreamLoad',
      component: () => import('../pages/bigdata/datastore/doris/DorisStreamLoad'),
    },
    {
      path: '/dorisBrokerLoad',
      component: () => import('../pages/bigdata/datastore/doris/DorisBrokerLoad'),
    },
    {
      path: '/dorisRoutineLoad',
      component: () => import('../pages/bigdata/datastore/doris/DorisRoutineLoad'),
    },
    {
      path: '/dorisBatchDelete',
      component: () => import('../pages/bigdata/datastore/doris/DorisBatchDelete'),
    },
    {
      path: '/dorisIndex',
      component: () => import('../pages/bigdata/datastore/doris/DorisIndex'),
    },
    {
      path: '/dorisMaterializeView',
      component: () => import('../pages/bigdata/datastore/doris/DorisMaterializeView'),
    },
    {
      path: '/dorisDynamicPartion',
      component: () => import('../pages/bigdata/datastore/doris/DorisDynamicPartion'),
    },
    {
      path: '/dorisDynamicPartion',
      component: () => import('../pages/bigdata/datastore/doris/DorisDataExport'),
    },
    {
      path: '/dorisDataExport',
      component: () => import('../pages/bigdata/datastore/doris/DorisDataExport'),
    },
    {
      path: '/dorisQueryResults',
      component: () => import('../pages/bigdata/datastore/doris/DorisQueryResults'),
    },
    {
      path: '/dorisPartitionCache',
      component: () => import('../pages/bigdata/datastore/doris/DorisPartitionCache'),
    },
    
    
    
    {
      path: '/kylinIntroduce',
      component: () => import('../pages/bigdata/datastore/kylin/KylinIntroduce'),
    },
    {
      path: '/kylinInstall',
      component: () => import('../pages/bigdata/datastore/kylin/KylinInstall'),
    },
    {
      path: '/kylinQuickStart',
      component: () => import('../pages/bigdata/datastore/kylin/KylinQuickStart'),
    },
    {
      path: '/kylinMysqlScott',
      component: () => import('../pages/bigdata/datastore/kylin/KylinMysqlScott'),
    },
    {
      path: '/kylinUsesNote',
      component: () => import('../pages/bigdata/datastore/kylin/KylinUsesNote'),
    },
    {
      path: '/kylinBuildCube',
      component: () => import('../pages/bigdata/datastore/kylin/KylinBuildCube'),
    },
    {
      path: '/kylinCubePrinciple',
      component: () => import('../pages/bigdata/datastore/kylin/KylinCubePrinciple'),
    },
    {
      path: '/kylinCubeBuildOptimize',
      component: () => import('../pages/bigdata/datastore/kylin/KylinCubeBuildOptimize'),
    },
    {
      path: '/kylinBI',
      component: () => import('../pages/bigdata/datastore/kylin/KylinBI'),
    },
    
    {
      path: '/hudiIntroduce',
      component: () => import('../pages/bigdata/datastore/hudi/HudiIntroduce'),
    },
    {
      path: '/hudiInstall',
      component: () => import('../pages/bigdata/datastore/hudi/HudiInstall'),
    },
    {
      path: '/hudiCoreConcepts',
      component: () => import('../pages/bigdata/datastore/hudi/HudiCoreConcepts'),
    },
    {
      path: '/hudiDataWriter',
      component: () => import('../pages/bigdata/datastore/hudi/HudiDataWriter'),
    },
    {
      path: '/hudiSpark',
      component: () => import('../pages/bigdata/datastore/hudi/HudiSpark'),
    },
    {
      path: '/hudiPyspark',
      component: () => import('../pages/bigdata/datastore/hudi/HudiPyspark'),
    },
    {
      path: '/hudiSparkSQL',
      component: () => import('../pages/bigdata/datastore/hudi/HudiSparkSQL'),
    },
    {
      path: '/hudiFlink',
      component: () => import('../pages/bigdata/datastore/hudi/HudiFlink'),
    },
    {
      path: '/hudiFlinkSQL',
      component: () => import('../pages/bigdata/datastore/hudi/HudiFlinkSQL'),
    },
    {
      path: '/hudiFlinkStream',
      component: () => import('../pages/bigdata/datastore/hudi/HudiFlinkStream'),
    },
    {
      path: '/hudiMySQL',
      component: () => import('../pages/bigdata/datastore/hudi/HudiMySQL'),
    },
    {
      path: '/hudiFlinkMapping',
      component: () => import('../pages/bigdata/datastore/hudi/HudiFlinkMapping'),
    },
    {
      path: '/hudiHive',
      component: () => import('../pages/bigdata/datastore/hudi/HudiHive'),
    },
    {
      path: '/hudiDataPersistence',
      component: () => import('../pages/bigdata/datastore/hudi/HudiDataPersistence'),
    },
    {
      path: '/hudiDataHive',
      component: () => import('../pages/bigdata/datastore/hudi/HudiDataHive'),
    },
    {
      path: '/hudiCoreParams',
      component: () => import('../pages/bigdata/datastore/hudi/HudiCoreParams'),
    },
    {
      path: '/hudiBatchImport',
      component: () => import('../pages/bigdata/datastore/hudi/HudiBatchImport'),
    },
    {
      path: '/hudiWriterModel',
      component: () => import('../pages/bigdata/datastore/hudi/HudiWriterModel'),
    },
    {
      path: '/hudiBucketIndex',
      component: () => import('../pages/bigdata/datastore/hudi/HudiBucketIndex'),
    },
    {
      path: '/hudiCompaction',
      component: () => import('../pages/bigdata/datastore/hudi/HudiCompaction'),
    },
    {
      path: '/hudiClusting',
      component: () => import('../pages/bigdata/datastore/hudi/HudiClusting'),
    },
    {
      path: '/hudiNormalQuestion',
      component: () => import('../pages/bigdata/datastore/hudi/HudiNormalQuestion'),
    },
    {
      path: '/hudiCow',
      component: () => import('../pages/bigdata/datastore/hudi/HudiCow'),
    },
    {
      path: '/hudiCheckpoint',
      component: () => import('../pages/bigdata/datastore/hudi/HudiCheckpoint'),
    },
    {
      path: '/hudiSparkFlink',
      component: () => import('../pages/bigdata/datastore/hudi/HudiSparkFlink'),
    },
    
    


    

    {
      path: '/hbaseOverview',
      component: () => import('../pages/bigdata/hbase/hbase/HbaseOverview'),
    },
    {
      path: '/hbaseIntroduce',
      component: () => import('../pages/bigdata/hbase/hbase/HbaseIntroduce'),
    },
    {
      path: '/hbaseAdvance',
      component: () => import('../pages/bigdata/hbase/hbase/HbaseAdvance'),
    },
    {
      path: '/hbaseAPI',
      component: () => import('../pages/bigdata/hbase/hbase/HbaseAPI'),
    },
    {
      path: '/hbaseOptimize',
      component: () => import('../pages/bigdata/hbase/hbase/HbaseOptimize'),
    },
    
    
    {
      path: '/stormbaseOverview',
      component: () => import('../pages/bigdata/storm/stormbase/StormbaseOverview'),
    },
    {
      path: '/stormbaseInstall',
      component: () => import('../pages/bigdata/storm/stormbase/StormbaseInstall'),
    },
    {
      path: '/stormbaseCoreConcept',
      component: () => import('../pages/bigdata/storm/stormbase/StormbaseCoreConcept'),
    },
    {
      path: '/stormbaseStructurePrinciple',
      component: () => import('../pages/bigdata/storm/stormbase/StormbaseStructurePrinciple'),
    },
    {
      path: '/stormbaseAffair',
      component: () => import('../pages/bigdata/storm/stormbase/StormbaseAffair'),
    },
    {
      path: '/stormbaseKafka',
      component: () => import('../pages/bigdata/storm/stormbase/StormbaseKafka'),
    },
    {
      path: '/stormbaseDRPC',
      component: () => import('../pages/bigdata/storm/stormbase/StormbaseDRPC'),
    },
    {
      path: '/stormbaseFault',
      component: () => import('../pages/bigdata/storm/stormbase/StormbaseFault'),
    },
    {
      path: '/stormbaseConcurrency',
      component: () => import('../pages/bigdata/storm/stormbase/StormbaseConcurrency'),
    },
    {
      path: '/stormbaseGroupStrategy',
      component: () => import('../pages/bigdata/storm/stormbase/StormbaseGroupStrategy'),
    },
    {
      path: '/stormbaseTwitter',
      component: () => import('../pages/bigdata/storm/stormbase/StormbaseTwitter'),
    },
    {
      path: '/stormbaseYahoo',
      component: () => import('../pages/bigdata/storm/stormbase/StormbaseYahoo'),
    },
    {
      path: '/stormbaseApp',
      component: () => import('../pages/bigdata/storm/stormbase/StormbaseApp'),
    },
    
    {
      path: '/dolphinSchedulerBaseOverview',
      component: () => import('../pages/bigdata/dolphinScheduler/dolphinSchedulerbase/DolphinSchedulerBaseOverview'),
    },
    {
      path: '/dolphinSchedulerBaseInstall',
      component: () => import('../pages/bigdata/dolphinScheduler/dolphinSchedulerbase/DolphinSchedulerBaseInstall'),
    },
    {
      path: '/dolphinSchedulerBaseParams',
      component: () => import('../pages/bigdata/dolphinScheduler/dolphinSchedulerbase/DolphinSchedulerBaseParams'),
    },
    {
      path: '/dolphinSchedulerBaseResource',
      component: () => import('../pages/bigdata/dolphinScheduler/dolphinSchedulerbase/DolphinSchedulerBaseResource'),
    },
    {
      path: '/dolphinSchedulerBaseAlarm',
      component: () => import('../pages/bigdata/dolphinScheduler/dolphinSchedulerbase/DolphinSchedulerBaseAlarm'),
    },
    {
      path: '/dolphinSchedulerBaseJob',
      component: () => import('../pages/bigdata/dolphinScheduler/dolphinSchedulerbase/DolphinSchedulerBaseJob'),
    },
    
    
    
    {
      path: '/meetingBigDataRdd',
      component: () => import('../pages/meeting/meetingbigdata/hadoop/MeetingBigDataRdd'),
    },
    {
      path: '/meetingBigDataHivePartitonBucket',
      component: () => import('../pages/meeting/meetingbigdata/hadoop/MeetingBigDataHivePartitonBucket'),
    },
    {
      path: '/meetingBigDataStaticDynamicPartition',
      component: () => import('../pages/meeting/meetingbigdata/hadoop/MeetingBigDataStaticDynamicPartition'),
    },
    {
      path: '/meetingBigDataWindowFunction',
      component: () => import('../pages/meeting/meetingbigdata/hadoop/MeetingBigDataWindowFunction'),
    },
    {
      path: '/meetingBigDataHiveSparkSql',
      component: () => import('../pages/meeting/meetingbigdata/hadoop/MeetingBigDataHiveSparkSql'),
    },
    {
      path: '/meetingBigDataSparkProcess',
      component: () => import('../pages/meeting/meetingbigdata/hadoop/MeetingBigDataSparkProcess'),
    },
    {
      path: '/meetingBigDataMapReduceSpark',
      component: () => import('../pages/meeting/meetingbigdata/hadoop/MeetingBigDataMapReduceSpark'),
    },
    {
      path: '/meetingBigDataMapReduceSparkQuick',
      component: () => import('../pages/meeting/meetingbigdata/hadoop/MeetingBigDataMapReduceSparkQuick'),
    },
    {
      path: '/meetingBigDataCheckPoint',
      component: () => import('../pages/meeting/meetingbigdata/hadoop/MeetingBigDataCheckPoint'),
    },
    {
      path: '/meetingBigDataWideNarrow',
      component: () => import('../pages/meeting/meetingbigdata/hadoop/MeetingBigDataWideNarrow'),
    },
    {
      path: '/meetingBigDataShuffle',
      component: () => import('../pages/meeting/meetingbigdata/hadoop/MeetingBigDataShuffle'),
    },
    {
      path: '/meetingBigDataShuffleSpark',
      component: () => import('../pages/meeting/meetingbigdata/hadoop/MeetingBigDataShuffleSpark'),
    },
    {
      path: '/meetingBigDataIncrementMerge',
      component: () => import('../pages/meeting/meetingbigdata/hadoop/MeetingBigDataIncrementMerge'),
    },
    {
      path: '/meetingBigDataFlinkSparkStreaming',
      component: () => import('../pages/meeting/meetingbigdata/hadoop/MeetingBigDataFlinkSparkStreaming'),
    },
    {
      path: '/meetingBigDataStormSparkFlink',
      component: () => import('../pages/meeting/meetingbigdata/hadoop/MeetingBigDataStormSparkFlink'),
    },
    {
      path: '/meetingBigDataUdaf',
      component: () => import('../pages/meeting/meetingbigdata/hadoop/MeetingBigDataUdaf'),
    },
    {
      path: '/meetingBigDataSparkParams',
      component: () => import('../pages/meeting/meetingbigdata/hadoop/MeetingBigDataSparkParams'),
    },
    {
      path: '/meetingBigDataHiveOrderby',
      component: () => import('../pages/meeting/meetingbigdata/hadoop/MeetingBigDataHiveOrderby'),
    },
    {
      path: '/meetingBigDataHiveGroupby',
      component: () => import('../pages/meeting/meetingbigdata/hadoop/MeetingBigDataHiveGroupby'),
    },
    {
      path: '/meetingBigDataDataFrameRDD',
      component: () => import('../pages/meeting/meetingbigdata/hadoop/MeetingBigDataDataFrameRDD'),
    },
    {
      path: '/meetingBigDataFlinkPrinciple',
      component: () => import('../pages/meeting/meetingbigdata/hadoop/MeetingBigDataFlinkPrinciple'),
    },
    {
      path: '/meetingBigDataLayered',
      component: () => import('../pages/meeting/meetingbigdata/hadoop/MeetingBigDataLayered'),
    },
    {
      path: '/meetingBigDataModelling',
      component: () => import('../pages/meeting/meetingbigdata/hadoop/MeetingBigDataModelling'),
    },
    {
      path: '/meetingBigDataFeatures',
      component: () => import('../pages/meeting/meetingbigdata/hadoop/MeetingBigDataFeatures'),
    },
    {
      path: '/meetingBigDataFullTable',
      component: () => import('../pages/meeting/meetingbigdata/hadoop/MeetingBigDataFullTable'),
    },
    {
      path: '/meetingBigDataFactTable',
      component: () => import('../pages/meeting/meetingbigdata/hadoop/MeetingBigDataFactTable'),
    },
    {
      path: '/meetingBigDataSlope',
      component: () => import('../pages/meeting/meetingbigdata/hadoop/MeetingBigDataSlope'),
    },
    {
      path: '/meetingBigDataGlobalSort',
      component: () => import('../pages/meeting/meetingbigdata/hadoop/MeetingBigDataGlobalSort'),
    },
    {
      path: '/meetingBigDataFlatmap',
      component: () => import('../pages/meeting/meetingbigdata/hadoop/MeetingBigDataFlatmap'),
    },
    {
      path: '/meetingBigDataHiveJoin',
      component: () => import('../pages/meeting/meetingbigdata/hadoop/MeetingBigDataHiveJoin'),
    },
    {
      path: '/meetingBigDataMRMap',
      component: () => import('../pages/meeting/meetingbigdata/hadoop/MeetingBigDataMRMap'),
    },
    {
      path: '/meetingBigDataMRmapSort',
      component: () => import('../pages/meeting/meetingbigdata/hadoop/MeetingBigDataMRmapSort'),
    },
    {
      path: '/meetingBigDataTopK',
      component: () => import('../pages/meeting/meetingbigdata/hadoop/MeetingBigDataTopK'),
    },
    {
      path: '/meetingBigDataNameNode',
      component: () => import('../pages/meeting/meetingbigdata/hadoop/MeetingBigDataNameNode'),
    },
    {
      path: '/meetingBigDataYarnProcess',
      component: () => import('../pages/meeting/meetingbigdata/hadoop/MeetingBigDataYarnProcess'),
    },
    {
      path: '/meetingBigDataCombinerPartition',
      component: () => import('../pages/meeting/meetingbigdata/hadoop/MeetingBigDataCombinerPartition'),
    },
    {
      path: '/meetingBigDataMapreduceSlope',
      component: () => import('../pages/meeting/meetingbigdata/hadoop/MeetingBigDataMapreduceSlope'),
    },
    {
      path: '/meetingBigDataMapReduce',
      component: () => import('../pages/meeting/meetingbigdata/hadoop/MeetingBigDataMapReduce'),
    },
    {
      path: '/meetingBigDataMRProcess',
      component: () => import('../pages/meeting/meetingbigdata/hadoop/MeetingBigDataMRProcess'),
    },
    {
      path: '/meetingBigDataFsimageEditLog',
      component: () => import('../pages/meeting/meetingbigdata/hadoop/MeetingBigDataFsimageEditLog'),
    },
    {
      path: '/meetingBigDataLeftJoin',
      component: () => import('../pages/meeting/meetingbigdata/hadoop/MeetingBigDataLeftJoin'),
    },
    {
      path: '/meetingBigDataHiveInnerTable',
      component: () => import('../pages/meeting/meetingbigdata/hadoop/MeetingBigDataHiveInnerTable'),
    },
    {
      path: '/meetingBigDataHiveCreateTable',
      component: () => import('../pages/meeting/meetingbigdata/hadoop/MeetingBigDataHiveCreateTable'),
    },
    {
      path: '/meetingBigDataHiveDb',
      component: () => import('../pages/meeting/meetingbigdata/hadoop/MeetingBigDataHiveDb'),
    },
    {
      path: '/meetingBigDataHiveImport',
      component: () => import('../pages/meeting/meetingbigdata/hadoop/MeetingBigDataHiveImport'),
    },
    {
      path: '/meetingBigDataHiveImportData',
      component: () => import('../pages/meeting/meetingbigdata/hadoop/MeetingBigDataHiveImportData'),
    },
    {
      path: '/meetingBigDataSparkPartition',
      component: () => import('../pages/meeting/meetingbigdata/hadoop/MeetingBigDataSparkPartition'),
    },
    {
      path: '/meetingBigDataRepartition',
      component: () => import('../pages/meeting/meetingbigdata/hadoop/MeetingBigDataRepartition'),
    },
    {
      path: '/meetingBigDataSortByKey',
      component: () => import('../pages/meeting/meetingbigdata/hadoop/MeetingBigDataSortByKey'),
    },
    {
      path: '/meetingBigDataMapPartitions',
      component: () => import('../pages/meeting/meetingbigdata/hadoop/MeetingBigDataMapPartitions'),
    },
    {
      path: '/meetingBigDataCheckPoint',
      component: () => import('../pages/meeting/meetingbigdata/hadoop/MeetingBigDataCheckPoint'),
    },
    {
      path: '/meetingBigDataCacheCheckPoint',
      component: () => import('../pages/meeting/meetingbigdata/hadoop/MeetingBigDataCacheCheckPoint'),
    },
    {
      path: '/meetingBigDataSparkConnector',
      component: () => import('../pages/meeting/meetingbigdata/hadoop/MeetingBigDataSparkConnector'),
    },
    {
      path: '/meetingBigDataYarnCluster',
      component: () => import('../pages/meeting/meetingbigdata/hadoop/MeetingBigDataYarnCluster'),
    },
    {
      path: '/meetingBigDataYarnKill',
      component: () => import('../pages/meeting/meetingbigdata/hadoop/MeetingBigDataYarnKill'),
    },
    {
      path: '/MeetingBigDataYarnKill',
      component: () => import('../pages/meeting/meetingbigdata/hadoop/MeetingBigDataYarnKill'),
    },
    {
      path: '/meetingBigDataSqlActiveUsers',
      component: () => import('../pages/meeting/meetingbigdata/hadoop/MeetingBigDataSqlActiveUsers'),
    },
    {
      path: '/meetingBigDataRepartitionCoalesce',
      component: () => import('../pages/meeting/meetingbigdata/hadoop/MeetingBigDataRepartitionCoalesce'),
    },
    {
      path: '/meetingBigDataSparkSmallFilesMerge',
      component: () => import('../pages/meeting/meetingbigdata/hadoop/MeetingBigDataSparkSmallFilesMerge'),
    },
    {
      path: '/meetingBigDataOneData',
      component: () => import('../pages/meeting/meetingbigdata/hadoop/MeetingBigDataOneData'),
    },
    {
      path: '/meetingBigDatadimensionalModeling',
      component: () => import('../pages/meeting/meetingbigdata/hadoop/MeetingBigDataDimensionalModeling'),
    },
    {
      path: '/meetingBigDataFiftyQuestions',
      component: () => import('../pages/meeting/meetingbigdata/hadoop2/MeetingBigDataFiftyQuestions'),
    },
    {
      path: '/meetingBigDataSummary',
      component: () => import('../pages/meeting/meetingbigdata/hadoop2/MeetingBigDataSummary'),
    },
    {
      path: '/meetingBigDataNormalSummary',
      component: () => import('../pages/meeting/meetingbigdata/hadoop2/MeetingBigDataNormalSummary'),
    },
    {
      path: '/meetingBigDataNormalSummaryTwo',
      component: () => import('../pages/meeting/meetingbigdata/hadoop2/MeetingBigDataNormalSummaryTwo'),
    },
    {
      path: '/meetingBigDataClassicMeetings',
      component: () => import('../pages/meeting/meetingbigdata/hadoop2/MeetingBigDataClassicMeetings'),
    },
    {
      path: '/vueIntroduce',
      component: () => import('../pages/front/vue/VueIntroduce'),
    },
    {
      path: '/vueCreateApp',
      component: () => import('../pages/front/vue/VueCreateApp'),
    },
    {
      path: '/vueStarted',
      component: () => import('../pages/front/vue/VueStarted'),
    },
    {
      path: '/vueTemplateSyntax',
      component: () => import('../pages/front/vue/VueTemplateSyntax'),
    },
    {
      path: '/vueResponseBase',
      component: () => import('../pages/front/vue/VueResponseBase'),
    },
    {
      path: '/vueComputeProperties',
      component: () => import('../pages/front/vue/VueComputeProperties'),
    },
    {
      path: '/vueClassStyleBand',
      component: () => import('../pages/front/vue/VueClassStyleBand'),
    },
    {
      path: '/vueListener',
      component: () => import('../pages/front/vue/VueListener'),
    },
    {
      path: '/vueLifecycleHook',
      component: () => import('../pages/front/vue/VueLifecycleHook'),
    },
    {
      path: '/vueFormBinding',
      component: () => import('../pages/front/vue/VueFormBinding'),
    },
    {
      path: '/vueEventProcess',
      component: () => import('../pages/front/vue/VueEventProcess'),
    },
    {
      path: '/vueInstruct',
      component: () => import('../pages/front/vue/VueInstruct'),
    },
    {
      path: '/vueSFC',
      component: () => import('../pages/front/vue/VueSFC'),
    },
    {
      path: '/vueTemplateRefer',
      component: () => import('../pages/front/vue/VueTemplateRefer'),
    },
    {
      path: '/vueComponentBase',
      component: () => import('../pages/front/vue/VueComponentBase'),
    },
    {
      path: '/vueComponentRegister',
      component: () => import('../pages/front/vue/VueComponentRegister'),
    },
    {
      path: '/vueProps',
      component: () => import('../pages/front/vue/VueProps'),
    },
    {
      path: '/vueVModel',
      component: () => import('../pages/front/vue/VueVModel'),
    },
    {
      path: '/vueAttributes',
      component: () => import('../pages/front/vue/VueAttributes'),
    },
    {
      path: '/vueSlot',
      component: () => import('../pages/front/vue/VueSlot'),
    },
    {
      path: '/vueDependencyInjection',
      component: () => import('../pages/front/vue/VueDependencyInjection'),
    },
    {
      path: '/vueSyncComponent',
      component: () => import('../pages/front/vue/VueSyncComponent'),
    },
    {
      path: '/vueGroupFunctions',
      component: () => import('../pages/front/vue/VueGroupFunctions'),
    },
    {
      path: '/vueCustomInstructions',
      component: () => import('../pages/front/vue/VueCustomInstructions'),
    },
    {
      path: '/vuePlugin',
      component: () => import('../pages/front/vue/VuePlugin'),
    },
    {
      path: '/vueStatusManage',
      component: () => import('../pages/front/vue/VueStatusManage'),
    },
    {
      path: '/vueRoute',
      component: () => import('../pages/front/vue/VueRoute'),
    },
    {
      path: '/vueProjectCombat',
      component: () => import('../pages/front/vue/VueProjectCombat'),
    },
    {
      path: '/reactProCreate',
      component: () => import('../pages/front/react/ReactProCreate'),
    },
    {
      path: '/reactJsxbase',
      component: () => import('../pages/front/react/ReactJsxbase'),
    },
    {
      path: '/reactComponents',
      component: () => import('../pages/front/react/ReactComponents'),
    },
    {
      path: '/reactComponentsStatus',
      component: () => import('../pages/front/react/ReactComponentsStatus'),
    },
    {
      path: '/reactComponentsComm',
      component: () => import('../pages/front/react/ReactComponentsComm'),
    },
    {
      path: '/reactComponentsProps',
      component: () => import('../pages/front/react/ReactComponentsProps'),
    },
    {
      path: '/reactHooksBase',
      component: () => import('../pages/front/react/ReactHooksBase'),
    },
    {
      path: '/reactHooksImprove',
      component: () => import('../pages/front/react/ReactHooksImprove'),
    },
    {
      path: '/reactRouter',
      component: () => import('../pages/front/react/ReactRouter'),
    },
    {
      path: '/reactMobx',
      component: () => import('../pages/front/react/ReactMobx'),
    },
    {
      path: '/reactCraco',
      component: () => import('../pages/front/react/ReactCraco'),
    },
    {
      path: '/reactEcharts',
      component: () => import('../pages/front/react/ReactEcharts'),
    },
    {
      path: '/reactDom',
      component: () => import('../pages/front/react/ReactDom'),
    },
    {
      path: '/reactUseEffect',
      component: () => import('../pages/front/react/ReactUseEffect'),
    },
    {
      path: '/reactRef',
      component: () => import('../pages/front/react/ReactRef'),
    },
    {
      path: '/reactIndexJs',
      component: () => import('../pages/front/react/ReactIndexJs'),
    },
    {
      path: '/reactAppJs',
      component: () => import('../pages/front/react/ReactAppJs'),
    },
    {
      path: '/reactDayJs',
      component: () => import('../pages/front/react/ReactDayJs'),
    },
    {
      path: '/reactFormBand',
      component: () => import('../pages/front/react/ReactFormBand'),
    },
    {
      path: '/reactComment',
      component: () => import('../pages/front/react/ReactComment'),
    },
    {
      path: '/reactNewHotOrder',
      component: () => import('../pages/front/react/ReactNewHotOrder'),
    },
    
    
    {
      path: '/html5Introduce',
      component: () => import('../pages/front/html5/Html5Introduce'),
    },
    {
      path: '/html5Browser',
      component: () => import('../pages/front/html5/Html5Browser'),
    },
    {
      path: '/html5NewElements',
      component: () => import('../pages/front/html5/Html5NewElements'),
    },
    {
      path: '/html5Canvas',
      component: () => import('../pages/front/html5/Html5Canvas'),
    },
    {
      path: '/html5SVG',
      component: () => import('../pages/front/html5/Html5SVG'),
    },
    {
      path: '/html5MathML',
      component: () => import('../pages/front/html5/Html5MathML'),
    },
    {
      path: '/html5Drag',
      component: () => import('../pages/front/html5/Html5Drag'),
    },
    {
      path: '/html5Geolocation',
      component: () => import('../pages/front/html5/Html5Geolocation'),
    },
    {
      path: '/html5Video',
      component: () => import('../pages/front/html5/Html5Video'),
    },
    {
      path: '/html5Audio',
      component: () => import('../pages/front/html5/Html5Audio'),
    },
    {
      path: '/html5Input',
      component: () => import('../pages/front/html5/Html5Input'),
    },
    {
      path: '/html5Form',
      component: () => import('../pages/front/html5/Html5Form'),
    },
    {
      path: '/html5Semantics',
      component: () => import('../pages/front/html5/Html5Semantics'),
    },
    {
      path: '/html5Web',
      component: () => import('../pages/front/html5/Html5Web'),
    },
    {
      path: '/html5WebSQL',
      component: () => import('../pages/front/html5/Html5WebSQL'),
    },
    {
      path: '/html5AppCache',
      component: () => import('../pages/front/html5/Html5AppCache'),
    },
    {
      path: '/html5WebWorkers',
      component: () => import('../pages/front/html5/Html5WebWorkers'),
    },
    {
      path: '/html5SentEvents',
      component: () => import('../pages/front/html5/Html5SentEvents'),
    },
    {
      path: '/html5WebSocket',
      component: () => import('../pages/front/html5/Html5WebSocket'),
    },
    {
      path: '/html5Specification',
      component: () => import('../pages/front/html5/Html5Specification'),
    },
    
    
    {
      path: '/cssIntroduce',
      component: () => import('../pages/front/css/CssIntroduce'),
    },
    {
      path: '/cssBaseAttribute',
      component: () => import('../pages/front/css/CssBaseAttribute'),
    },
    {
      path: '/cssLength',
      component: () => import('../pages/front/css/CssLength'),
    },
    {
      path: '/cssModelBrief',
      component: () => import('../pages/front/css/CssModelBrief'),
    },
    {
      path: '/cssAttributeSummary',
      component: () => import('../pages/front/css/CssAttributeSummary'),
    },
    {
      path: '/cssAnchorLink',
      component: () => import('../pages/front/css/CssAnchorLink'),
    },
    {
      path: '/cssGradientAnimal',
      component: () => import('../pages/front/css/CssGradientAnimal'),
    },
    {
      path: '/cssTwoDExchange',
      component: () => import('../pages/front/css/CssTwoDExchange'),
    },
    {
      path: '/cssAttributeAlign',
      component: () => import('../pages/front/css/CssAttributeAlign'),
    },
    {
      path: '/cssFlexModel',
      component: () => import('../pages/front/css/CssFlexModel'),
    },
    {
      path: '/cssGrid',
      component: () => import('../pages/front/css/CssGrid'),
    },
    {
      path: '/cssColumn',
      component: () => import('../pages/front/css/CssColumn'),
    },
    {
      path: '/cssMediaQuery',
      component: () => import('../pages/front/css/CssMediaQuery'),
    },
    {
      path: '/cssPositionZindex',
      component: () => import('../pages/front/css/CssPositionZindex'),
    },
    {
      path: '/cssFloatClear',
      component: () => import('../pages/front/css/CssFloatClear'),
    },
    {
      path: '/cssSelector',
      component: () => import('../pages/front/css/CssSelector'),
    },
    {
      path: '/cssCalc',
      component: () => import('../pages/front/css/CssCalc'),
    },
    {
      path: '/cssVarPara',
      component: () => import('../pages/front/css/CssVarPara'),
    },
    {
      path: '/cssAttributeMouse',
      component: () => import('../pages/front/css/CssAttributeMouse'),
    },
    {
      path: '/cssOpacity',
      component: () => import('../pages/front/css/CssOpacity'),
    },
    {
      path: '/cssMinMaxFunction',
      component: () => import('../pages/front/css/CssMinMaxFunction'),
    },
    {
      path: '/cssGapAttribute',
      component: () => import('../pages/front/css/CssGapAttribute'),
    },


    
    {
      path: '/jspIntroduce',
      component: () => import('../pages/front/jsp/JspIntroduce'),
    },
    {
      path: '/jspPrinciple',
      component: () => import('../pages/front/jsp/JspPrinciple'),
    },
    {
      path: '/jspServlet',
      component: () => import('../pages/front/jsp/JspServlet'),
    },
    {
      path: '/jsptemplateExpression',
      component: () => import('../pages/front/jsp/JsptemplateExpression'),
    },
    {
      path: '/jspScript',
      component: () => import('../pages/front/jsp/JspScript'),
    },
    {
      path: '/jspDeclarAnno',
      component: () => import('../pages/front/jsp/JspDeclarAnno'),
    },
    {
      path: '/jspInstruction',
      component: () => import('../pages/front/jsp/JspInstruction'),
    },
    {
      path: '/jspOutObject',
      component: () => import('../pages/front/jsp/JspOutObject'),
    },
    {
      path: '/jspPageContext',
      component: () => import('../pages/front/jsp/JspPageContext'),
    },
    {
      path: '/jspException',
      component: () => import('../pages/front/jsp/JspException'),
    },
    {
      path: '/jspInclude',
      component: () => import('../pages/front/jsp/JspInclude'),
    },
    {
      path: '/jspForward',
      component: () => import('../pages/front/jsp/JspForward'),
    },
    
    


    
    {
      path: '/designPatternBaseIntroduce',
      component: () => import('../pages/java/components/designpattern/designPatternBase/DesignPatternBaseIntroduce'),
    },
    {
      path: '/designPatternSingletonPattern',
      component: () => import('../pages/java/components/designpattern/designPatternBase/DesignPatternSingletonPattern'),
    },
    {
      path: '/designPatternFactoryPattern',
      component: () => import('../pages/java/components/designpattern/designPatternBase/DesignPatternFactoryPattern'),
    },
    {
      path: '/designPatternDecoratorPattern',
      component: () => import('../pages/java/components/designpattern/designPatternBase/DesignPatternDecoratorPattern'),
    },
    {
      path: '/designPatternAdapterPattern',
      component: () => import('../pages/java/components/designpattern/designPatternBase/DesignPatternAdapterPattern'),
    },
    {
      path: '/designPatternObserverPattern',
      component: () => import('../pages/java/components/designpattern/designPatternBase/DesignPatternObserverPattern'),
    },
    {
      path: '/designPatternFacadePattern',
      component: () => import('../pages/java/components/designpattern/designPatternBase/DesignPatternFacadePattern'),
    },
    {
      path: '/designPatternStatePattern',
      component: () => import('../pages/java/components/designpattern/designPatternBase/DesignPatternStatePattern'),
    },
    {
      path: '/designPatternStrategyPattern',
      component: () => import('../pages/java/components/designpattern/designPatternBase/DesignPatternStrategyPattern'),
    },
    {
      path: '/designPatternProxyPattern',
      component: () => import('../pages/java/components/designpattern/designPatternBase/DesignPatternProxyPattern'),
    },
    {
      path: '/designPatternChainResponsePattern',
      component: () => import('../pages/java/components/designpattern/designPatternBase/DesignPatternChainResponsePattern'),
    },
    {
      path: '/designPatternTemplatePattern',
      component: () => import('../pages/java/components/designpattern/designPatternBase/DesignPatternTemplatePattern'),
    },
    {
      path: '/designPatternFlyweightPattern',
      component: () => import('../pages/java/components/designpattern/designPatternBase/DesignPatternFlyweightPattern'),
    },
    {
      path: '/designPatternCommandPattern',
      component: () => import('../pages/java/components/designpattern/designPatternBase/DesignPatternCommandPattern'),
    },
    {
      path: '/designPatternBuilderPattern',
      component: () => import('../pages/java/components/designpattern/designPatternBase/DesignPatternBuilderPattern'),
    },
    {
      path: '/designPatternPrototypePattern',
      component: () => import('../pages/java/components/designpattern/designPatternBase/DesignPatternPrototypePattern'),
    },
    {
      path: '/designPatternMementoPattern',
      component: () => import('../pages/java/components/designpattern/designPatternBase/DesignPatternMementoPattern'),
    },
    {
      path: '/designPatternIteratorPattern',
      component: () => import('../pages/java/components/designpattern/designPatternBase/DesignPatternIteratorPattern'),
    },
    {
      path: '/designPatternCompositePattern',
      component: () => import('../pages/java/components/designpattern/designPatternBase/DesignPatternCompositePattern'),
    },
    {
      path: '/designPatternBridgePattern',
      component: () => import('../pages/java/components/designpattern/designPatternBase/DesignPatternBridgePattern'),
    },
    {
      path: '/designPatternMediatorPattern',
      component: () => import('../pages/java/components/designpattern/designPatternBase/DesignPatternMediatorPattern'),
    },
    {
      path: '/designPatternVisitorPattern',
      component: () => import('../pages/java/components/designpattern/designPatternBase/DesignPatternVisitorPattern'),
    },
    {
      path: '/designPatternInterpreterPattern',
      component: () => import('../pages/java/components/designpattern/designPatternBase/DesignPatternInterpreterPattern'),
    },
    
    {
      path: '/dockerInstall',
      component: () => import('../pages/cloud/docker/dockerbase/DockerInstall'),
    },
    {
      path: '/dockerCommand',
      component: () => import('../pages/cloud/docker/dockerbase/DckerCommand'),
    },
    {
      path: '/dockerImage',
      component: () => import('../pages/cloud/docker/dockerbase/DockerImage'),
    },
    {
      path: '/dockerInstallSoft',
      component: () => import('../pages/cloud/docker/dockerbase/DockerInstallSoft'),
    },
    {
      path: '/dockerHarbor',
      component: () => import('../pages/cloud/docker/dockerbase/DockerHarbor'),
    },
    {
      path: '/dockerMavenPlugin',
      component: () => import('../pages/cloud/docker/dockerbase/DockerMavenPlugin'),
    },
    {
      path: '/dockerMount',
      component: () => import('../pages/cloud/docker/dockerbase/DockerMount'),
    },
    {
      path: '/dockerIntroduce',
      component: () => import('../pages/cloud/docker/dockerbase/DockerIntroduce'),
    },
    {
      path: '/dockerFile',
      component: () => import('../pages/cloud/docker/dockerbase/DockerFile'),
    },
    {
      path: '/dockerOffLineInstall',
      component: () => import('../pages/cloud/docker/dockerbase/DockerOffLineInstall'),
    },
    {
      path: '/dockerMinioInstall',
      component: () => import('../pages/cloud/docker/dockerbase/DockerMinioInstall'),
    },
    {
      path: '/dockerNetwork',
      component: () => import('../pages/cloud/docker/dockerbase/DockerNetwork'),
    },
    {
      path: '/dockerCompose',
      component: () => import('../pages/cloud/docker/dockerbase/DockerCompose'),
    },
    {
      path: '/dockerSecretDockerConfig',
      component: () => import('../pages/cloud/docker/dockerbase/DockerSecretDockerConfig'),
    },
    {
      path: '/dockerStack',
      component: () => import('../pages/cloud/docker/dockerbase/DockerStack'),
    },
    {
      path: '/dockerSwarm',
      component: () => import('../pages/cloud/docker/dockerbase/DockerSwarm'),
    },
    {
      path: '/dockerIdeaSwap',
      component: () => import('../pages/cloud/docker/dockerbase/DockerIdeaSwap'),
    },
    {
      path: '/dockerOverview',
      component: () => import('../pages/cloud/docker/dockerrecognize/DockerOverview'),
    },
    {
      path: '/dockerOnlineInstall',
      component: () => import('../pages/cloud/docker/dockerrecognize/DockerOnlineInstall'),
    },
    {
      path: '/dockerofflineInstall',
      component: () => import('../pages/cloud/docker/dockerrecognize/DockerofflineInstall'),
    },
    {
      path: '/dockerScriptInstall',
      component: () => import('../pages/cloud/docker/dockerrecognize/DockerScriptInstall'),
    },
    {
      path: '/dockerInstallQuestions',
      component: () => import('../pages/cloud/docker/dockerrecognize/DockerInstallQuestions'),
    },
    {
      path: '/dockerRunTheory',
      component: () => import('../pages/cloud/docker/dockerrecognize/DockerRunTheory'),
    },
    {
      path: '/dockerInductionProgram',
      component: () => import('../pages/cloud/docker/dockeruses/DockerInductionProgram'),
    },
    {
      path: '/dockerFileStruct',
      component: () => import('../pages/cloud/docker/dockeruses/DockerFileStruct'),
    },
    {
      path: '/dockerFileCommand',
      component: () => import('../pages/cloud/docker/dockeruses/DockerFileCommand'),
    },
    {
      path: '/dockerIgnoreFile',
      component: () => import('../pages/cloud/docker/dockeruses/DockerIgnoreFile'),
    },
    {
      path: '/dockerCommonCommand',
      component: () => import('../pages/cloud/docker/dockeruses/DockerCommonCommand'),
    },
    {
      path: '/dockerMirrorTools',
      component: () => import('../pages/cloud/docker/dockeruses/DockerMirrorTools'),
    },
    {
      path: '/dockerHubMirrorTools',
      component: () => import('../pages/cloud/docker/dockeruses/DockerHubMirrorTools'),
    },
    {
      path: '/dockerRegistryStoreHouse',
      component: () => import('../pages/cloud/docker/dockeruses/DockerRegistryStoreHouse'),
    },
    {
      path: '/dockerRegistryStoreHouseConfig',
      component: () => import('../pages/cloud/docker/dockeruses/DockerRegistryStoreHouseConfig'),
    }, 
    {
      path: '/dockerDefaultNetManage',
      component: () => import('../pages/cloud/docker/dockernetdata/DockerDefaultNetManage'),
    },
    {
      path: '/dockerDefineNet',
      component: () => import('../pages/cloud/docker/dockernetdata/DockerDefineNet'),
    },
    {
      path: '/dockerDefineBridge',
      component: () => import('../pages/cloud/docker/dockernetdata/DockerDefineBridge'),
    },
    {
      path: '/dockerNetCommunicate',
      component: () => import('../pages/cloud/docker/dockernetdata/DockerNetCommunicate'),
    },
    {
      path: '/dockerSwarmOverview',
      component: () => import('../pages/cloud/docker/dockernetdata/DockerSwarmOverview'),
    },
    {
      path: '/dockerSwarmUse',
      component: () => import('../pages/cloud/docker/dockernetdata/DockerSwarmUse'),
    },
    {
      path: '/dockerDataStorage',
      component: () => import('../pages/cloud/docker/dockernetdata/DockerDataStorage'),
    },
    {
      path: '/dockerDataStorageMethod',
      component: () => import('../pages/cloud/docker/dockernetdata/DockerDataStorageMethod'),
    },
    {
      path: '/dockerVolumesAdvantage',
      component: () => import('../pages/cloud/docker/dockernetdata/DockerVolumesAdvantage'),
    },
    {
      path: '/dockerVolumesUse',
      component: () => import('../pages/cloud/docker/dockernetdata/DockerVolumesUse'),
    },
    
    {
      path: '/dockeMicServeStructure',
      component: () => import('../pages/cloud/docker/dockermicserveintegration/DockeMicServeStructure'),
    },
    {
      path: '/dockerMicServeIntroduce',
      component: () => import('../pages/cloud/docker/dockermicserveintegration/DockerMicServeIntroduce'),
    },
    {
      path: '/dockerMicServeStartTest',
      component: () => import('../pages/cloud/docker/dockermicserveintegration/DockerMicServeStartTest'),
    },
    {
      path: '/dockerSwaggerUI',
      component: () => import('../pages/cloud/docker/dockermicserveintegration/DockerSwaggerUI'),
    },
    {
      path: '/dockerSwaggerUITest',
      component: () => import('../pages/cloud/docker/dockermicserveintegration/DockerSwaggerUITest'),
    },
    
    {
      path: '/dockeComposeInstall',
      component: () => import('../pages/cloud/docker/dockermicserveinstall/DockeComposeInstall'),
    },
    {
      path: '/dockerComposeFile',
      component: () => import('../pages/cloud/docker/dockermicserveinstall/DockerComposeFile'),
    },
    {
      path: '/dockerMicServeIntegrate',
      component: () => import('../pages/cloud/docker/dockermicserveinstall/DockerMicServeIntegrate'),
    },
    {
      path: '/dockerEnvInstall',
      component: () => import('../pages/cloud/docker/dockermicserveinstall/DockerEnvInstall'),
    },
    {
      path: '/dockerMirrorPrepare',
      component: () => import('../pages/cloud/docker/dockermicserveinstall/DockerMirrorPrepare'),
    },
    {
      path: '/dockerNonColonyInstall',
      component: () => import('../pages/cloud/docker/dockermicserveinstall/DockerNonColonyInstall'),
    },
    {
      path: '/dockerColonyInstall',
      component: () => import('../pages/cloud/docker/dockermicserveinstall/DockerColonyInstall'),
    },
    {
      path: '/dockerMicServeTest',
      component: () => import('../pages/cloud/docker/dockermicserveinstall/DockerMicServeTest'),
    },
    {
      path: '/dockerJenkinsIntroduce',
      component: () => import('../pages/cloud/docker/dockermicserveinstall/DockerJenkinsIntroduce'),
    },
    {
      path: '/dockerJenkinsInstall',
      component: () => import('../pages/cloud/docker/dockermicserveinstall/DockerJenkinsInstall'),
    },
    {
      path: '/dockerJenkinsPluginConfig',
      component: () => import('../pages/cloud/docker/dockermicserveinstall/DockerJenkinsPluginConfig'),
    },
    {
      path: '/dockerAutoInstall',
      component: () => import('../pages/cloud/docker/dockermicserveinstall/DockerAutoInstall'),
    },
    
    
    
    
    {
      path: '/kubernetesIntroduce',
      component: () => import('../pages/cloud/kubernetes/kubernetesbase/KubernetesIntroduce'),
    },
    {
      path: '/kubernetesOffLineInstall',
      component: () => import('../pages/cloud/kubernetes/kubernetesbase/KubernetesOffLineInstall'),
    },
    {
      path: '/kubernetesKubeSphereInstall',
      component: () => import('../pages/cloud/kubernetes/kubernetesbase/KubernetesKubeSphereInstall'),
    },
    {
      path: '/kubernetesPodInit',
      component: () => import('../pages/cloud/kubernetes/kubernetesbase/KubernetesPodInit'),
    },
    {
      path: '/kubernetesCoreConcept',
      component: () => import('../pages/cloud/kubernetes/kubernetesbase/KubernetesCoreConcept'),
    },
    {
      path: '/kubernetesHelmInstall',
      component: () => import('../pages/cloud/kubernetes/kubernetesbase/KubernetesHelmInstall'),
    },
    {
      path: '/kubernetesYaml',
      component: () => import('../pages/cloud/kubernetes/kubernetesbase/KubernetesYaml'),
    },
    {
      path: '/kubernetesCommand',
      component: () => import('../pages/cloud/kubernetes/kubernetesbase/KubernetesCommand'),
    },
    {
      path: '/kubernetesAszInstall',
      component: () => import('../pages/cloud/kubernetes/kubernetesbase/KubernetesAszInstall'),
    },
    {
      path: '/kubernetesIngress',
      component: () => import('../pages/cloud/kubernetes/kubernetesbase/KubernetesIngress'),
    },
    {
      path: '/kubernetesResourceLimit',
      component: () => import('../pages/cloud/kubernetes/kubernetesbase/KubernetesResourceLimit'),
    },
    {
      path: '/kubernetesRBAC',
      component: () => import('../pages/cloud/kubernetes/kubernetesbase/KubernetesRBAC'),
    },
    {
      path: '/kubernetesProbe',
      component: () => import('../pages/cloud/kubernetes/kubernetesbase/KubernetesProbe'),
    },
    {
      path: '/kubernetesSelector',
      component: () => import('../pages/cloud/kubernetes/kubernetesbase/KubernetesSelector'),
    },
    {
      path: '/kubernetesHpa',
      component: () => import('../pages/cloud/kubernetes/kubernetesbase/KubernetesHpa'),
    },
    {
      path: '/kubernetesExposePort',
      component: () => import('../pages/cloud/kubernetes/kubernetesbase/KubernetesExposePort'),
    },
    
    {
      path: '/javaBaseGrammar',
      component: () => import('../pages/java/components/javabase/javacodebase/JavaBaseGrammar'),
    },
    {
      path: '/javaBaseVariableDefine',
      component: () => import('../pages/java/components/javabase/javacodebase/JavaBaseVariableDefine'),
    },
    {
      path: '/javaBaseVariableType',
      component: () => import('../pages/java/components/javabase/javacodebase/JavaBaseVariableType'),
    },
    {
      path: '/javaBaseVariableTypeExchange',
      component: () => import('../pages/java/components/javabase/javacodebase/JavaBaseVariableTypeExchange'),
    },
    {
      path: '/javaBaseVariableScope',
      component: () => import('../pages/java/components/javabase/javacodebase/JavaBaseVariableScope'),
    },
    {
      path: '/javaBaseConstant',
      component: () => import('../pages/java/components/javabase/javacodebase/JavaBaseConstant'),
    },
    {
      path: '/javaBaseArithmeticOperator',
      component: () => import('../pages/java/components/javabase/javacodebase/JavaBaseArithmeticOperator'),
    },
    {
      path: '/javaBaseAssignmentOperator',
      component: () => import('../pages/java/components/javabase/javacodebase/JavaBaseAssignmentOperator'),
    },
    {
      path: '/javaBaseComparativeOperator',
      component: () => import('../pages/java/components/javabase/javacodebase/JavaBaseComparativeOperator'),
    },
    {
      path: '/javaBaseLogicalOperator',
      component: () => import('../pages/java/components/javabase/javacodebase/JavaBaseLogicalOperator'),
    },
    {
      path: '/javaBaseBitOperator',
      component: () => import('../pages/java/components/javabase/javacodebase/JavaBaseBitOperator'),
    },
    {
      path: '/javaBaseConditionOperator',
      component: () => import('../pages/java/components/javabase/javacodebase/JavaBaseConditionOperator'),
    },
    {
      path: '/javaBasePriorityOperator',
      component: () => import('../pages/java/components/javabase/javacodebase/JavaBasePriorityOperator'),
    },
    {
      path: '/javaBaseIfCondition',
      component: () => import('../pages/java/components/javabase/javacodebase/JavaBaseIfCondition'),
    },
    {
      path: '/javaBaseSwitchCondition',
      component: () => import('../pages/java/components/javabase/javacodebase/JavaBaseSwitchCondition'),
    },
    {
      path: '/javaBaseWhileCondition',
      component: () => import('../pages/java/components/javabase/javacodebase/JavaBaseWhileCondition'),
    },
    {
      path: '/javaBaseDoWhileCondition',
      component: () => import('../pages/java/components/javabase/javacodebase/JavaBaseDoWhileCondition'),
    },
    {
      path: '/javaBaseForCondition',
      component: () => import('../pages/java/components/javabase/javacodebase/JavaBaseForCondition'),
    },
    {
      path: '/javaBaseCircleCondition',
      component: () => import('../pages/java/components/javabase/javacodebase/JavaBaseCircleCondition'),
    },
    {
      path: '/javaBaseBreakContinue',
      component: () => import('../pages/java/components/javabase/javacodebase/JavaBaseBreakContinue'),
    },
    {
      path: '/javaBaseArrayDefine',
      component: () => import('../pages/java/components/javabase/javacodebase/JavaBaseArrayDefine'),
    },
    {
      path: '/javaBaseArrayCommonOperate',
      component: () => import('../pages/java/components/javabase/javacodebase/JavaBaseArrayCommonOperate'),
    },
    {
      path: '/javaBaseMultiArray',
      component: () => import('../pages/java/components/javabase/javacodebase/JavaBaseMultiArray'),
    },
    {
      path: '/javaObjectOrientedOverview',
      component: () => import('../pages/java/components/javabase/javaobjectoriented/JavaObjectOrientedOverview'),
    },
    {
      path: '/javaObjectOrientedClassObject',
      component: () => import('../pages/java/components/javabase/javaobjectoriented/JavaObjectOrientedClassObject'),
    },
    {
      path: '/javaObjectOrientedClassDefine',
      component: () => import('../pages/java/components/javabase/javaobjectoriented/JavaObjectOrientedClassDefine'),
    },
    {
      path: '/javaObjectOrientedClassUses',
      component: () => import('../pages/java/components/javabase/javaobjectoriented/JavaObjectOrientedClassUses'),
    },
    {
      path: '/javaObjectOrientedAccessControl',
      component: () => import('../pages/java/components/javabase/javaobjectoriented/JavaObjectOrientedAccessControl'),
    },
    {
      path: '/javaObjectOrientedClassPackage',
      component: () => import('../pages/java/components/javabase/javaobjectoriented/JavaObjectOrientedClassPackage'),
    },
    {
      path: '/javaObjectOrientedImplClassPackage',
      component: () => import('../pages/java/components/javabase/javaobjectoriented/JavaObjectOrientedImplClassPackage'),
    },
    {
      path: '/javaObjectOrientedOverload',
      component: () => import('../pages/java/components/javabase/javaobjectoriented/JavaObjectOrientedOverload'),
    },
    {
      path: '/javaObjectOrientedRecursive',
      component: () => import('../pages/java/components/javabase/javaobjectoriented/JavaObjectOrientedRecursive'),
    },
    {
      path: '/javaObjectOrientedConstructDefine',
      component: () => import('../pages/java/components/javabase/javaobjectoriented/JavaObjectOrientedConstructDefine'),
    },
    {
      path: '/javaObjectOrientedConstructOveride',
      component: () => import('../pages/java/components/javabase/javaobjectoriented/JavaObjectOrientedConstructOveride'),
    },
    {
      path: '/javaObjectOrientedThis',
      component: () => import('../pages/java/components/javabase/javaobjectoriented/JavaObjectOrientedThis'),
    },
    {
      path: '/javaObjectOrientedStaticParam',
      component: () => import('../pages/java/components/javabase/javaobjectoriented/JavaObjectOrientedStaticParam'),
    },
    {
      path: '/javaObjectOrientedStaticMethod',
      component: () => import('../pages/java/components/javabase/javaobjectoriented/JavaObjectOrientedStaticMethod'),
    },
    {
      path: '/javaObjectOrientedStaticBlock',
      component: () => import('../pages/java/components/javabase/javaobjectoriented/JavaObjectOrientedStaticBlock'),
    },
    
    {
      path: '/javaObjectOrientedExtendOverview',
      component: () => import('../pages/java/components/javabase/javaobjectorientedtwo/JavaObjectOrientedExtendOverview'),
    },
    {
      path: '/javaObjectOrientedOverrideParent',
      component: () => import('../pages/java/components/javabase/javaobjectorientedtwo/JavaObjectOrientedOverrideParent'),
    },
    {
      path: '/javaObjectOrientedSuper',
      component: () => import('../pages/java/components/javabase/javaobjectorientedtwo/JavaObjectOrientedSuper'),
    },
    {
      path: '/javaObjectOrientedObject',
      component: () => import('../pages/java/components/javabase/javaobjectorientedtwo/JavaObjectOrientedObject'),
    },

    {
      path: '/javaObjectOrientedFinalClass',
      component: () => import('../pages/java/components/javabase/javaobjectorientedtwo/JavaObjectOrientedFinalClass'),
    },
    {
      path: '/javaObjectOrientedFinalMethod',
      component: () => import('../pages/java/components/javabase/javaobjectorientedtwo/JavaObjectOrientedFinalMethod'),
    },
    {
      path: '/javaObjectOrientedFinalMethodParam',
      component: () => import('../pages/java/components/javabase/javaobjectorientedtwo/JavaObjectOrientedFinalMethodParam'),
    },
    {
      path: '/javaObjectOrientedAbstractClass',
      component: () => import('../pages/java/components/javabase/javaobjectorientedtwo/JavaObjectOrientedAbstractClass'),
    },
    {
      path: '/javaObjectOrientedInterface',
      component: () => import('../pages/java/components/javabase/javaobjectorientedtwo/JavaObjectOrientedInterface'),
    },
    {
      path: '/javaObjectOrientedMultiClass',
      component: () => import('../pages/java/components/javabase/javaobjectorientedtwo/JavaObjectOrientedMultiClass'),
    },
    {
      path: '/javaObjectOrientedTypeExchange',
      component: () => import('../pages/java/components/javabase/javaobjectorientedtwo/JavaObjectOrientedTypeExchange'),
    },
    {
      path: '/javaObjectOrientedMemberInnerClass',
      component: () => import('../pages/java/components/javabase/javaobjectorientedtwo/JavaObjectOrientedMemberInnerClass'),
    },
    {
      path: '/javaObjectOrientedLocalInnerClass',
      component: () => import('../pages/java/components/javabase/javaobjectorientedtwo/JavaObjectOrientedLocalInnerClass'),
    },
    {
      path: '/javaObjectOrientedStaticInnerClass',
      component: () => import('../pages/java/components/javabase/javaobjectorientedtwo/JavaObjectOrientedStaticInnerClass'),
    },
    {
      path: '/javaObjectOrientedAanonymousInnerClass',
      component: () => import('../pages/java/components/javabase/javaobjectorientedtwo/JavaObjectOrientedAanonymousInnerClass'),
    },
    {
      path: '/javaObjectOrientedExceptionType',
      component: () => import('../pages/java/components/javabase/javaobjectorientedtwo/JavaObjectOrientedExceptionType'),
    },
    {
      path: '/javaObjectOrientedTryCatchFinally',
      component: () => import('../pages/java/components/javabase/javaobjectorientedtwo/JavaObjectOrientedTryCatchFinally'),
    },
    {
      path: '/javaObjectOrientedThrows',
      component: () => import('../pages/java/components/javabase/javaobjectorientedtwo/JavaObjectOrientedThrows'),
    },
    {
      path: '/javaObjectOrientedThrow',
      component: () => import('../pages/java/components/javabase/javaobjectorientedtwo/JavaObjectOrientedThrow'),
    },
    {
      path: '/javaObjectOrientedDefineException',
      component: () => import('../pages/java/components/javabase/javaobjectorientedtwo/JavaObjectOrientedDefineException'),
    },
    {
      path: '/javaCollectionOverview',
      component: () => import('../pages/java/components/javabase/javacollection/JavaCollectionOverview'),
    },
    {
      path: '/javaCollectionList',
      component: () => import('../pages/java/components/javabase/javacollection/JavaCollectionList'),
    },
    {
      path: '/javaCollectionArrayList',
      component: () => import('../pages/java/components/javabase/javacollection/JavaCollectionArrayList'),
    },
    {
      path: '/javaCollectionLinkedList',
      component: () => import('../pages/java/components/javabase/javacollection/JavaCollectionLinkedList'),
    },
    {
      path: '/javaCollectionIterator',
      component: () => import('../pages/java/components/javabase/javacollection/JavaCollectionIterator'),
    },
    {
      path: '/javaCollectionForeache',
      component: () => import('../pages/java/components/javabase/javacollection/JavaCollectionForeache'),
    },
    {
      path: '/javaCollectionJdk8Foreache',
      component: () => import('../pages/java/components/javabase/javacollection/JavaCollectionJdk8Foreache'),
    },
    {
      path: '/javaCollectionHashSet',
      component: () => import('../pages/java/components/javabase/javacollection/JavaCollectionHashSet'),
    },
    {
      path: '/javaCollectionTreeSet',
      component: () => import('../pages/java/components/javabase/javacollection/JavaCollectionTreeSet'),
    },
    {
      path: '/javaCollectionMap',
      component: () => import('../pages/java/components/javabase/javacollection/JavaCollectionMap'),
    },
    {
      path: '/javaCollectionHashMap',
      component: () => import('../pages/java/components/javabase/javacollection/JavaCollectionHashMap'),
    },
    {
      path: '/javaCollectionMapForeach',
      component: () => import('../pages/java/components/javabase/javacollection/JavaCollectionMapForeach'),
    },
    {
      path: '/javaCollectionTreeMap',
      component: () => import('../pages/java/components/javabase/javacollection/JavaCollectionTreeMap'),
    },
    {
      path: '/javaCollectionProperties',
      component: () => import('../pages/java/components/javabase/javacollection/JavaCollectionProperties'),
    },
    {
      path: '/javaCollectionGenericities',
      component: () => import('../pages/java/components/javabase/javacollection/JavaCollectionGenericities'),
    },
    {
      path: '/javaCollectionCollections',
      component: () => import('../pages/java/components/javabase/javacollection/JavaCollectionCollections'),
    },
    {
      path: '/javaCollectionArrays',
      component: () => import('../pages/java/components/javabase/javacollection/JavaCollectionArrays'),
    },
    {
      path: '/javaCollectionStreamIntroduce',
      component: () => import('../pages/java/components/javabase/javacollection/JavaCollectionStreamIntroduce'),
    },
    {
      path: '/javaCollectionCreateStream',
      component: () => import('../pages/java/components/javabase/javacollection/JavaCollectionCreateStream'),
    },
    {
      path: '/javaCollectionCommonStream',
      component: () => import('../pages/java/components/javabase/javacollection/JavaCollectionCommonStream'),
    },
    {
      path: '/javaCollectionParallelStream',
      component: () => import('../pages/java/components/javabase/javacollection/JavaCollectionParallelStream'),
    },
    {
      path: '/javaIOOverview',
      component: () => import('../pages/java/components/javabase/javaio/JavaIOOverview'),
    },
    {
      path: '/javaIOByteStreamOverview',
      component: () => import('../pages/java/components/javabase/javaio/JavaIOByteStreamOverview'),
    },
    {
      path: '/javaIOButeReadWrite',
      component: () => import('../pages/java/components/javabase/javaio/JavaIOButeReadWrite'),
    },
    {
      path: '/javaIOCopyFile',
      component: () => import('../pages/java/components/javabase/javaio/JavaIOCopyFile'),
    },
    {
      path: '/javaIOByteCache',
      component: () => import('../pages/java/components/javabase/javaio/JavaIOByteCache'),
    },
    {
      path: '/javaIOByteCacheStream',
      component: () => import('../pages/java/components/javabase/javaio/JavaIOByteCacheStream'),
    },
    {
      path: '/javaIOCharacterOverview',
      component: () => import('../pages/java/components/javabase/javaio/JavaIOCharacterOverview'),
    },
    {
      path: '/javaIOCharacterFile',
      component: () => import('../pages/java/components/javabase/javaio/JavaIOCharacterFile'),
    },
    {
      path: '/javaIOExchangeStream',
      component: () => import('../pages/java/components/javabase/javaio/JavaIOExchangeStream'),
    },
    {
      path: '/javaIOFileCommonMethod',
      component: () => import('../pages/java/components/javabase/javaio/JavaIOFileCommonMethod'),
    },
    {
      path: '/javaIOForeachFiles',
      component: () => import('../pages/java/components/javabase/javaio/JavaIOForeachFiles'),
    },
    {
      path: '/javaIODeleteFiles',
      component: () => import('../pages/java/components/javabase/javaio/JavaIODeleteFiles'),
    },
    {
      path: '/javaIORandomAccessFile',
      component: () => import('../pages/java/components/javabase/javaio/JavaIORandomAccessFile'),
    },
    {
      path: '/javaIOObjectSerial',
      component: () => import('../pages/java/components/javabase/javaio/JavaIOObjectSerial'),
    },
    {
      path: '/javaIONIO',
      component: () => import('../pages/java/components/javabase/javaio/JavaIONIO'),
    },
    {
      path: '/javaIOBuffer',
      component: () => import('../pages/java/components/javabase/javaio/JavaIOBuffer'),
    },
    {
      path: '/javaIOChannel',
      component: () => import('../pages/java/components/javabase/javaio/JavaIOChannel'),
    },
    {
      path: '/javaIOPath',
      component: () => import('../pages/java/components/javabase/javaio/JavaIOPath'),
    },
    {
      path: '/javaIOFileTools',
      component: () => import('../pages/java/components/javabase/javaio/JavaIOFileTools'),
    },
    {
      path: '/jdbcOverview',
      component: () => import('../pages/java/components/javabase/jdbc/JdbcOverview'),
    },
    {
      path: '/jdbcCommonApi',
      component: () => import('../pages/java/components/javabase/jdbc/JdbcCommonApi'),
    },
    {
      path: '/jdbcCodeProcess',
      component: () => import('../pages/java/components/javabase/jdbc/JdbcCodeProcess'),
    },
    {
      path: '/jdbcFirstProcessor',
      component: () => import('../pages/java/components/javabase/jdbc/JdbcFirstProcessor'),
    },
    {
      path: '/jdbcPreparedStatement',
      component: () => import('../pages/java/components/javabase/jdbc/JdbcPreparedStatement'),
    },
    {
      path: '/jdbcCallableStatement',
      component: () => import('../pages/java/components/javabase/jdbc/JdbcCallableStatement'),
    },
    {
      path: '/jdbcResultSet',
      component: () => import('../pages/java/components/javabase/jdbc/JdbcResultSet'),
    },
    {
      path: '/jdbcStatementBatch',
      component: () => import('../pages/java/components/javabase/jdbc/JbcStatementBatch'),
    },
    {
      path: '/jdbcClob',
      component: () => import('../pages/java/components/javabase/jdbc/JdbcClob'),
    },
    {
      path: '/jdbcBlob',
      component: () => import('../pages/java/components/javabase/jdbc/JdbcBlob'),
    },
    {
      path: '/jdbcAffair',
      component: () => import('../pages/java/components/javabase/jdbc/JdbcAffair'),
    },
    {
      path: '/jdbcDbPool',
      component: () => import('../pages/java/components/javabase/jdbc/JdbcDbPool'),
    },
    {
      path: '/jdbcDbcp',
      component: () => import('../pages/java/components/javabase/jdbc/JdbcDbcp'),
    },
    {
      path: '/jdbcC3P0',
      component: () => import('../pages/java/components/javabase/jdbc/JdbcC3P0'),
    },
    
    

    {
      path: '/xmlW3cGroup',
      component: () => import('../pages/java/components/javaweb/xml/XmlW3cGroup'),
    },
    {
      path: '/xmlHtml',
      component: () => import('../pages/java/components/javaweb/xml/XmlHtml'),
    },
    {
      path: '/xmlDocDeclaration',
      component: () => import('../pages/java/components/javaweb/xml/XmlDocDeclaration'),
    },
    {
      path: '/xmlElementAttr',
      component: () => import('../pages/java/components/javaweb/xml/XmlElementAttr'),
    },
    {
      path: '/xmlAnnotation',
      component: () => import('../pages/java/components/javaweb/xml/XmlAnnotation'),
    },
    {
      path: '/xmlSpecialCharacter',
      component: () => import('../pages/java/components/javaweb/xml/XmlSpecialCharacter'),
    },
    {
      path: '/xmlCDATA',
      component: () => import('../pages/java/components/javaweb/xml/XmlCDATA'),
    },
    {
      path: '/xmlDTA',
      component: () => import('../pages/java/components/javaweb/xml/XmlDTA'),
    },
    {
      path: '/xmlDTDGrammar',
      component: () => import('../pages/java/components/javaweb/xml/XmlDTDGrammar'),
    },
    {
      path: '/xmlSchema',
      component: () => import('../pages/java/components/javaweb/xml/XmlSchema'),
    },
    {
      path: '/xmlNameSpace',
      component: () => import('../pages/java/components/javaweb/xml/XmlNameSpace'),
    },
    {
      path: '/xmlSchemaDoc',
      component: () => import('../pages/java/components/javaweb/xml/XmlSchemaDoc'),
    },
    {
      path: '/xmlSchemaGrammar',
      component: () => import('../pages/java/components/javaweb/xml/XmlSchemaGrammar'),
    },
    
    
     
    {
      path: '/tomcatBSCS',
      component: () => import('../pages/java/components/javaweb/tomcat/TomcatBSCS'),
    },
    {
      path: '/tomcatTeleProco',
      component: () => import('../pages/java/components/javaweb/tomcat/TomcatTeleProco'),
    },
    {
      path: '/tomcatWebResource',
      component: () => import('../pages/java/components/javaweb/tomcat/TomcatWebResource'),
    },
    {
      path: '/tomcatInstall',
      component: () => import('../pages/java/components/javaweb/tomcat/TomcatInstall'),
    },
    {
      path: '/tomcatJudget',
      component: () => import('../pages/java/components/javaweb/tomcat/TomcatJudget'),
    },
    {
      path: '/tomcatWebIntroduce',
      component: () => import('../pages/java/components/javaweb/tomcat/TomcatWebIntroduce'),
    },
    {
      path: '/tomcatWebVirtulContent',
      component: () => import('../pages/java/components/javaweb/tomcat/TomcatWebVirtulContent'),
    },
    {
      path: '/tomcatWebDefaultPage',
      component: () => import('../pages/java/components/javaweb/tomcat/TomcatWebDefaultPage'),
    },
    {
      path: '/tomcatManagePlatform',
      component: () => import('../pages/java/components/javaweb/tomcat/TomcatManagePlatform'),
    },
    {
      path: '/tomcatConfigVirtual',
      component: () => import('../pages/java/components/javaweb/tomcat/TomcatConfigVirtual'),
    },
    {
      path: '/tomcatEclipse',
      component: () => import('../pages/java/components/javaweb/tomcat/TomcatEclipse'),
    },
    
    {
      path: '/httpIntroduce',
      component: () => import('../pages/java/components/javaweb/httpprotocol/HttpIntroduce'),
    },
    {
      path: '/httpMessage',
      component: () => import('../pages/java/components/javaweb/httpprotocol/HttpMessage'),
    },
    {
      path: '/httpReqLine',
      component: () => import('../pages/java/components/javaweb/httpprotocol/HttpReqLine'),
    },
    {
      path: '/httpReqHeader',
      component: () => import('../pages/java/components/javaweb/httpprotocol/HttpReqHeader'),
    },
    {
      path: '/httpResStatus',
      component: () => import('../pages/java/components/javaweb/httpprotocol/HttpResStatus'),
    },
    {
      path: '/httpMessageHeader',
      component: () => import('../pages/java/components/javaweb/httpprotocol/HttpMessageHeader'),
    },
    {
      path: '/httpNormalHeader',
      component: () => import('../pages/java/components/javaweb/httpprotocol/HttpNormalHeader'),
    },
    {
      path: '/httpEntityHeader',
      component: () => import('../pages/java/components/javaweb/httpprotocol/HttpEntityHeader'),
    },
    
    {
      path: '/servletIntroduce',
      component: () => import('../pages/java/components/javaweb/servlet/ServletIntroduce'),
    },
    {
      path: '/servletImpl',
      component: () => import('../pages/java/components/javaweb/servlet/ServletImpl'),
    },
    {
      path: '/servletLifeCycle',
      component: () => import('../pages/java/components/javaweb/servlet/ServletLifeCycle'),
    },
    {
      path: '/servletHttpServlet',
      component: () => import('../pages/java/components/javaweb/servlet/ServletHttpServlet'),
    },
    {
      path: '/servletEclipse',
      component: () => import('../pages/java/components/javaweb/servlet/ServletEclipse'),
    },
    {
      path: '/servletVirtualPath',
      component: () => import('../pages/java/components/javaweb/servlet/ServletVirtualPath'),
    },
    {
      path: '/servletServletConfig',
      component: () => import('../pages/java/components/javaweb/servlet/ServletServletConfig'),
    },
    {
      path: '/servletServletContext',
      component: () => import('../pages/java/components/javaweb/servlet/ServletServletContext'),
    },
    {
      path: '/reqResSendStatusMethod',
      component: () => import('../pages/java/components/javaweb/requestresponse/ReqResSendStatusMethod'),
    },
    {
      path: '/reqResSendResHeader',
      component: () => import('../pages/java/components/javaweb/requestresponse/ReqResSendResHeader'),
    },
    {
      path: '/reqResSendResBody',
      component: () => import('../pages/java/components/javaweb/requestresponse/ReqResSendResBody'),
    },
    {
      path: '/reqResErrorCodes',
      component: () => import('../pages/java/components/javaweb/requestresponse/ReqResErrorCodes'),
    },
    {
      path: '/reqResPageRefresh',
      component: () => import('../pages/java/components/javaweb/requestresponse/ReqResPageRefresh'),
    },
    {
      path: '/reqResBrowserCache',
      component: () => import('../pages/java/components/javaweb/requestresponse/ReqResBrowserCache'),
    },
    {
      path: '/reqResRedirect',
      component: () => import('../pages/java/components/javaweb/requestresponse/ReqResRedirect'),
    },
    {
      path: '/reqResReqLine',
      component: () => import('../pages/java/components/javaweb/requestresponse/ReqResReqLine'),
    },
    {
      path: '/reqResReqHeader',
      component: () => import('../pages/java/components/javaweb/requestresponse/ReqResReqHeader'),
    },
    {
      path: '/reqResReqBody',
      component: () => import('../pages/java/components/javaweb/requestresponse/ReqResReqBody'),
    },
    {
      path: '/reqResReqParams',
      component: () => import('../pages/java/components/javaweb/requestresponse/ReqResReqParams'),
    },
    {
      path: '/reqResNetWorkLinkMessage',
      component: () => import('../pages/java/components/javaweb/requestresponse/ReqResNetWorkLinkMessage'),
    },
    {
      path: '/reqResReqObjectData',
      component: () => import('../pages/java/components/javaweb/requestresponse/ReqResReqObjectData'),
    },
    {
      path: '/reqResRequestDispatcher',
      component: () => import('../pages/java/components/javaweb/requestresponse/ReqResRequestDispatcher'),
    },
    {
      path: '/reqResReqTransmit',
      component: () => import('../pages/java/components/javaweb/requestresponse/ReqResReqTransmit'),
    },
    {
      path: '/reqResReqContains',
      component: () => import('../pages/java/components/javaweb/requestresponse/ReqResReqContains'),
    },
    {
      path: '/sessionOverview',
      component: () => import('../pages/java/components/javaweb/session/SessionOverview'),
    },
    {
      path: '/sessionCookieIntroduce',
      component: () => import('../pages/java/components/javaweb/session/SessionCookieIntroduce'),
    },
    {
      path: '/sessionCookieAPI',
      component: () => import('../pages/java/components/javaweb/session/SessionCookieAPI'),
    },
    {
      path: '/sessionIntroduce',
      component: () => import('../pages/java/components/javaweb/session/SessionIntroduce'),
    },
    {
      path: '/sessionHttpSessionAPI',
      component: () => import('../pages/java/components/javaweb/session/SessionHttpSessionAPI'),
    },
    {
      path: '/sessionTimeOut',
      component: () => import('../pages/java/components/javaweb/session/SessionTimeOut'),
    },


    {
      path: '/javaBeanOverview',
      component: () => import('../pages/java/components/javaweb/javabean/JavaBeanOverview'),
    },
    {
      path: '/javaBeanClass',
      component: () => import('../pages/java/components/javaweb/javabean/JavaBeanClass'),
    },
    {
      path: '/javaBeaReflectObject',
      component: () => import('../pages/java/components/javaweb/javabean/JavaBeaReflectObject'),
    },
    {
      path: '/javaBeanReflectParams',
      component: () => import('../pages/java/components/javaweb/javabean/JavaBeanReflectParams'),
    },
    {
      path: '/javaBeanReflectMethod',
      component: () => import('../pages/java/components/javaweb/javabean/JavaBeanReflectMethod'),
    },
    {
      path: '/javaBeanIntrospection',
      component: () => import('../pages/java/components/javaweb/javabean/JavaBeanIntrospection'),
    },
    {
      path: '/javaBeanEditParams',
      component: () => import('../pages/java/components/javaweb/javabean/JavaBeanEditParams'),
    },
    {
      path: '/javaBeanReadParams',
      component: () => import('../pages/java/components/javaweb/javabean/JavaBeanReadParams'),
    },
    {
      path: '/javaBeanJspUseBean',
      component: () => import('../pages/java/components/javaweb/javabean/JavaBeanJspUseBean'),
    },
    {
      path: '/javaBeanJspSetProperty',
      component: () => import('../pages/java/components/javaweb/javabean/JavaBeanJspSetProperty'),
    },
    {
      path: '/javaBeanJspGetProperty',
      component: () => import('../pages/java/components/javaweb/javabean/JavaBeanJspGetProperty'),
    },
    {
      path: '/javaBeanJspGetProperty',
      component: () => import('../pages/java/components/javaweb/javabean/JavaBeanJspGetProperty'),
    },
    {
      path: '/javaBeanUtils',
      component: () => import('../pages/java/components/javaweb/javabean/JavaBeanUtils'),
    },
    {
      path: '/javaBeanUtilsProperty',
      component: () => import('../pages/java/components/javaweb/javabean/JavaBeanUtilsProperty'),
    },
    
    
    
    
    {
      path: '/filterOverview',
      component: () => import('../pages/java/components/javaweb/filter/FilterOverview'),
    },
    {
      path: '/filterFirstProcessor',
      component: () => import('../pages/java/components/javaweb/filter/FilterFirstProcessor'),
    },
    {
      path: '/filterMapping',
      component: () => import('../pages/java/components/javaweb/filter/FilterMapping'),
    },
    {
      path: '/filterLinked',
      component: () => import('../pages/java/components/javaweb/filter/FilterLinked'),
    },
    {
      path: '/filterConfig',
      component: () => import('../pages/java/components/javaweb/filter/FilterConfig'),
    },
    {
      path: '/filterDecPattern',
      component: () => import('../pages/java/components/javaweb/filter/FilterDecPattern'),
    },
    {
      path: '/filterUniCode',
      component: () => import('../pages/java/components/javaweb/filter/FilterUniCode'),
    },
    {
      path: '/filterPageStatic',
      component: () => import('../pages/java/components/javaweb/filter/FilterPageStatic'),
    },
    {
      path: '/servletListenOverview',
      component: () => import('../pages/java/components/javaweb/servletlisten/ServletListenOverview'),
    },
    {
      path: '/servletListenServletContextListener',
      component: () => import('../pages/java/components/javaweb/servletlisten/ServletListenServletContextListener'),
    },
    {
      path: '/servletListenHttpSessionListener',
      component: () => import('../pages/java/components/javaweb/servletlisten/ServletListenHttpSessionListener'),
    },
    {
      path: '/servletListenServletRequestListener',
      component: () => import('../pages/java/components/javaweb/servletlisten/ServletListenServletRequestListener'),
    },
    {
      path: '/servletListenParams',
      component: () => import('../pages/java/components/javaweb/servletlisten/ServletListenParams'),
    },
    {
      path: '/servletListenHttpSessionBindingListener',
      component: () => import('../pages/java/components/javaweb/servletlisten/ServletListenHttpSessionBindingListener'),
    },
    {
      path: '/servletListenHttpSessionActivationListener',
      component: () => import('../pages/java/components/javaweb/servletlisten/ServletListenHttpSessionActivationListener'),
    },
    
    
    {
      path: '/filesUpload',
      component: () => import('../pages/java/components/javaweb/fileupload/FilesUpload'),
    },
    {
      path: '/filesFileItem',
      component: () => import('../pages/java/components/javaweb/fileupload/FilesFileItem'),
    },
    {
      path: '/filesDiskFileItemFactory',
      component: () => import('../pages/java/components/javaweb/fileupload/FilesDiskFileItemFactory'),
    },
    {
      path: '/filesServletFileUpload',
      component: () => import('../pages/java/components/javaweb/fileupload/FilesServletFileUpload'),
    },
    {
      path: '/filesDownLoadPrinciple',
      component: () => import('../pages/java/components/javaweb/fileupload/FilesDownLoadPrinciple'),
    },
    {
      path: '/filesDownloadCode',
      component: () => import('../pages/java/components/javaweb/fileupload/FilesDownloadCode'),
    },
    
    {
      path: '/elGrammar',
      component: () => import('../pages/java/components/javaweb/elexpress/ElGrammar'),
    },
    {
      path: '/elOperator',
      component: () => import('../pages/java/components/javaweb/elexpress/ElOperator'),
    },
    {
      path: '/elPageContext',
      component: () => import('../pages/java/components/javaweb/elexpress/ElPageContext'),
    },
    {
      path: '/elWebObject',
      component: () => import('../pages/java/components/javaweb/elexpress/ElWebObject'),
    },
    {
      path: '/elParamValues',
      component: () => import('../pages/java/components/javaweb/elexpress/ElParamValues'),
    },
    {
      path: '/elHeaderValues',
      component: () => import('../pages/java/components/javaweb/elexpress/ElHeaderValues'),
    },
    {
      path: '/elCookie',
      component: () => import('../pages/java/components/javaweb/elexpress/ElCookie'),
    },
    {
      path: '/elInitParam',
      component: () => import('../pages/java/components/javaweb/elexpress/ElInitParam'),
    },
    {
      path: '/elHTMLInject',
      component: () => import('../pages/java/components/javaweb/elexpress/ElHTMLInject'),
    },
    {
      path: '/internationIntroduce',
      component: () => import('../pages/java/components/javaweb/internation/InternationIntroduce'),
    },
    {
      path: '/internationLocale',
      component: () => import('../pages/java/components/javaweb/internation/InternationLocale'),
    },
    {
      path: '/internationResourceBundle',
      component: () => import('../pages/java/components/javaweb/internation/InternationResourceBundle'),
    },
    {
      path: '/internationDateFormat',
      component: () => import('../pages/java/components/javaweb/internation/InternationDateFormat'),
    },
    {
      path: '/internationNumberFormat',
      component: () => import('../pages/java/components/javaweb/internation/InternationNumberFormat'),
    },
    {
      path: '/internationMessageFormat',
      component: () => import('../pages/java/components/javaweb/internation/InternationMessageFormat'),
    },
    {
      path: '/internationWebLocalInfo',
      component: () => import('../pages/java/components/javaweb/internation/InternationWebLocalInfo'),
    },
    {
      path: '/internationGlobalLabel',
      component: () => import('../pages/java/components/javaweb/internation/InternationGlobalLabel'),
    },
    {
      path: '/internationInfoLabel',
      component: () => import('../pages/java/components/javaweb/internation/InternationInfoLabel'),
    },
    {
      path: '/internationNumberDate',
      component: () => import('../pages/java/components/javaweb/internation/InternationNumberDate'),
    },
    
    
    
    

    
    
    {
      path: '/javeNetWorkProtocol',
      component: () => import('../pages/java/components/javabase/javanetwork/JaveNetWorkProtocol'),
    },
    {
      path: '/javeNetWorkIPPort',
      component: () => import('../pages/java/components/javabase/javanetwork/JaveNetWorkIPPort'),
    },
    {
      path: '/javeNetWorkInetAddress',
      component: () => import('../pages/java/components/javabase/javanetwork/JaveNetWorkInetAddress'),
    },
    {
      path: '/javeNetWorkUDPTCP',
      component: () => import('../pages/java/components/javabase/javanetwork/JaveNetWorkUDPTCP'),
    },
    {
      path: '/javeNetWorkUdpIntroduce',
      component: () => import('../pages/java/components/javabase/javanetwork/JaveNetWorkUdpIntroduce'),
    },
    {
      path: '/javeNetWorkDatagramPacket',
      component: () => import('../pages/java/components/javabase/javanetwork/JaveNetWorkDatagramPacket'),
    },
    {
      path: '/javeNetWorkDatagramSocket',
      component: () => import('../pages/java/components/javabase/javanetwork/JaveNetWorkDatagramSocket'),
    },
    {
      path: '/javeNetWorkUdpPrograms',
      component: () => import('../pages/java/components/javabase/javanetwork/JaveNetWorkUdpPrograms'),
    },
    {
      path: '/javeNetWorkTcpIntroduce',
      component: () => import('../pages/java/components/javabase/javanetwork/JaveNetWorkTcpIntroduce'),
    },
    {
      path: '/javeNetWorkTcpPrograms',
      component: () => import('../pages/java/components/javabase/javanetwork/JaveNetWorkTcpPrograms'),
    },
    {
      path: '/javeNetWorkMutiThreadTcpPrograms',
      component: () => import('../pages/java/components/javabase/javanetwork/JaveNetWorkMutiThreadTcpPrograms'),
    },
    
    {
      path: '/javaDevIntroduce',
      component: () => import('../pages/java/components/javabase/javadev/JavaDevIntroduce'),
    },
    {
      path: '/javaDevJdkInstall',
      component: () => import('../pages/java/components/javabase/javadev/JavaDevJdkInstall'),
    },
    {
      path: '/javaDevEnvVariable',
      component: () => import('../pages/java/components/javabase/javadev/JavaDevEnvVariable'),
    },
    {
      path: '/javaDevFirstProgram',
      component: () => import('../pages/java/components/javabase/javadev/JavaDevFirstProgram'),
    },
    {
      path: '/javaDevRunningMechanism',
      component: () => import('../pages/java/components/javabase/javadev/JavaDevRunningMechanism'),
    },
    {
      path: '/javaDevIdeaInstall',
      component: () => import('../pages/java/components/javabase/javadev/JavaDevIdeaInstall'),
    },
    
    
    {
      path: '/javaClassStringIntroduce',
      component: () => import('../pages/java/components/javabase/javaclass/JavaClassStringIntroduce'),
    },
    {
      path: '/javaClassStringCommonOperate',
      component: () => import('../pages/java/components/javabase/javaclass/JavaClassStringCommonOperate'),
    },
    {
      path: '/javaClassStringIntroduce',
      component: () => import('../pages/java/components/javabase/javaclass/JavaClassStringIntroduce'),
    },
    {
      path: '/javaClassStringBuffer',
      component: () => import('../pages/java/components/javabase/javaclass/JavaClassStringBuffer'),
    },
    {
      path: '/javaClassSystem',
      component: () => import('../pages/java/components/javabase/javaclass/JavaClassSystem'),
    },
    {
      path: '/javaClassRuntime',
      component: () => import('../pages/java/components/javabase/javaclass/JavaClassRuntime'),
    },
    {
      path: '/javaClassMathRandom',
      component: () => import('../pages/java/components/javabase/javaclass/JavaClassMathRandom'),
    },
    {
      path: '/javaClassPackageClass',
      component: () => import('../pages/java/components/javabase/javaclass/JavaClassPackageClass'),
    },
    {
      path: '/javaClassDate',
      component: () => import('../pages/java/components/javabase/javaclass/JavaClassDate'),
    },
    {
      path: '/javaClassCalendar',
      component: () => import('../pages/java/components/javabase/javaclass/JavaClassCalendar'),
    },
    {
      path: '/javaClassJdk8Date',
      component: () => import('../pages/java/components/javabase/javaclass/JavaClassJdk8Date'),
    },
    {
      path: '/javaClassDateFormat',
      component: () => import('../pages/java/components/javabase/javaclass/JavaClassDateFormat'),
    },
    {
      path: '/javaClassSimpleDateFormat',
      component: () => import('../pages/java/components/javabase/javaclass/JavaClassSimpleDateFormat'),
    },
    {
      path: '/javaClassDateTimeFormatter',
      component: () => import('../pages/java/components/javabase/javaclass/JavaClassDateTimeFormatter'),
    },
    
    


    

  ],
});

export default router;
