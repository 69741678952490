<template>
  <div class="home">
    <div class="header">
      <div
        style="
          height: 30px;
          color: black;
          font-weight: 600;
          font-size: 1.3rem;
          margin-top: 5px;
        "
      >
        <router-link
          to="/home"
          active-class="active"
          style="text-decoration: none; color: black; margin-left: 5px"
          >我爱编程网</router-link
        >
      </div>
      <div id="app" v-cloak>
        <li
          style="width: 100px"
          v-for="menu in data.menus"
          :key="menu.name"
          @mouseover="menu.show = !menu.show"
          @mouseout="menu.show = !menu.show"
        >
          <router-link
            :to="{ path: menu.url }"
            active-class="active"
            class="nav-header"
            >{{ menu.name }}</router-link
          >
          <ul
            v-show="menu.show"
            style="
              background-color: #fff;
              margin-top: -1px;
              position: fixed;
              z-index: 10;
              border: 1px solid #eaecef;
            "
          >
            <li
              v-for="subMenu in menu.subMenus"
              :key="subMenu.name"
              style="margin-left: -10px"
            >
              <router-link
                :to="{ path: subMenu.url }"
                active-class="active"
                style="
                  text-decoration: none;
                  color: black;
                  border-bottom: 1px solid #eaecef;
                  margin-left: -20px;
                "
                class="nav-header"
                :class="{ mouse: hover }"
                >{{ subMenu.name }}</router-link
              >
            </li>
          </ul>
        </li>
      </div>
    </div>
    <div class="content">
      <div style="flex: 1">
        <router-view></router-view>
      </div>
      <div style="background-color: #fff">
        <div class="metting" v-if="ins == 0">
          <span style="font-size: 15px; font-weight: 600; margin: 10px"
            >关注公众号，回复【<span style="color: red">面试题</span
            >】，获取面试题、架构视频和技术资料</span
          ><br />
          <img
            src="../public/img/mp.jpg"
            style="width: 100px; margin-left: 50px; margin-top: 10px"
          />
        </div>
        <div class="metting" v-if="ins == 1">
          <span style="font-size: 15px; font-weight: 600; margin: 10px"
            >想要加入技术交流群，添加小编微信，邀请进群[无任何套路]</span
          >
          <br />
          <img
            src="../public/img/muziwechat.png"
            style="width: 100px; margin-left: 50px; margin-top: 10px"
          />
        </div>
      </div>
      <div
        style="
          width: 50px;
          right: 0;
          position: fixed;
          height: 100%;
          border-left: 1px solid #eaecef;
          background-color: #fff;
        "
      >
        <div
          style="
            margin-top: 20px;
            border-bottom: 1px solid #eaecef;
            text-align: center;
          "
          @mouseover="onHover(0)"
          @mouseleave="onHover(2)"
        >
          <img
            style="width: 50px; height: 50px"
            src="https://i-blog.csdnimg.cn/direct/22dcc9556320447a9a616db15e9ff180.jpeg"
          />
        </div>
        <div
          style="margin-top: 10px; text-align: center"
          @mouseover="onHover(1)"
          @mouseleave="onHover(2)"
        >
          <img
            style="width: 50px; height: 50px"
            src="https://i-blog.csdnimg.cn/direct/51b230db3d7a48f7bfecd69c0aa84cff.jpeg"
          />
          <p style="text-align: center; font-size: 15px; margin-top: -5px">
            读者群
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const data = {
  menus: [
    {
      name: "首页",
      url: "/home",
      show: false,
      subMenus: [],
    },

    {
      name: "Java",
      url: "#",
      show: false,
      subMenus: [
        { name: "Java基础", url: "/javaDev" },
        { name: "Java并发", url: "/concurrencyOverview" },
        { name: "JDK新特性", url: "/jdk7VersionFeatures" },
        { name: "Java Web", url: "/xmlBase" },
        { name: "设计模式", url: "/designPatternBase" },
        { name: "JVM", url: "/jvmMemory" },
        { name: "Spring", url: "/springBase" },
        { name: "SpringBoot", url: "/springbootDevelopOverview" },
        { name: "Mybatis", url: "/mybatisOverview" },
      ],
    },

    {
      name: "组件",
      url: "#",
      show: false,
      subMenus: [
        { name: "消息队列", url: "/rocketMQ" },
        { name: "分布式事务", url: "/transactionBase" },
        { name: "作业调度", url: "/jobBase" },
      ],
    },

    {
      name: "缓存数据库",
      url: "#",
      show: false,
      subMenus: [
        { name: "缓存", url: "/redis" },
        { name: "mysql", url: "/mysqlBase" },
        { name: "oracle", url: "/oracleBase" },
      ],
    },

    {
      name: "微服务框架",
      url: "#",
      show: false,
      subMenus: [
        { name: "SpringCloud", url: "/gateway" },
        { name: "Dubbo", url: "/dubboCode" },
      ],
    },
    {
      name: "大数据",
      url: "#",
      show: false,
      subMenus: [
        { name: "Hadoop", url: "/hadoopBase" },
        { name: "引擎工具", url: "/flinkBase" },
        { name: "数据仓库", url: "/store" },
      ],
    },
    {
      name: "云原生",
      url: "#",
      show: false,
      subMenus: [
        { name: "docker", url: "/dockerRecognize" },
        { name: "K8s", url: "/kubernetesBase" },
      ],
    },
    {
      name: "前端",
      url: "#",
      show: false,
      subMenus: [{ name: "前端框架", url: "/vue" }],
    },
    {
      name: "面试题",
      url: "#",
      show: false,
      subMenus: [
        { name: "java", url: "/meetingJavaBase" },
        { name: "组件", url: "/meetingKafka" },
        { name: "缓存数据库", url: "/meetingRedis" },
        { name: "微服务框架", url: "/meetingSpringcloud" },
        { name: "大数据", url: "/meetingBigDataNormal" },
      ],
    },
    {
      name: "工具破解",
      url: "/toolsIdea",
      show: false,
    },
  ],
};

export default {
  data() {
    return {
      hover: false,
      data: data,
      ins: 2,
    };
  },

  methods: {
    onHover(i) {
      this.ins = i;
    },
  },
};
</script>

<style>
.active {
  font-weight: bold;
}
.nav-header {
  text-decoration: none;
  display: block;
  width: 150px;
  height: 40px;
  line-height: 40px;
  font-weight: 500;
  color: black;
}

li {
  list-style-type: none;
  font-size: 15px;
}

#app > li {
  list-style-type: none;
  float: left;
  text-align: center;
  position: relative;
}

//下拉框的样式
#app li ul {
  position: absolute;
  top: 40px;
  margin-top: 1px;
  font-size: 12px;
  background-color: #d0f2d0;
}
[v-cloak] {
  display: none;
}
.header {
  display: flex; /* 使用Flexbox布局 */
  position: fixed;
  width: 100%;
  margin-top: -10px;
  border: 1px solid #eaecef;
  margin-left: -10px;
  background-color: #fff;
  position: relative;
}
.home {
  display: flex;
  flex-direction: column;
  flex-flow: column nowrap;
  height: 100%;
}
.content {
  display: flex;
  flex-direction: row;
}

.container {
  position: relative;
}

.floating-div {
  position: fixed; /* 或者使用absolute定位，如果你想让它相对于父元素定位 */
  top: 10px; /* 距离顶部10像素 */
  left: 10px; /* 距离左侧10像素 */
  z-index: 10; /* 确保这个div在最上面 */
}
.metting {
  position: fixed;
  right: 50px;
  z-index: 10;
  background-color: #fff;
  width: 200px;
  height: 200px;
  border: 1px solid #eaecef;
}
</style>
